import React from "react";
import * as Styled from "./styles";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  //Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useFirstConsult } from "shared/providers/FirstConsult";

//import toast from "react-hot-toast";
//import { Odontograma } from "components/Odontograma";

const PlanningInvisible: React.FC = () => {
  const {
    setStep,

    setAllObservationsPlanning,
    allObservationsPlanning,
    attachmentExchange,
    setChangeAttachmentExchange,
    changeAttachmentExchange,
    setAttachmentExchange,
    setRealizeIPRExchange,
    realizeIPRExchange,
    /*setOdontogramaAttachmentExchange,
    odontogramaAttachmentExchange,*/
  } = useFirstConsult();

  /*const [modalOdontoAttachment, setModalOdontoAttachment] =
    React.useState<boolean>(false);*/

  const next = () => {
    setStep(5);
  };

  return (
    <Styled.Box>
      <Styled.BoxIndication>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={attachmentExchange}
                onClick={() => {
                  setAttachmentExchange(!attachmentExchange);
                  setChangeAttachmentExchange([]);
                }}
              />
            }
            label="Troca de Attachment"
          />
        </FormGroup>

        {attachmentExchange && (
          <>
            <Styled.BoxButtons>
              <Button
                variant={
                  changeAttachmentExchange.includes("SUP")
                    ? "contained"
                    : "outlined"
                }
                onClick={() => {
                  if (changeAttachmentExchange.includes("SUP")) {
                    setChangeAttachmentExchange(
                      changeAttachmentExchange.filter((type) => type !== "SUP")
                    );
                  } else {
                    setChangeAttachmentExchange([
                      ...changeAttachmentExchange,
                      "SUP",
                    ]);
                  }
                }}
              >
                Superior
              </Button>
              <Button
                variant={
                  changeAttachmentExchange.includes("INF")
                    ? "contained"
                    : "outlined"
                }
                onClick={() => {
                  if (changeAttachmentExchange.includes("INF")) {
                    setChangeAttachmentExchange(
                      changeAttachmentExchange.filter((type) => type !== "INF")
                    );
                  } else {
                    setChangeAttachmentExchange([
                      ...changeAttachmentExchange,
                      "INF",
                    ]);
                  }
                }}
              >
                Inferior
              </Button>
            </Styled.BoxButtons>
          </>
        )}
        {/*changeAttachmentExchange.length > 0 && (
          <>
            <Button
              sx={{ margin: "20px 0" }}
              onClick={() => setModalOdontoAttachment(true)}
            >
              Abrir Odontograma
            </Button>

            <Modal
              open={modalOdontoAttachment}
              onClose={() => setModalOdontoAttachment(false)}
            >
              <Odontograma
                onClose={() => setModalOdontoAttachment(false)}
                selected={odontogramaAttachmentExchange}
                setSelected={setOdontogramaAttachmentExchange}
                subtitle="Troca de Attachment"
              />
            </Modal>
          </>
        )*/}
      </Styled.BoxIndication>

      <Styled.BoxIndication>
        <Typography
          variant="h5"
          sx={{ marginTop: "5px", marginBottom: "10px" }}
        >
          Necessidade de IPR ?
        </Typography>
        <Styled.BoxButtons style={{ marginBottom: "10px" }}>
          <Button
            variant={realizeIPRExchange === "YES" ? "contained" : "outlined"}
            onClick={() => {
              setRealizeIPRExchange("YES");
            }}
          >
            Sim
          </Button>
          <Button
            variant={realizeIPRExchange === "NO" ? "contained" : "outlined"}
            onClick={() => {
              setRealizeIPRExchange("NO");
            }}
          >
            Não
          </Button>
        </Styled.BoxButtons>
      </Styled.BoxIndication>
      <Styled.BoxIndication>
        <TextField
          label="Observações gerais"
          fullWidth
          margin="normal"
          value={allObservationsPlanning}
          onChange={(e) => setAllObservationsPlanning(e.target.value)}
        />
      </Styled.BoxIndication>

      <Styled.BtnFixed>
        <Button fullWidth onClick={() => setStep(3)} variant="outlined">
          Voltar
        </Button>
        <Button fullWidth onClick={next}>
          Continuar
        </Button>
      </Styled.BtnFixed>
    </Styled.Box>
  );
};

export { PlanningInvisible };
