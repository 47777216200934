import API from "shared/services/Api";
import { IProcedureResponse } from "shared/types/Procedure";

export const getProcedures = async (
  userId: string,
  type: number,
  idPatient: number
): Promise<IProcedureResponse> =>
  API.get(
    `/oc/procedures?id=${userId}&idProd=0&type=${type}&idPac=${idPatient}`
  );
