import React from "react";
import * as Styled from "./styles";
import {
  Button,
  FormControlLabel,
  Checkbox,
  FormGroup,
  TextField,
  Autocomplete,
} from "@mui/material";
import { useFirstConsult } from "shared/providers/FirstConsult";

import { useAuth } from "shared/providers/auth";
import toast from "react-hot-toast";

const PlanningMovable: React.FC = () => {
  const {
    setStep,
    othersProcedures,
    setOthersProcedures,
    //othersReferrals,
    //setOthersReferrals,
    valueOthers,
    setValueOthers,
    //setValueEnc,
    performMolding,
    braceMovel,
    setBraceMovel,
    relizeMoldage,
    setRealizeMoldage,
    //moldageSecondMovable,
    //patientObj,
    supMovable,
    setSupMovable,
    infMovable,
    setInfMovable,
    //valueEnc,
    /*installMovableReturn,
    moldageSecondMovableReturn,
    justify15,
    justify16,

    supMovableProcedureReturn,
    infMovableProcedureReturn,*/

    setAllObservationsPlanning,
    allObservationsPlanning,
  } = useFirstConsult();

  const { proceduresData /*referralsData*/ } = useAuth();

  const next = () => {
    if (!braceMovel && !relizeMoldage && !othersProcedures) {
      toast.error("Selecione pelo menos um procedimento.");
      return false;
    }

    if (braceMovel) {
      if (!infMovable && !supMovable) {
        toast.error("Selecione pelo menos um lado do aparelho móvel.");
        return false;
      }
    }

    if (relizeMoldage) {
      if (!infMovable && !supMovable) {
        toast.error("Selecione pelo menos um lado da moldagem.");
        return false;
      }
    }

    if (performMolding === "YES") {
      if (!braceMovel && !othersProcedures) {
        toast.error("Selecione pelo menos um procedimento.");
        return false;
      }
    }

    if (performMolding === "NO") {
      if (!relizeMoldage && !othersProcedures) {
        toast.error("Selecione pelo menos um procedimento.");
        return false;
      }
    }

    if (othersProcedures && valueOthers.length < 1) {
      toast.error("Selecione pelo menos um procedimento.");
      return false;
    }

    setStep(5);
  };

  return (
    <Styled.Box>
      <>
        {/* 
          {patientObj.aname_id > 0 && moldageSecondMovable && (
        <Styled.BoxIndication>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={braceMovel}
                  onClick={() => {
                    setBraceMovel(!braceMovel);
                    setInfMovable(false);
                    setSupMovable(false);
                  }}
                />
              }
              label="Instalação do Aparelho Móvel"
            />
          </FormGroup>

          {braceMovel && (
            <Styled.BoxButtons>
              <Button
                variant={supMovable ? "contained" : "outlined"}
                onClick={() => setSupMovable(!supMovable)}
                sx={{
                  "&:hover": {
                    backgroundColor: supMovable ? "#007DED" : "white",
                    color: supMovable ? "white" : "#007DED",
                    borderColor: supMovable ? "#007DED" : "#EEEFF1",
                  },
                }}
              >
                Superior
              </Button>
              <Button
                variant={infMovable ? "contained" : "outlined"}
                onClick={() => setInfMovable(!infMovable)}
                sx={{
                  "&:hover": {
                    backgroundColor: infMovable ? "#007DED" : "white",
                    color: infMovable ? "white" : "#007DED",
                    borderColor: infMovable ? "#007DED" : "#EEEFF1",
                  },
                }}
              >
                Inferior
              </Button>
            </Styled.BoxButtons>
          )}
        </Styled.BoxIndication>
      )}

      {patientObj.aname_id > 0 &&
        !installMovableReturn &&
        justify15.length > 0 && (
          <Styled.BoxIndication>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={braceMovel}
                    onClick={() => {
                      setBraceMovel(!braceMovel);
                      setInfMovable(false);
                      setSupMovable(false);
                    }}
                  />
                }
                label="Instalação do Aparelho Móvel"
              />
            </FormGroup>

            {braceMovel && (
              <Styled.BoxButtons>
                <Button
                  variant={supMovable ? "contained" : "outlined"}
                  onClick={() => setSupMovable(!supMovable)}
                  sx={{
                    "&:hover": {
                      backgroundColor: supMovable ? "#007DED" : "white",
                      color: supMovable ? "white" : "#007DED",
                      borderColor: supMovable ? "#007DED" : "#EEEFF1",
                    },
                  }}
                >
                  Superior
                </Button>
                <Button
                  variant={infMovable ? "contained" : "outlined"}
                  onClick={() => setInfMovable(!infMovable)}
                  sx={{
                    "&:hover": {
                      backgroundColor: infMovable ? "#007DED" : "white",
                      color: infMovable ? "white" : "#007DED",
                      borderColor: infMovable ? "#007DED" : "#EEEFF1",
                    },
                  }}
                >
                  Inferior
                </Button>
              </Styled.BoxButtons>
            )}
          </Styled.BoxIndication>
        )}

      {patientObj.aname_id > 0 &&
        !moldageSecondMovableReturn &&
        justify16.length > 0 && (
          <Styled.BoxIndication>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={relizeMoldage}
                    onClick={() => {
                      setRealizeMoldage(!relizeMoldage);
                      setInfMovable(false);
                      setSupMovable(false);
                    }}
                  />
                }
                label="Realizar a moldagem"
              />
            </FormGroup>
            {relizeMoldage && (
              <Styled.BoxButtons>
                <Button
                  variant={supMovable ? "contained" : "outlined"}
                  onClick={() => setSupMovable(!supMovable)}
                  sx={{
                    "&:hover": {
                      backgroundColor: supMovable ? "#007DED" : "white",
                      color: supMovable ? "white" : "#007DED",
                      borderColor: supMovable ? "#007DED" : "#EEEFF1",
                    },
                  }}
                >
                  Superior
                </Button>
                <Button
                  variant={infMovable ? "contained" : "outlined"}
                  onClick={() => setInfMovable(!infMovable)}
                  sx={{
                    "&:hover": {
                      backgroundColor: infMovable ? "#007DED" : "white",
                      color: infMovable ? "white" : "#007DED",
                      borderColor: infMovable ? "#007DED" : "#EEEFF1",
                    },
                  }}
                >
                  Inferior
                </Button>
              </Styled.BoxButtons>
            )}
          </Styled.BoxIndication>
        )}

      {patientObj.aname_id > 0 && moldageSecondMovableReturn && (
        <>
          {infMovableProcedureReturn && !supMovableProcedureReturn && (
            <>
              <Styled.BoxIndication>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={relizeMoldage}
                        onClick={() => {
                          setRealizeMoldage(!relizeMoldage);
                          setInfMovable(false);
                          setSupMovable(false);
                        }}
                      />
                    }
                    label="Realizar a moldagem"
                  />
                </FormGroup>
                {relizeMoldage && (
                  <Styled.BoxButtons>
                    <Button
                      variant={supMovable ? "contained" : "outlined"}
                      onClick={() => setSupMovable(!supMovable)}
                      sx={{
                        "&:hover": {
                          backgroundColor: supMovable ? "#007DED" : "white",
                          color: supMovable ? "white" : "#007DED",
                          borderColor: supMovable ? "#007DED" : "#EEEFF1",
                        },
                      }}
                    >
                      Superior
                    </Button>
                    <Button
                      variant={infMovable ? "contained" : "outlined"}
                      onClick={() => setInfMovable(!infMovable)}
                      sx={{
                        "&:hover": {
                          backgroundColor: infMovable ? "#007DED" : "white",
                          color: infMovable ? "white" : "#007DED",
                          borderColor: infMovable ? "#007DED" : "#EEEFF1",
                        },
                      }}
                    >
                      Inferior
                    </Button>
                  </Styled.BoxButtons>
                )}
              </Styled.BoxIndication>
            </>
          )}
        </>
      )}

      {patientObj.aname_id > 0 && moldageSecondMovableReturn && (
        <>
          {!infMovableProcedureReturn && supMovableProcedureReturn && (
            <>
              <Styled.BoxIndication>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={relizeMoldage}
                        onClick={() => {
                          setRealizeMoldage(!relizeMoldage);
                          setInfMovable(false);
                          setSupMovable(false);
                        }}
                      />
                    }
                    label="Realizar a moldagem"
                  />
                </FormGroup>
                {relizeMoldage && (
                  <Styled.BoxButtons>
                    <Button
                      variant={supMovable ? "contained" : "outlined"}
                      onClick={() => setSupMovable(!supMovable)}
                      sx={{
                        "&:hover": {
                          backgroundColor: supMovable ? "#007DED" : "white",
                          color: supMovable ? "white" : "#007DED",
                          borderColor: supMovable ? "#007DED" : "#EEEFF1",
                        },
                      }}
                    >
                      Superior
                    </Button>
                    <Button
                      variant={infMovable ? "contained" : "outlined"}
                      onClick={() => setInfMovable(!infMovable)}
                      sx={{
                        "&:hover": {
                          backgroundColor: infMovable ? "#007DED" : "white",
                          color: infMovable ? "white" : "#007DED",
                          borderColor: infMovable ? "#007DED" : "#EEEFF1",
                        },
                      }}
                    >
                      Inferior
                    </Button>
                  </Styled.BoxButtons>
                )}
              </Styled.BoxIndication>
            </>
          )}
        </>
      )}

      {patientObj.aname_id > 0 && moldageSecondMovableReturn && (
        <>
          {infMovableProcedureReturn && supMovableProcedureReturn && (
            <>
              <Styled.BoxIndication>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={braceMovel}
                        onClick={() => {
                          setBraceMovel(!braceMovel);
                          setInfMovable(false);
                          setSupMovable(false);
                        }}
                      />
                    }
                    label="Instalação do Aparelho Móvel"
                  />
                </FormGroup>

                {braceMovel && (
                  <Styled.BoxButtons>
                    <Button
                      variant={supMovable ? "contained" : "outlined"}
                      onClick={() => setSupMovable(!supMovable)}
                      sx={{
                        "&:hover": {
                          backgroundColor: supMovable ? "#007DED" : "white",
                          color: supMovable ? "white" : "#007DED",
                          borderColor: supMovable ? "#007DED" : "#EEEFF1",
                        },
                      }}
                    >
                      Superior
                    </Button>
                    <Button
                      variant={infMovable ? "contained" : "outlined"}
                      onClick={() => setInfMovable(!infMovable)}
                      sx={{
                        "&:hover": {
                          backgroundColor: infMovable ? "#007DED" : "white",
                          color: infMovable ? "white" : "#007DED",
                          borderColor: infMovable ? "#007DED" : "#EEEFF1",
                        },
                      }}
                    >
                      Inferior
                    </Button>
                  </Styled.BoxButtons>
                )}
              </Styled.BoxIndication>
            </>
          )}
        </>
      )}

      {patientObj.aname_id > 0 && installMovableReturn && (
        <>
          {!infMovableProcedureReturn && supMovableProcedureReturn && (
            <>
              <Styled.BoxIndication>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={braceMovel}
                        onClick={() => {
                          setBraceMovel(!braceMovel);
                          setInfMovable(false);
                          setSupMovable(false);
                        }}
                      />
                    }
                    label="Instalação do Aparelho Móvel"
                  />
                </FormGroup>

                {braceMovel && (
                  <Styled.BoxButtons>
                    <Button
                      variant={supMovable ? "contained" : "outlined"}
                      onClick={() => setSupMovable(!supMovable)}
                      sx={{
                        "&:hover": {
                          backgroundColor: supMovable ? "#007DED" : "white",
                          color: supMovable ? "white" : "#007DED",
                          borderColor: supMovable ? "#007DED" : "#EEEFF1",
                        },
                      }}
                    >
                      Superior
                    </Button>
                    <Button
                      variant={infMovable ? "contained" : "outlined"}
                      onClick={() => setInfMovable(!infMovable)}
                      sx={{
                        "&:hover": {
                          backgroundColor: infMovable ? "#007DED" : "white",
                          color: infMovable ? "white" : "#007DED",
                          borderColor: infMovable ? "#007DED" : "#EEEFF1",
                        },
                      }}
                    >
                      Inferior
                    </Button>
                  </Styled.BoxButtons>
                )}
              </Styled.BoxIndication>
            </>
          )}
        </>
      )}

      {patientObj.aname_id > 0 && installMovableReturn && (
        <>
          {infMovableProcedureReturn && !supMovableProcedureReturn && (
            <>
              <Styled.BoxIndication>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={braceMovel}
                        onClick={() => {
                          setBraceMovel(!braceMovel);
                          setInfMovable(false);
                          setSupMovable(false);
                        }}
                      />
                    }
                    label="Instalação do Aparelho Móvel"
                  />
                </FormGroup>

                {braceMovel && (
                  <Styled.BoxButtons>
                    <Button
                      variant={supMovable ? "contained" : "outlined"}
                      onClick={() => setSupMovable(!supMovable)}
                      sx={{
                        "&:hover": {
                          backgroundColor: supMovable ? "#007DED" : "white",
                          color: supMovable ? "white" : "#007DED",
                          borderColor: supMovable ? "#007DED" : "#EEEFF1",
                        },
                      }}
                    >
                      Superior
                    </Button>
                    <Button
                      variant={infMovable ? "contained" : "outlined"}
                      onClick={() => setInfMovable(!infMovable)}
                      sx={{
                        "&:hover": {
                          backgroundColor: infMovable ? "#007DED" : "white",
                          color: infMovable ? "white" : "#007DED",
                          borderColor: infMovable ? "#007DED" : "#EEEFF1",
                        },
                      }}
                    >
                      Inferior
                    </Button>
                  </Styled.BoxButtons>
                )}
              </Styled.BoxIndication>
            </>
          )}
        </>
      )}

      {patientObj.aname_id < 1 && (
        <>
          {performMolding === "YES" && (
            <Styled.BoxIndication>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={braceMovel}
                      onClick={() => {
                        setBraceMovel(!braceMovel);
                        setInfMovable(false);
                        setSupMovable(false);
                      }}
                    />
                  }
                  label="Instalação do Aparelho Móvel"
                />
              </FormGroup>
              {braceMovel && (
                <Styled.BoxButtons>
                  <Button
                    variant={supMovable ? "contained" : "outlined"}
                    onClick={() => setSupMovable(!supMovable)}
                    sx={{
                      "&:hover": {
                        backgroundColor: supMovable ? "#007DED" : "white",
                        color: supMovable ? "white" : "#007DED",
                        borderColor: supMovable ? "#007DED" : "#EEEFF1",
                      },
                    }}
                  >
                    Superior
                  </Button>
                  <Button
                    variant={infMovable ? "contained" : "outlined"}
                    onClick={() => setInfMovable(!infMovable)}
                    sx={{
                      "&:hover": {
                        backgroundColor: infMovable ? "#007DED" : "white",
                        color: infMovable ? "white" : "#007DED",
                        borderColor: infMovable ? "#007DED" : "#EEEFF1",
                      },
                    }}
                  >
                    Inferior
                  </Button>
                </Styled.BoxButtons>
              )}
            </Styled.BoxIndication>
          )}

          {performMolding !== "YES" && (
            <Styled.BoxIndication>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={relizeMoldage}
                      onClick={() => {
                        setRealizeMoldage(!relizeMoldage);
                        setInfMovable(false);
                        setSupMovable(false);
                      }}
                    />
                  }
                  label="Realizar a moldagem"
                />
              </FormGroup>
              {relizeMoldage && (
                <Styled.BoxButtons>
                  <Button
                    variant={supMovable ? "contained" : "outlined"}
                    onClick={() => setSupMovable(!supMovable)}
                    sx={{
                      "&:hover": {
                        backgroundColor: supMovable ? "#007DED" : "white",
                        color: supMovable ? "white" : "#007DED",
                        borderColor: supMovable ? "#007DED" : "#EEEFF1",
                      },
                    }}
                  >
                    Superior
                  </Button>
                  <Button
                    variant={infMovable ? "contained" : "outlined"}
                    onClick={() => setInfMovable(!infMovable)}
                    sx={{
                      "&:hover": {
                        backgroundColor: infMovable ? "#007DED" : "white",
                        color: infMovable ? "white" : "#007DED",
                        borderColor: infMovable ? "#007DED" : "#EEEFF1",
                      },
                    }}
                  >
                    Inferior
                  </Button>
                </Styled.BoxButtons>
              )}
            </Styled.BoxIndication>
          )}
        </>
      )}
        */}
      </>

      <Styled.BoxIndication>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={braceMovel}
                onClick={() => {
                  setBraceMovel(!braceMovel);
                  setInfMovable(false);
                  setSupMovable(false);
                }}
              />
            }
            label="Instalação do Aparelho Móvel"
          />
        </FormGroup>

        {braceMovel && (
          <Styled.BoxButtons>
            <Button
              variant={supMovable ? "contained" : "outlined"}
              onClick={() => setSupMovable(!supMovable)}
              sx={{
                "&:hover": {
                  backgroundColor: supMovable ? "#007DED" : "white",
                  color: supMovable ? "white" : "#007DED",
                  borderColor: supMovable ? "#007DED" : "#EEEFF1",
                },
              }}
            >
              Superior
            </Button>
            <Button
              variant={infMovable ? "contained" : "outlined"}
              onClick={() => setInfMovable(!infMovable)}
              sx={{
                "&:hover": {
                  backgroundColor: infMovable ? "#007DED" : "white",
                  color: infMovable ? "white" : "#007DED",
                  borderColor: infMovable ? "#007DED" : "#EEEFF1",
                },
              }}
            >
              Inferior
            </Button>
          </Styled.BoxButtons>
        )}
      </Styled.BoxIndication>

      <Styled.BoxIndication>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={relizeMoldage}
                onClick={() => {
                  setRealizeMoldage(!relizeMoldage);
                  setInfMovable(false);
                  setSupMovable(false);
                }}
              />
            }
            label="Realizar a moldagem"
          />
        </FormGroup>
        {relizeMoldage && (
          <Styled.BoxButtons>
            <Button
              variant={supMovable ? "contained" : "outlined"}
              onClick={() => setSupMovable(!supMovable)}
              sx={{
                "&:hover": {
                  backgroundColor: supMovable ? "#007DED" : "white",
                  color: supMovable ? "white" : "#007DED",
                  borderColor: supMovable ? "#007DED" : "#EEEFF1",
                },
              }}
            >
              Superior
            </Button>
            <Button
              variant={infMovable ? "contained" : "outlined"}
              onClick={() => setInfMovable(!infMovable)}
              sx={{
                "&:hover": {
                  backgroundColor: infMovable ? "#007DED" : "white",
                  color: infMovable ? "white" : "#007DED",
                  borderColor: infMovable ? "#007DED" : "#EEEFF1",
                },
              }}
            >
              Inferior
            </Button>
          </Styled.BoxButtons>
        )}
      </Styled.BoxIndication>

      <Styled.BoxIndication>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={othersProcedures}
                onClick={() => setOthersProcedures(!othersProcedures)}
              />
            }
            label="Outros procedimentos"
          />
        </FormGroup>

        {othersProcedures && (
          <>
            <Autocomplete
              options={proceduresData}
              multiple
              sx={{ margin: "10px 0" }}
              onChange={(event, newValue) => {
                setValueOthers(newValue);
              }}
              value={valueOthers}
              renderInput={(params) => (
                <TextField
                  {...params}
                  value={valueOthers}
                  label="Outros procedimentos"
                />
              )}
            />
            {/* 
            <Styled.ContentComplement>
              {procedureOptions.map((option) => (
                <Styled.AllComplements key={option.value}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedProcedureOptions.includes(
                          option.value
                        )}
                        onChange={() =>
                          handleOptionChange(
                            option.value,
                            setSelectedProcedureOptions
                          )
                        }
                      />
                    }
                    label={option.label}
                  />
                </Styled.AllComplements>
              ))}
            </Styled.ContentComplement>
            */}
          </>
        )}
      </Styled.BoxIndication>

      {/*
      <Styled.BoxIndication>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={othersReferrals}
                onClick={() => setOthersReferrals(!othersReferrals)}
              />
            }
            label="Encaminhamentos"
          />
        </FormGroup>

        {othersReferrals && (
          <>
            <Autocomplete
              options={referralsData}
              multiple
              sx={{ margin: "10px 0" }}
              onChange={(event, newValue) => {
                setValueEnc(newValue);
              }}
              value={valueEnc}
              renderInput={(params) => (
                <TextField
                  {...params}
                  value={valueEnc}
                  label="Encaminhamentos"
                />
              )}
            />
          </>
        )}
      </Styled.BoxIndication>
      */}

      <Styled.BoxIndication>
        <TextField
          label="Observações gerais"
          fullWidth
          margin="normal"
          value={allObservationsPlanning}
          onChange={(e) => setAllObservationsPlanning(e.target.value)}
        />
      </Styled.BoxIndication>

      <Styled.BtnFixed>
        <Button fullWidth onClick={() => setStep(3)} variant="outlined">
          Voltar
        </Button>
        <Button fullWidth onClick={next}>
          Continuar
        </Button>
      </Styled.BtnFixed>
    </Styled.Box>
  );
};

export { PlanningMovable };
