import React from "react";
import * as Styled from "./styles";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useFirstConsult } from "shared/providers/FirstConsult";

import { materialOptions, numbers } from "shared/mock";

import { FaVideo } from "react-icons/fa";
import { useAuth } from "shared/providers/auth";
import toast from "react-hot-toast";
import { Odontograma } from "components/Odontograma";

const ProcedurePerformedFixed: React.FC = () => {
  const {
    setStep,
    braceType,
    setBraceType,
    painPrescriptionNeeded,
    setPainPrescriptionNeeded,
    informedBasicCare,
    setInformedBasicCare,
    deliveredTreatmentManual,
    setDeliveredTreatmentManual,
    advisedWireAndWax,
    setAdvisedWireAndWax,
    additionalDevice,
    setAdditionalDevice,
    hasComplement,
    setHasComplement,
    hasOtherProcedures,
    setHasOtherProcedures,
    otherProceduresValue,
    setOtherProceduresValue,
    setReferralValue,
    setShowPainPrescriptionNeeded,
    setShowInformedBasicCare,
    setShowDeliveredTreatmentManual,
    setShowAdvisedWireAndWax,

    setShowAdditionalDevice,
    hasOtherReferrals,
    setHasOtherReferrals,
    setMaterialProcedure,
    setNumberProcedure,
    setOthersProceduresFixed,
    videoClicked,
    setVideoClicked,
    basicCareObservations,
    setBasicCareObservations,
    treatmentManualObservations,
    setTreatmentManualObservations,
    wireAndWaxObservations,
    setWireAndWaxObservations,
    errorBasicCare,
    setErrorBasicCare,
    errorTreatmentManual,
    setErrorTreatmentManual,
    errorWireAndWax,
    setErrorWireAndWax,
    odontogramaAparat,
    setOdontogramaAparat,
    odontogramaComplement,
    setOdontogramaComplement,
    patientObj,
    questionsPlanning,
    aparateReturn,
    setAparateReturn,
    aparateBraceReturn,
    setAparateBraceReturn,
    materialReturn,
    setMaterialReturn,
    numberReturn,
    setNumberReturn,
    othersReturn,
    setOthersReturn,
    wireReturn,
    setWireReturn,
    changeWireReturn,
    setChangeWireReturn,
    materialChangeReturn,
    setMaterialChangeReturn,
    numberChangeReturn,
    setNumberChangeReturn,
    othersChangeReturn,
    setOthersChangeReturn,
    keepThreadReturn,
    setKeepThreadReturn,
    keepReturn,
    setKeepReturn,
    setAtivationReturn,
    ativationReturn,
    cimentReturn,
    setCimentReturn,
    cimentBandReturn,
    setCimentBandReturn,
    glueTubReturn,
    glueReturn,
    setGlueTubReturn,
    setGlueReturn,
    complementReturn,
    setComplementReturn,
    compReturn,
    setCompReturn,
    othersProceduresReturn,
    setOthersProceduresReturn,
    //setValueOthersReturn,
    othersReferralsReturn,
    setOthersReferralsReturn,
    setValueEncReturn,
    //valueOthersReturn,
    valueEncReturn,
    justify01,
    setJustify01,
    justify02,
    setJustify02,
    justify03,
    setJustify03,
    justify04,
    setJustify04,
    justify05,
    setJustify05,
    justify07,
    setJustify07,
    justify08,
    setJustify08,
    justify09,
    setJustify09,
    repOrRec,
    setRepOrRec,
    repOrRecBrace,
    setRepOrRecBrace,
    rep,
    setRep,
    materialProcedure,
    numberProcedure,
    realize,
    setRealize,
    setJustification,
    realizeContent,
    setRealizeContent,
    realizeAparat,
    setRealizeAparat,
    realizeEruption,
    setRealizeEruption,
    realizeIntermaxilary,
    setRealizeIntermaxilary,
    realizeCollage,
    setRealizeCollage,
    realizeRebonding,
    setRealizeRebonding,
    realizeBite,
    setRealizeBite,
    route,
    setRoute,
    setJustifyRoute,
    realizeRelease,
    setRealizeRelease,
    setJustificationContent,
    setInformateRegionContent,
    setJustificationAparat,
    setJustificationEruption,
    setInformateRegionEruption,
    setJustificationIntermaxilary,
    setJustificationCollage,
    setJustificationRebonding,
    setJustificationBite,
    setJustificationRelease,
    setInformateRegion,
    currentProcedureIndex,
    setCurrentProcedureIndex,

    odontogramaComplementReturn,
    setOdontogramaComplementReturn,
    odontogramaCollageReturn,
    setOdontogramaCollageReturn,
    odontogramaCimentReturn,
    setOdontogramaCimentReturn,

    odontogramaRemote,
    setOdontogramaRemote,
    odontogramaInstall,
    setOdontogramaInstall,
    odontogramaButton,
    setOdontogramaButton,
    odontogramaIntermax,
    setOdontogramaIntermax,
    odontogramaLevant,
    setOdontogramaLevant,

    odontogramaPlanningAparatReturn,
    setOdontogramaPlanningAparatReturn,
    braceTypeMaintenance,
    setBraceTypeMaintenance,
    repOrRecProcedure,
    setRepOrRecProcedure,
    repOrRecBraceProcedure,
    setRepOrRecBraceProcedure,
    repProcedure,
    setRepProcedure,
    painPrescriptionNeededMaintenance,
    setPainPrescriptionNeededMaintenance,
    informedBasicCareMaintenance,
    setInformedBasicCareMaintenance,
    setMaterialProcedureMaintenance,
    setNumberProcedureMaintenance,
    setOthersProceduresFixedMaintenance,
    basicCareObservationsMaintenance,
    setBasicCareObservationsMaintenance,

    showOtherProcedureScreen,
    setShowOtherProcedureScreen,
    wireProcedures,
    setWireProcedures,
    changeWireProcedures,
    setChangeWireProcedures,
    setMaterialChangeProcedures,
    setNumberChangeProcedures,
    setOthersChangeProcedures,
    keepThreadProcedures,
    setKeepThreadProcedures,
    keepProcedures,
    setKeepProcedures,
    setAtivationProcedures,
    cimentProcedures,
    setCimentProcedures,
    cimentBandProcedures,
    setCimentBandProcedures,
    odontogramaCimentProcedures,
    setOdontogramaCimentProcedures,
    glueProcedures,
    setGlueProcedures,
    glueTubProcedures,
    setGlueTubProcedures,
    odontogramaCollageProcedures,
    setOdontogramaCollageProcedures,
    materialChangeProcedures,
    numberChangeProcedures,
    ativationProcedures,
    odontogramaRepOrRec,
    setOdontogramaRepOrRec,
    referralValue,

    /*otherProceduresValueProcedures,
    setOtherProceduresValueProcedures,*/

    valueReferralsProcedures,
    setValueReferralsProcedures,

    setReceiveHigh,
    receiveHigh,

    materialReturnInf,
    setMaterialReturnInf,
    setNumberReturnInf,
    numberReturnInf,
    setOthersReturnInf,
    othersReturnInf,
    setMaterialChangeReturnInf,
    materialChangeReturnInf,
    setNumberChangeReturnInf,
    numberChangeReturnInf,
    setOthersChangeReturnInf,
    othersChangeReturnInf,
    setMaterialProceduresInf,
    setNumberProceduresInf,
    setOthersProceduresInf,
    setMaterialChangeProceduresInf,
    setNumberChangeProceduresInf,
    setOthersChangeProceduresInf,
    othersProceduresFixed,
    materialProceduresInf,
    numberProceduresInf,
    othersProceduresInf,
    materialChangeProceduresInf,
    numberChangeProceduresInf,
    othersChangeProcedures,
    othersChangeProceduresInf,
    setAllObservationsProcedure,
    allObservationsProcedure,

    needsBandBottom,
    setNeedsBandBottom,
    needsSeparatorBottom,
    setNeedsSeparatorBottom,
    performMoldingBottom,
    setPerformMoldingBottom,
    installSeparatorBottom,
    setInstallSeparatorBottom,

    setMoldingJustificationBottom,

    setSeparatorJustificationBottom,
    odontogramaSeparatorBottom,
    setOdontogramaSeparatorBottom,

    needsBandTop,
    setNeedsBandTop,
    needsSeparatorTop,
    setNeedsSeparatorTop,
    performMoldingTop,
    setPerformMoldingTop,
    installSeparatorTop,
    setInstallSeparatorTop,

    setMoldingJustificationTop,

    setSeparatorJustificationTop,
    odontogramaSeparatorTop,
    setOdontogramaSeparatorTop,

    realizeClear,
    setRealizeClear,
    setOtherProceduresValueSecondConsult,
    otherProceduresValueSecondConsult,
  } = useFirstConsult();
  const {
    complementary,
    proceduresData,
    referralsData,
    proceduresDataWithFlow,
  } = useAuth();
  const [modalRecOrRep, setModalRecOrRep] = React.useState<boolean>(false);

  const [modalSeparatorBottom, setModalSeparatorBottom] =
    React.useState<boolean>(false);

  const [modalSeparatorTop, setModalSeparatorTop] =
    React.useState<boolean>(false);

  const [openModalVideo, setOpenModalVideo] = React.useState<boolean>(false);
  const [modalAparat, setModalAparat] = React.useState<boolean>(false);
  const [modalComplement, setModalComplement] = React.useState<boolean>(false);

  const [modalRemote, setModalRemote] = React.useState<boolean>(false);
  const [modalInstall, setModalInstall] = React.useState<boolean>(false);
  const [modalButton, setModalButton] = React.useState<boolean>(false);
  const [modalIntermax, setModalIntermax] = React.useState<boolean>(false);
  const [modalLevant, setModalLevant] = React.useState<boolean>(false);

  const [modalCiment, setModalCiment] = React.useState<boolean>(false);
  const [modalCollage, setModalCollage] = React.useState<boolean>(false);
  const [modalPlanningAparat, setModalPlanningAparat] =
    React.useState<boolean>(false);

  const [verifyChange01, setVerifyChange01] = React.useState<boolean>(false);
  const [verifyChange02, setVerifyChange02] = React.useState<boolean>(false);
  const [verifyChange03, setVerifyChange03] = React.useState<boolean>(false);
  const [verifyChange04, setVerifyChange04] = React.useState<boolean>(false);
  const [verifyChange05, setVerifyChange05] = React.useState<boolean>(false);
  const [verifyChange07, setVerifyChange07] = React.useState<boolean>(false);
  const [verifyChange08, setVerifyChange08] = React.useState<boolean>(false);
  const [verifyChange09, setVerifyChange09] = React.useState<boolean>(false);

  const [openModalCimentBand, setOpenModalCimentBand] =
    React.useState<boolean>(false);

  const [openModalColagemTub, setOpenModalColagemTub] =
    React.useState<boolean>(false);

  const handleContinue = () => {
    if (realizeClear === "NOT" && patientObj.aname_id < 1) {
      toast.error("Responda se realizou a limpeza");
      return false;
    }

    if (painPrescriptionNeeded === "NOT") {
      toast.error("Responda se Precisa de Receita para caso de Dor");
      return false;
    }

    if (informedBasicCare === "NOT") {
      toast.error(
        "Responda se Informou o paciente sobre os cuidados básicos com o aparelho"
      );
      return false;
    }

    if (deliveredTreatmentManual === "NOT" && patientObj.aname_id < 1) {
      toast.error("Responda se Entregou o manual do tratamento ortodôntico");
      return false;
    }

    if (advisedWireAndWax === "NOT") {
      toast.error(
        "Responda se Orientou o paciente a solicitar passa fio e cera na recepção"
      );
      return false;
    }

    if (braceType.length > 0) {
      if (braceType.includes("UPPER")) {
        if (!materialProcedure) {
          toast.error("Selecione o material Superior");
          return false;
        }

        if (!numberProcedure) {
          toast.error("Selecione o número Superior");
          return false;
        }
      }

      if (braceType.includes("LOWER")) {
        if (!materialProceduresInf) {
          toast.error("Selecione o material Inferior");
          return false;
        }

        if (!numberProceduresInf) {
          toast.error("Selecione o número Inferior");
          return false;
        }
      }
    }

    if (changeWireProcedures.length > 0) {
      if (changeWireProcedures.includes("SUP")) {
        if (!materialChangeProcedures) {
          toast.error("Selecione o material da Troca de fio Superior");
          return false;
        }

        if (!numberChangeProcedures) {
          toast.error("Selecione o número da Troca de fio Superior");
          return false;
        }
      }

      if (changeWireProcedures.includes("INF")) {
        if (!materialChangeProceduresInf) {
          toast.error("Selecione o material da Troca de fio Inferior");
          return false;
        }

        if (!numberChangeProceduresInf) {
          toast.error("Selecione o número da Troca de fio Inferior");
          return false;
        }
      }
    }

    if (keepProcedures.length > 0) {
      if (ativationProcedures !== "no" && ativationProcedures !== "yes") {
        toast.error("Selecione se tem ativação ou não.");
        return false;
      }
    }

    if (patientObj.aname_id > 0) {
      if (wireProcedures && changeWireProcedures.length < 1) {
        toast.error("Selecione a troca de fio");
        return false;
      }

      if (wireProcedures && changeWireProcedures.length > 0) {
        if (!materialChangeProcedures) {
          toast.error("Selecione o material da Troca de fio");
          return false;
        }

        if (!numberChangeProcedures) {
          toast.error("Selecione o número da Troca de fio");
          return false;
        }
      }

      if (cimentProcedures && cimentBandProcedures.length < 1) {
        toast.error("Selecione a cimentação de banda");
        return false;
      }

      if (keepThreadProcedures && keepProcedures.length < 1) {
        toast.error("Selecione manter fio");
        return false;
      }

      if (keepThreadProcedures && keepProcedures.length > 0) {
        if (!ativationProcedures) {
          toast.error("Selecione se tem ativação ou não.");
          return false;
        }
      }
    }

    if (
      questionsPlanning.length > 0 &&
      questionsPlanning.filter((item) => item.id === "PPQ001").length > 0
    ) {
      if (videoClicked === false) {
        toast.error("Assista o vídeo para prosseguir");
        return false;
      }
    }

    if (
      newArrayValue.length > 0 &&
      newArrayValue[0].length > 1 &&
      patientObj.proc_name !== "Emergência"
    ) {
      if (showOtherProcedureScreen) {
        if (currentProcedureIndex < newArrayValue.length - 1) {
          if (newArrayValue[currentProcedureIndex] === "Mini Implante") {
            if (realize === "NOT") {
              toast.error("Responda a pergunta e tente novamente");
              return false;
            }
          }

          if (
            newArrayValue[currentProcedureIndex] === "Controle de Contenção"
          ) {
            if (realizeContent === "NOT") {
              toast.error("Responda a pergunta e tente novamente");
              return false;
            }
          }

          if (newArrayValue[currentProcedureIndex] === "Remoção de Aparelho") {
            if (realizeAparat === "NOT") {
              toast.error("Responda a pergunta e tente novamente");
              return false;
            }
          }

          if (newArrayValue[currentProcedureIndex] === "Controle de Erupção") {
            if (realizeEruption === "NOT") {
              toast.error("Responda a pergunta e tente novamente");
              return false;
            }
          }

          if (
            newArrayValue[currentProcedureIndex] ===
            "Instalacao de elasticos intermaxilares"
          ) {
            if (realizeIntermaxilary === "NOT") {
              toast.error("Responda a pergunta e tente novamente");
              return false;
            }
          }

          if (newArrayValue[currentProcedureIndex] === "Colagem de Botão") {
            if (realizeCollage === "NOT") {
              toast.error("Responda a pergunta e tente novamente");
              return false;
            }
          }

          if (newArrayValue[currentProcedureIndex] === "Recolagem Contenção") {
            if (realizeRebonding === "NOT") {
              toast.error("Responda a pergunta e tente novamente");
              return false;
            }
          }

          if (newArrayValue[currentProcedureIndex] === "Levante de Mordida") {
            if (realizeBite === "NOT") {
              toast.error("Responda a pergunta e tente novamente");
              return false;
            }
          }

          if (newArrayValue[currentProcedureIndex] === "Alta") {
            if (realizeRelease === "NOT") {
              toast.error("Responda a pergunta e tente novamente");
              return false;
            }
          }

          if (receiveHigh) {
            setStep(6);
          }

          setCurrentProcedureIndex(currentProcedureIndex + 1);
        } else {
          if (
            patientObj.proc_name === "Emergência" ||
            patientObj.future.scheduling === true
          ) {
            setStep(6);
          } else {
            setStep(4);
          }
          /*if (patientObj.aname_id > 0) {
            if (
              braceType === "NOT" &&
              keepThreadProcedures === false &&
              wireProcedures === false &&
              glueProcedures === false &&
              cimentProcedures === false &&
              painPrescriptionNeeded === "NOT" &&
              informedBasicCare === "NOT" &&
              advisedWireAndWax === "NOT" &&
              rep === false &&
              hasComplement === false &&
              hasOtherProcedures === false &&
              hasOtherReferrals === false
            ) {
              toast.error("Selecione algum procedimento para continuar");
              return false;
            }
            setStep(4);
          }*/
        }
      } else {
        setCurrentProcedureIndex(0);
        setShowOtherProcedureScreen(true);
      }
    } else {
      if (
        patientObj.proc_name === "Emergência" ||
        patientObj.future.scheduling === true
      ) {
        setStep(6);
      } else {
        setStep(4);
      }
    }
  };

  const descriptionsWithFlowZero = proceduresData
    .filter((procedure) => procedure.flow === 0)
    .map((procedure) => procedure.label);

  const combinedProcedures = [
    ...otherProceduresValue,
    ...otherProceduresValueSecondConsult,
  ];

  const newArrayValue: string[] = combinedProcedures
    .filter(
      (procedure: string) => !descriptionsWithFlowZero.includes(procedure)
    )
    .reduce<string[]>((uniqueArray, procedure) => {
      if (!uniqueArray.includes(procedure)) {
        uniqueArray.push(procedure);
      }
      return uniqueArray;
    }, []);

  const next = () => {
    if (verifyChange01 && justify01.length < 1) {
      toast.error("Preencha o campo justificativa");
      return false;
    }

    if (verifyChange02 && justify02.length < 1) {
      toast.error("Preencha o campo justificativa");
      return false;
    }

    if (verifyChange03 && justify03.length < 1) {
      toast.error("Preencha o campo justificativa");
      return false;
    }

    if (verifyChange04 && justify04.length < 1) {
      toast.error("Preencha o campo justificativa");
      return false;
    }

    if (verifyChange05 && justify05.length < 1) {
      toast.error("Preencha o campo justificativa");
      return false;
    }

    if (verifyChange07 && justify07.length < 1) {
      toast.error("Preencha o campo justificativa");
      return false;
    }

    if (verifyChange08 && justify08.length < 1) {
      toast.error("Preencha o campo justificativa");
      return false;
    }

    if (verifyChange09 && justify09.length < 1) {
      toast.error("Preencha o campo justificativa");
      return false;
    }

    if (
      (informedBasicCare === "NO" && basicCareObservations === "") ||
      (deliveredTreatmentManual === "NO" &&
        treatmentManualObservations === "") ||
      (advisedWireAndWax === "NO" && wireAndWaxObservations === "")
    ) {
      toast.error("Por favor, preencha todas as observações necessárias.");

      if (informedBasicCare === "NO" && basicCareObservations === "")
        setErrorBasicCare(true);
      if (
        deliveredTreatmentManual === "NO" &&
        treatmentManualObservations === ""
      )
        setErrorTreatmentManual(true);
      if (advisedWireAndWax === "NO" && wireAndWaxObservations === "")
        setErrorWireAndWax(true);
    } else {
      if (
        braceType.length > 0 &&
        videoClicked === false &&
        questionsPlanning.length < 1
      ) {
        toast.error("Assista o vídeo para prosseguir");
        return false;
      } else {
        handleContinue();
      }
    }
  };

  const handleReturn = () => {
    if (currentProcedureIndex > 0) {
      setCurrentProcedureIndex(currentProcedureIndex - 1);
    } else {
      setShowOtherProcedureScreen(false);
    }
  };

  const handleNeedsBandClickBottom = (value: "NO" | "YES") => {
    setNeedsBandBottom(value);
    setNeedsSeparatorBottom("NOT");
    setPerformMoldingBottom("NOT");
    setInstallSeparatorBottom("NOT");
    setMoldingJustificationBottom("");
    setSeparatorJustificationBottom("");
  };

  const handleNeedsSeparatorClickBottom = (value: "NO" | "YES") => {
    setNeedsSeparatorBottom(value);
    setPerformMoldingBottom("NOT");
    setInstallSeparatorBottom("NOT");
    setMoldingJustificationBottom("");
    setSeparatorJustificationBottom("");
  };

  const handlePerformMoldingClickBottom = (value: "NO" | "YES") => {
    setPerformMoldingBottom(value);
    if (value === "YES") setMoldingJustificationBottom("");
  };

  const handleInstallSeparatorClickBottom = (value: "NO" | "YES") => {
    setInstallSeparatorBottom(value);
    if (value === "YES") setSeparatorJustificationBottom("");
  };

  const shouldShowBtnFixedBottom = () => {
    return performMoldingBottom !== "NOT" || installSeparatorBottom !== "NOT";
  };

  const handleNeedsBandClickTop = (value: "NO" | "YES") => {
    setNeedsBandTop(value);
    setNeedsSeparatorTop("NOT");
    setPerformMoldingTop("NOT");
    setInstallSeparatorTop("NOT");
    setMoldingJustificationTop("");
    setSeparatorJustificationTop("");
  };

  const handleNeedsSeparatorClickTop = (value: "NO" | "YES") => {
    setNeedsSeparatorTop(value);
    setPerformMoldingTop("NOT");
    setInstallSeparatorTop("NOT");
    setMoldingJustificationTop("");
    setSeparatorJustificationTop("");
  };

  const handlePerformMoldingClickTop = (value: "NO" | "YES") => {
    setPerformMoldingTop(value);
    if (value === "YES") setMoldingJustificationTop("");
  };

  const handleInstallSeparatorClickTop = (value: "NO" | "YES") => {
    setInstallSeparatorTop(value);
    if (value === "YES") setSeparatorJustificationTop("");
  };

  const shouldShowBtnFixedTop = () => {
    return performMoldingTop !== "NOT" || installSeparatorTop !== "NOT";
  };

  return (
    <Styled.Box>
      {showOtherProcedureScreen && showOtherProcedureScreen ? (
        <>
          <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
            {newArrayValue[currentProcedureIndex]}
          </Typography>

          {newArrayValue[currentProcedureIndex] === "Mini Implante" && (
            <Styled.BoxDescription>
              <Typography variant="h5">
                Foi Realizado o Mini Implante ortodôntico?
              </Typography>
              <Styled.BoxButtons>
                <Button
                  variant={realize === "NO" ? "contained" : "outlined"}
                  onClick={() => setRealize("NO")}
                >
                  NÃO
                </Button>
                <Button
                  variant={realize === "YES" ? "contained" : "outlined"}
                  onClick={() => setRealize("YES")}
                >
                  SIM
                </Button>
              </Styled.BoxButtons>
              {realize === "NO" && (
                <TextField
                  label="Justificativa"
                  fullWidth
                  margin="normal"
                  onChange={(e) => setJustification(e.target.value)}
                />
              )}

              {realize === "YES" && (
                <TextField
                  label="Informe a região onde foi realizado o procedimento"
                  fullWidth
                  margin="normal"
                  onChange={(e) => setInformateRegion(e.target.value)}
                />
              )}
              {realize !== "NOT" && (
                <>
                  <Autocomplete
                    options={proceduresDataWithFlow.map(
                      (option) => option.label
                    )}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !otherProceduresValue.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setOtherProceduresValue((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Outros procedimentos" />
                    )}
                  />

                  <Autocomplete
                    options={referralsData}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !valueReferralsProcedures.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setValueReferralsProcedures((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Encaminhamentos" />
                    )}
                  />
                </>
              )}
            </Styled.BoxDescription>
          )}

          {newArrayValue[currentProcedureIndex] === "Controle de Contenção" && (
            <Styled.BoxDescription>
              <Typography variant="h5">
                Foi Realizado Controle de Contenção?
              </Typography>
              <Styled.BoxButtons>
                <Button
                  variant={realizeContent === "NO" ? "contained" : "outlined"}
                  onClick={() => setRealizeContent("NO")}
                >
                  NÃO
                </Button>
                <Button
                  variant={realizeContent === "YES" ? "contained" : "outlined"}
                  onClick={() => setRealizeContent("YES")}
                >
                  SIM
                </Button>
              </Styled.BoxButtons>
              {realizeContent === "NO" && (
                <TextField
                  label="Justificativa"
                  fullWidth
                  margin="normal"
                  onChange={(e) => setJustificationContent(e.target.value)}
                />
              )}
              {realizeContent === "YES" && (
                <TextField
                  label="Faça o registro do caso"
                  fullWidth
                  margin="normal"
                  onChange={(e) => setInformateRegionContent(e.target.value)}
                />
              )}

              {realizeContent !== "NOT" && (
                <>
                  <Autocomplete
                    options={proceduresDataWithFlow.map(
                      (option) => option.label
                    )}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !otherProceduresValue.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setOtherProceduresValue((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Outros procedimentos" />
                    )}
                  />
                  <Autocomplete
                    options={referralsData}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !valueReferralsProcedures.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setValueReferralsProcedures((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Encaminhamentos" />
                    )}
                  />
                </>
              )}
            </Styled.BoxDescription>
          )}

          {newArrayValue[currentProcedureIndex] === "Remoção de Aparelho" && (
            <Styled.BoxDescription>
              <Typography variant="h5">
                Foi Realizada a Remoção da Aparatologia Ortodôntica?
              </Typography>
              <Styled.BoxButtons>
                <Button
                  variant={realizeAparat === "NO" ? "contained" : "outlined"}
                  onClick={() => setRealizeAparat("NO")}
                >
                  NÃO
                </Button>
                <Button
                  variant={realizeAparat === "YES" ? "contained" : "outlined"}
                  onClick={() => setRealizeAparat("YES")}
                >
                  SIM
                </Button>
              </Styled.BoxButtons>
              {realizeAparat === "NO" && (
                <TextField
                  label="Justificativa"
                  fullWidth
                  margin="normal"
                  onChange={(e) => setJustificationAparat(e.target.value)}
                />
              )}

              {realizeAparat === "YES" && (
                <>
                  <Button
                    sx={{ margin: "20px 0" }}
                    onClick={() => setModalRemote(true)}
                  >
                    Abrir Odontograma
                  </Button>

                  <Modal
                    open={modalRemote}
                    onClose={() => setModalRemote(false)}
                  >
                    <Odontograma
                      onClose={() => setModalRemote(false)}
                      selected={odontogramaRemote}
                      setSelected={setOdontogramaRemote}
                      subtitle="Remoção de Aparatologia Ortodôntica"
                    />
                  </Modal>
                </>
              )}

              {realizeAparat !== "NOT" && (
                <>
                  <Autocomplete
                    options={proceduresDataWithFlow.map(
                      (option) => option.label
                    )}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !otherProceduresValue.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setOtherProceduresValue((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Outros procedimentos" />
                    )}
                  />
                  <Autocomplete
                    options={referralsData}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !valueReferralsProcedures.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setValueReferralsProcedures((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Encaminhamentos" />
                    )}
                  />
                </>
              )}
            </Styled.BoxDescription>
          )}

          {newArrayValue[currentProcedureIndex] === "Controle de Erupção" && (
            <Styled.BoxDescription>
              <Typography variant="h5">
                Foi Realizado Controle de Erupção?
              </Typography>
              <Styled.BoxButtons>
                <Button
                  variant={realizeEruption === "NO" ? "contained" : "outlined"}
                  onClick={() => setRealizeEruption("NO")}
                >
                  NÃO
                </Button>
                <Button
                  variant={realizeEruption === "YES" ? "contained" : "outlined"}
                  onClick={() => setRealizeEruption("YES")}
                >
                  SIM
                </Button>
              </Styled.BoxButtons>
              {realizeEruption === "NO" && (
                <TextField
                  label="Justificativa"
                  fullWidth
                  margin="normal"
                  onChange={(e) => setJustificationEruption(e.target.value)}
                />
              )}
              {realizeEruption === "YES" && (
                <TextField
                  label="Faça o registro do caso"
                  fullWidth
                  margin="normal"
                  onChange={(e) => setInformateRegionEruption(e.target.value)}
                />
              )}

              {realizeEruption !== "NOT" && (
                <>
                  <Autocomplete
                    options={proceduresDataWithFlow.map(
                      (option) => option.label
                    )}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !otherProceduresValue.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setOtherProceduresValue((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Outros procedimentos" />
                    )}
                  />
                  <Autocomplete
                    options={referralsData}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !valueReferralsProcedures.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setValueReferralsProcedures((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Encaminhamentos" />
                    )}
                  />
                </>
              )}
            </Styled.BoxDescription>
          )}

          {newArrayValue[currentProcedureIndex] ===
            "Instalacao de elasticos intermaxilares" && (
            <Styled.BoxDescription>
              <Typography variant="h5">Foi Colocado Elástico?</Typography>
              <Styled.BoxButtons>
                <Button
                  variant={
                    realizeIntermaxilary === "NO" ? "contained" : "outlined"
                  }
                  onClick={() => setRealizeIntermaxilary("NO")}
                >
                  NÃO
                </Button>
                <Button
                  variant={
                    realizeIntermaxilary === "YES" ? "contained" : "outlined"
                  }
                  onClick={() => setRealizeIntermaxilary("YES")}
                >
                  SIM
                </Button>
              </Styled.BoxButtons>
              {realizeIntermaxilary === "NO" && (
                <TextField
                  label="Justificativa"
                  fullWidth
                  margin="normal"
                  onChange={(e) =>
                    setJustificationIntermaxilary(e.target.value)
                  }
                />
              )}

              {realizeIntermaxilary === "YES" && (
                <>
                  <Button
                    sx={{ margin: "20px 0" }}
                    onClick={() => setModalInstall(true)}
                  >
                    Abrir Odontograma
                  </Button>

                  <Modal
                    open={modalInstall}
                    onClose={() => setModalInstall(false)}
                  >
                    <Odontograma
                      onClose={() => setModalInstall(false)}
                      selected={odontogramaInstall}
                      setSelected={setOdontogramaInstall}
                      subtitle="Instalação de elásticos intermaxilares"
                    />
                  </Modal>
                </>
              )}

              {realizeIntermaxilary !== "NOT" && (
                <>
                  <Autocomplete
                    options={proceduresDataWithFlow.map(
                      (option) => option.label
                    )}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !otherProceduresValue.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setOtherProceduresValue((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Outros procedimentos" />
                    )}
                  />

                  <Autocomplete
                    options={referralsData}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !valueReferralsProcedures.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setValueReferralsProcedures((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Encaminhamentos" />
                    )}
                  />
                </>
              )}
            </Styled.BoxDescription>
          )}

          {newArrayValue[currentProcedureIndex] === "Colagem de Botão" && (
            <Styled.BoxDescription>
              <Typography variant="h5">Colagem de botão?</Typography>
              <Styled.BoxButtons>
                <Button
                  variant={realizeCollage === "NO" ? "contained" : "outlined"}
                  onClick={() => setRealizeCollage("NO")}
                >
                  NÃO
                </Button>
                <Button
                  variant={realizeCollage === "YES" ? "contained" : "outlined"}
                  onClick={() => setRealizeCollage("YES")}
                >
                  SIM
                </Button>
              </Styled.BoxButtons>
              {realizeCollage === "NO" && (
                <TextField
                  label="Justificativa"
                  fullWidth
                  margin="normal"
                  onChange={(e) => setJustificationCollage(e.target.value)}
                />
              )}

              {realizeCollage === "YES" && (
                <>
                  <Button
                    sx={{ margin: "20px 0" }}
                    onClick={() => setModalButton(true)}
                  >
                    Abrir Odontograma
                  </Button>

                  <Modal
                    open={modalButton}
                    onClose={() => setModalButton(false)}
                  >
                    <Odontograma
                      onClose={() => setModalButton(false)}
                      selected={odontogramaButton}
                      setSelected={setOdontogramaButton}
                      subtitle="Colagem de Botão"
                    />
                  </Modal>
                </>
              )}

              {realizeCollage !== "NOT" && (
                <>
                  <Autocomplete
                    options={proceduresDataWithFlow.map(
                      (option) => option.label
                    )}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !otherProceduresValue.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setOtherProceduresValue((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Outros procedimentos" />
                    )}
                  />

                  <Autocomplete
                    options={referralsData}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !valueReferralsProcedures.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setValueReferralsProcedures((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Encaminhamentos" />
                    )}
                  />
                </>
              )}
            </Styled.BoxDescription>
          )}

          {newArrayValue[currentProcedureIndex] === "Recolagem Contenção" && (
            <Styled.BoxDescription>
              <Typography variant="h5">
                Foi realizada a recolagem de contenção?
              </Typography>
              <Styled.BoxButtons>
                <Button
                  variant={realizeRebonding === "NO" ? "contained" : "outlined"}
                  onClick={() => setRealizeRebonding("NO")}
                >
                  NÃO
                </Button>
                <Button
                  variant={
                    realizeRebonding === "YES" ? "contained" : "outlined"
                  }
                  onClick={() => setRealizeRebonding("YES")}
                >
                  SIM
                </Button>
              </Styled.BoxButtons>
              {realizeRebonding === "NO" && (
                <TextField
                  label="Justificativa"
                  fullWidth
                  margin="normal"
                  onChange={(e) => setJustificationRebonding(e.target.value)}
                />
              )}

              {realizeRebonding === "YES" && (
                <>
                  <Button
                    sx={{ margin: "20px 0" }}
                    onClick={() => setModalIntermax(true)}
                  >
                    Abrir Odontograma
                  </Button>

                  <Modal
                    open={modalIntermax}
                    onClose={() => setModalIntermax(false)}
                  >
                    <Odontograma
                      onClose={() => setModalIntermax(false)}
                      selected={odontogramaIntermax}
                      setSelected={setOdontogramaIntermax}
                      subtitle="Recolagem de Contenção"
                    />
                  </Modal>
                </>
              )}

              {realizeRebonding !== "NOT" && (
                <>
                  <Autocomplete
                    options={proceduresDataWithFlow.map(
                      (option) => option.label
                    )}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !otherProceduresValue.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setOtherProceduresValue((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Outros procedimentos" />
                    )}
                  />

                  <Autocomplete
                    options={referralsData}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !valueReferralsProcedures.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setValueReferralsProcedures((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Encaminhamentos" />
                    )}
                  />
                </>
              )}
            </Styled.BoxDescription>
          )}

          {newArrayValue[currentProcedureIndex] === "Levante de Mordida" && (
            <Styled.BoxDescription>
              <Typography variant="h5">
                Foi realizado levante de mordida?
              </Typography>
              <Styled.BoxButtons>
                <Button
                  variant={realizeBite === "NO" ? "contained" : "outlined"}
                  onClick={() => setRealizeBite("NO")}
                >
                  NÃO
                </Button>
                <Button
                  variant={realizeBite === "YES" ? "contained" : "outlined"}
                  onClick={() => setRealizeBite("YES")}
                >
                  SIM
                </Button>
              </Styled.BoxButtons>
              {realizeBite === "NO" && (
                <TextField
                  label="Justificativa"
                  fullWidth
                  margin="normal"
                  onChange={(e) => setJustificationBite(e.target.value)}
                />
              )}

              {realizeBite === "YES" && (
                <>
                  <Button
                    sx={{ margin: "20px 0" }}
                    onClick={() => setModalLevant(true)}
                  >
                    Abrir Odontograma
                  </Button>

                  <Modal
                    open={modalLevant}
                    onClose={() => setModalLevant(false)}
                  >
                    <Odontograma
                      onClose={() => setModalLevant(false)}
                      selected={odontogramaLevant}
                      setSelected={setOdontogramaLevant}
                      subtitle="Levante de Mordida"
                    />
                  </Modal>
                </>
              )}

              {realizeBite !== "NOT" && (
                <>
                  <Autocomplete
                    options={proceduresDataWithFlow.map(
                      (option) => option.label
                    )}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !otherProceduresValue.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setOtherProceduresValue((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Outros procedimentos" />
                    )}
                  />

                  <Autocomplete
                    options={referralsData}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !valueReferralsProcedures.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setValueReferralsProcedures((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Encaminhamentos" />
                    )}
                  />
                </>
              )}
            </Styled.BoxDescription>
          )}

          {newArrayValue[currentProcedureIndex] === "Alta" && (
            <>
              <Styled.BoxDescription>
                <Typography variant="h5">Paciente recebeu alta?</Typography>
                <Styled.BoxButtons>
                  <Button
                    variant={realizeRelease === "NO" ? "contained" : "outlined"}
                    onClick={() => {
                      setRealizeRelease("NO");
                      setReceiveHigh(false);
                    }}
                  >
                    NÃO
                  </Button>
                  <Button
                    variant={
                      realizeRelease === "YES" ? "contained" : "outlined"
                    }
                    onClick={() => {
                      setRealizeRelease("YES");
                      setReceiveHigh(true);
                    }}
                  >
                    SIM
                  </Button>
                </Styled.BoxButtons>
                {realizeRelease === "NO" && (
                  <TextField
                    label="Justificativa"
                    fullWidth
                    margin="normal"
                    onChange={(e) => setJustificationRelease(e.target.value)}
                  />
                )}
              </Styled.BoxDescription>

              {realizeRelease === "YES" && (
                <Styled.BoxDescription>
                  <Typography variant="h5">
                    Paciente foi encaminhado para a documentação final?
                  </Typography>
                  <Styled.BoxButtons>
                    <Button
                      variant={route === "NO" ? "contained" : "outlined"}
                      onClick={() => setRoute("NO")}
                    >
                      NÃO
                    </Button>
                    <Button
                      variant={route === "YES" ? "contained" : "outlined"}
                      onClick={() => setRoute("YES")}
                    >
                      SIM
                    </Button>
                  </Styled.BoxButtons>
                  {route === "NO" && (
                    <TextField
                      label="Justificativa"
                      fullWidth
                      margin="normal"
                      onChange={(e) => setJustifyRoute(e.target.value)}
                    />
                  )}
                </Styled.BoxDescription>
              )}

              {realizeRelease !== "NOT" && (
                <>
                  <Autocomplete
                    options={proceduresDataWithFlow.map(
                      (option) => option.label
                    )}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !otherProceduresValue.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setOtherProceduresValue((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Outros procedimentos" />
                    )}
                  />

                  <Autocomplete
                    options={referralsData}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !valueReferralsProcedures.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setValueReferralsProcedures((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Encaminhamentos" />
                    )}
                  />
                </>
              )}
            </>
          )}

          {newArrayValue[currentProcedureIndex] === "Manutenção" && (
            <>
              <Styled.BoxIndication>
                <Styled.BoxButtons
                  style={{ marginBottom: "10px", marginTop: "10px" }}
                >
                  <Button
                    variant={
                      braceTypeMaintenance === "UPPER"
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => {
                      setBraceTypeMaintenance("UPPER");
                    }}
                  >
                    Trocar fio
                  </Button>
                  <Button
                    variant={
                      braceTypeMaintenance === "LOWER"
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => {
                      setBraceTypeMaintenance("LOWER");
                    }}
                  >
                    Manter fio
                  </Button>
                </Styled.BoxButtons>
                {braceTypeMaintenance !== "NOT" && (
                  <Box display="flex" alignItems="center" mt={2} mb={2}>
                    <FormControl fullWidth>
                      <InputLabel
                        id="material-select-label"
                        sx={{ backgroundColor: "white", padding: "2px" }}
                      >
                        Material
                      </InputLabel>
                      <Select
                        displayEmpty
                        variant="outlined"
                        sx={{ width: "90%" }}
                        style={{ marginRight: 16 }}
                        onChange={(e) =>
                          setMaterialProcedureMaintenance(
                            e.target.value as string
                          )
                        }
                      >
                        {materialOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth>
                      <InputLabel
                        id="number-select-label"
                        sx={{ backgroundColor: "white", padding: "2px" }}
                      >
                        N°
                      </InputLabel>
                      <Select
                        displayEmpty
                        variant="outlined"
                        sx={{ width: "90%" }}
                        style={{ marginRight: 16 }}
                        onChange={(e) =>
                          setNumberProcedureMaintenance(
                            e.target.value as string
                          )
                        }
                      >
                        {numbers.map((item, index) => (
                          <MenuItem key={index} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField
                      label="Outros"
                      onChange={(e) =>
                        setOthersProceduresFixedMaintenance(
                          e.target.value as string
                        )
                      }
                    ></TextField>
                  </Box>
                )}
              </Styled.BoxIndication>

              <Styled.BoxIndication>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={repProcedure}
                        onClick={() => {
                          setRepProcedure(!repProcedure);
                        }}
                      />
                    }
                    label="Reposição ou Recolagem de Braquete"
                  />
                </FormGroup>
                {repProcedure && (
                  <>
                    <Styled.BoxButtons>
                      <Button
                        variant={
                          repOrRecProcedure === "REP" ? "contained" : "outlined"
                        }
                        onClick={() => setRepOrRecProcedure("REP")}
                      >
                        Reposição
                      </Button>
                      <Button
                        variant={
                          repOrRecProcedure === "REC" ? "contained" : "outlined"
                        }
                        onClick={() => setRepOrRecProcedure("REC")}
                      >
                        Recolagem
                      </Button>
                    </Styled.BoxButtons>

                    {repOrRecProcedure !== "NOT" && (
                      <Styled.BoxButtons
                        style={{ marginTop: "20px", paddingBottom: "10px" }}
                      >
                        <Button
                          variant={
                            repOrRecBraceProcedure === "SUP"
                              ? "contained"
                              : "outlined"
                          }
                          onClick={() => setRepOrRecBraceProcedure("SUP")}
                        >
                          Superior
                        </Button>
                        <Button
                          variant={
                            repOrRecBraceProcedure === "INF"
                              ? "contained"
                              : "outlined"
                          }
                          onClick={() => setRepOrRecBraceProcedure("INF")}
                        >
                          Inferior
                        </Button>
                      </Styled.BoxButtons>
                    )}
                  </>
                )}
              </Styled.BoxIndication>

              <Styled.BoxIndication>
                <Typography
                  variant="h5"
                  sx={{ marginTop: "5px", marginBottom: "10px" }}
                >
                  Precisa de receita para o caso de dor?
                </Typography>
                <Styled.BoxButtons style={{ marginBottom: "10px" }}>
                  <Button
                    variant={
                      painPrescriptionNeededMaintenance === "YES"
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => {
                      setPainPrescriptionNeededMaintenance("YES");
                    }}
                  >
                    Sim
                  </Button>
                  <Button
                    variant={
                      painPrescriptionNeededMaintenance === "NO"
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => {
                      setPainPrescriptionNeededMaintenance("NO");
                    }}
                  >
                    Não
                  </Button>
                </Styled.BoxButtons>
              </Styled.BoxIndication>

              <Styled.BoxIndication>
                <Typography
                  variant="h5"
                  sx={{ marginTop: "5px", marginBottom: "10px" }}
                >
                  Informou o paciente sobre os cuidados básicos com o aparelho?
                </Typography>
                <Styled.BoxButtons style={{ marginBottom: "10px" }}>
                  <Button
                    variant={
                      informedBasicCareMaintenance === "YES"
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => {
                      setInformedBasicCareMaintenance("YES");
                    }}
                  >
                    Sim
                  </Button>
                  <Button
                    variant={
                      informedBasicCareMaintenance === "NO"
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => {
                      setInformedBasicCareMaintenance("NO");
                    }}
                  >
                    Não
                  </Button>
                </Styled.BoxButtons>
                {informedBasicCareMaintenance === "NO" && (
                  <TextField
                    fullWidth
                    label="Justifique"
                    variant="outlined"
                    sx={{ marginTop: "10px", paddingBottom: "5px" }}
                    value={basicCareObservationsMaintenance}
                    onChange={(e) => {
                      setBasicCareObservationsMaintenance(e.target.value);
                    }}
                  />
                )}

                {braceTypeMaintenance !== "NOT" && (
                  <>
                    <Autocomplete
                      options={proceduresData.map((option) => option.label)}
                      multiple
                      sx={{ margin: "10px 0" }}
                      onChange={(event, newValue) => {
                        const uniqueValues = newValue.filter(
                          (value) => !otherProceduresValue.includes(value)
                        );

                        // Atualiza o estado com os valores únicos
                        setOtherProceduresValue((prevValue: any) => [
                          ...prevValue,
                          ...uniqueValues,
                        ]);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Outros procedimentos" />
                      )}
                    />

                    <Autocomplete
                      options={referralsData}
                      multiple
                      sx={{ margin: "10px 0" }}
                      onChange={(event, newValue) => {
                        const uniqueValues = newValue.filter(
                          (value) => !valueReferralsProcedures.includes(value)
                        );

                        // Atualiza o estado com os valores únicos
                        setValueReferralsProcedures((prevValue: any) => [
                          ...prevValue,
                          ...uniqueValues,
                        ]);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Encaminhamentos" />
                      )}
                    />
                  </>
                )}
              </Styled.BoxIndication>
            </>
          )}

          {newArrayValue[currentProcedureIndex] === "Moldagem Inferior" && (
            <>
              <Styled.BoxDescription>
                <Typography variant="h5">Vai precisar de banda?</Typography>
                <Styled.BoxButtons>
                  <Button
                    variant={
                      needsBandBottom === "NO" ? "contained" : "outlined"
                    }
                    onClick={() => handleNeedsBandClickBottom("NO")}
                  >
                    NÃO
                  </Button>
                  <Button
                    variant={
                      needsBandBottom === "YES" ? "contained" : "outlined"
                    }
                    onClick={() => handleNeedsBandClickBottom("YES")}
                  >
                    SIM
                  </Button>
                </Styled.BoxButtons>
              </Styled.BoxDescription>

              {needsBandBottom === "NO" && (
                <Styled.BoxDescription>
                  <Typography variant="h5">Realizou a moldagem?</Typography>
                  <Styled.BoxButtons>
                    <Button
                      variant={
                        performMoldingBottom === "NO" ? "contained" : "outlined"
                      }
                      onClick={() => handlePerformMoldingClickBottom("NO")}
                    >
                      NÃO
                    </Button>
                    <Button
                      variant={
                        performMoldingBottom === "YES"
                          ? "contained"
                          : "outlined"
                      }
                      onClick={() => handlePerformMoldingClickBottom("YES")}
                    >
                      SIM
                    </Button>
                  </Styled.BoxButtons>
                  {performMoldingBottom === "NO" && (
                    <TextField
                      label="Justifique"
                      onChange={(e) =>
                        setMoldingJustificationBottom(e.target.value)
                      }
                    />
                  )}
                </Styled.BoxDescription>
              )}

              {needsBandBottom === "YES" && (
                <Styled.BoxDescription>
                  <Typography variant="h5">
                    Vai precisar de separador?
                  </Typography>
                  <Styled.BoxButtons>
                    <Button
                      variant={
                        needsSeparatorBottom === "NO" ? "contained" : "outlined"
                      }
                      onClick={() => handleNeedsSeparatorClickBottom("NO")}
                    >
                      NÃO
                    </Button>
                    <Button
                      variant={
                        needsSeparatorBottom === "YES"
                          ? "contained"
                          : "outlined"
                      }
                      onClick={() => handleNeedsSeparatorClickBottom("YES")}
                    >
                      SIM
                    </Button>
                  </Styled.BoxButtons>
                </Styled.BoxDescription>
              )}

              {needsSeparatorBottom === "NO" && needsBandBottom === "YES" && (
                <>
                  <Button onClick={() => setModalSeparatorBottom(true)}>
                    Abrir Odontograma
                  </Button>

                  <Modal
                    open={modalSeparatorBottom}
                    onClose={() => setModalSeparatorBottom(false)}
                  >
                    <Odontograma
                      onClose={() => setModalSeparatorBottom(false)}
                      selected={odontogramaSeparatorBottom}
                      setSelected={setOdontogramaSeparatorBottom}
                      subtitle="Moldagem Inferior"
                    />
                  </Modal>
                  <Styled.BoxDescription>
                    <Typography variant="h5">Realizou a moldagem?</Typography>
                    <Styled.BoxButtons>
                      <Button
                        variant={
                          performMoldingBottom === "NO"
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() => handlePerformMoldingClickBottom("NO")}
                      >
                        NÃO
                      </Button>
                      <Button
                        variant={
                          performMoldingBottom === "YES"
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() => handlePerformMoldingClickBottom("YES")}
                      >
                        SIM
                      </Button>
                    </Styled.BoxButtons>
                    {performMoldingBottom === "NO" && (
                      <TextField
                        label="Justifique"
                        onChange={(e) =>
                          setMoldingJustificationBottom(e.target.value)
                        }
                      />
                    )}
                  </Styled.BoxDescription>
                </>
              )}

              {needsSeparatorBottom === "YES" && needsBandBottom === "YES" && (
                <>
                  <Button onClick={() => setModalSeparatorBottom(true)}>
                    Abrir Odontograma
                  </Button>

                  <Modal
                    open={modalSeparatorBottom}
                    onClose={() => setModalSeparatorBottom(false)}
                  >
                    <Odontograma
                      onClose={() => setModalSeparatorBottom(false)}
                      selected={odontogramaSeparatorBottom}
                      setSelected={setOdontogramaSeparatorBottom}
                      subtitle="Moldagem Inferior"
                    />
                  </Modal>
                  <Styled.BoxDescription>
                    <Typography variant="h5">Instalou o separador?</Typography>
                    <Styled.BoxButtons>
                      <Button
                        variant={
                          installSeparatorBottom === "NO"
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() => handleInstallSeparatorClickBottom("NO")}
                      >
                        NÃO
                      </Button>
                      <Button
                        variant={
                          installSeparatorBottom === "YES"
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() => handleInstallSeparatorClickBottom("YES")}
                      >
                        SIM
                      </Button>
                    </Styled.BoxButtons>

                    {installSeparatorBottom === "NO" && (
                      <TextField
                        label="Justifique"
                        onChange={(e) =>
                          setSeparatorJustificationBottom(e.target.value)
                        }
                      />
                    )}
                  </Styled.BoxDescription>
                </>
              )}
            </>
          )}

          {newArrayValue[currentProcedureIndex] === "Moldagem Superior" && (
            <>
              <Styled.BoxDescription>
                <Typography variant="h5">Vai precisar de banda?</Typography>
                <Styled.BoxButtons>
                  <Button
                    variant={needsBandTop === "NO" ? "contained" : "outlined"}
                    onClick={() => handleNeedsBandClickTop("NO")}
                  >
                    NÃO
                  </Button>
                  <Button
                    variant={needsBandTop === "YES" ? "contained" : "outlined"}
                    onClick={() => handleNeedsBandClickTop("YES")}
                  >
                    SIM
                  </Button>
                </Styled.BoxButtons>
              </Styled.BoxDescription>

              {needsBandTop === "NO" && (
                <Styled.BoxDescription>
                  <Typography variant="h5">Realizou a moldagem?</Typography>
                  <Styled.BoxButtons>
                    <Button
                      variant={
                        performMoldingTop === "NO" ? "contained" : "outlined"
                      }
                      onClick={() => handlePerformMoldingClickTop("NO")}
                    >
                      NÃO
                    </Button>
                    <Button
                      variant={
                        performMoldingTop === "YES" ? "contained" : "outlined"
                      }
                      onClick={() => handlePerformMoldingClickTop("YES")}
                    >
                      SIM
                    </Button>
                  </Styled.BoxButtons>
                  {performMoldingTop === "NO" && (
                    <TextField
                      label="Justifique"
                      onChange={(e) =>
                        setMoldingJustificationTop(e.target.value)
                      }
                    />
                  )}
                </Styled.BoxDescription>
              )}

              {needsBandTop === "YES" && (
                <Styled.BoxDescription>
                  <Typography variant="h5">
                    Vai precisar de separador?
                  </Typography>
                  <Styled.BoxButtons>
                    <Button
                      variant={
                        needsSeparatorTop === "NO" ? "contained" : "outlined"
                      }
                      onClick={() => handleNeedsSeparatorClickTop("NO")}
                    >
                      NÃO
                    </Button>
                    <Button
                      variant={
                        needsSeparatorTop === "YES" ? "contained" : "outlined"
                      }
                      onClick={() => handleNeedsSeparatorClickTop("YES")}
                    >
                      SIM
                    </Button>
                  </Styled.BoxButtons>
                </Styled.BoxDescription>
              )}

              {needsSeparatorTop === "NO" && needsBandTop === "YES" && (
                <>
                  <Button onClick={() => setModalSeparatorTop(true)}>
                    Abrir Odontograma
                  </Button>

                  <Modal
                    open={modalSeparatorTop}
                    onClose={() => setModalSeparatorTop(false)}
                  >
                    <Odontograma
                      onClose={() => setModalSeparatorTop(false)}
                      selected={odontogramaSeparatorTop}
                      setSelected={setOdontogramaSeparatorTop}
                      subtitle="Moldagem Inferior"
                    />
                  </Modal>
                  <Styled.BoxDescription>
                    <Typography variant="h5">Realizou a moldagem?</Typography>
                    <Styled.BoxButtons>
                      <Button
                        variant={
                          performMoldingTop === "NO" ? "contained" : "outlined"
                        }
                        onClick={() => handlePerformMoldingClickTop("NO")}
                      >
                        NÃO
                      </Button>
                      <Button
                        variant={
                          performMoldingTop === "YES" ? "contained" : "outlined"
                        }
                        onClick={() => handlePerformMoldingClickTop("YES")}
                      >
                        SIM
                      </Button>
                    </Styled.BoxButtons>
                    {performMoldingTop === "NO" && (
                      <TextField
                        label="Justifique"
                        onChange={(e) =>
                          setMoldingJustificationTop(e.target.value)
                        }
                      />
                    )}
                  </Styled.BoxDescription>
                </>
              )}

              {needsSeparatorTop === "YES" && needsBandTop === "YES" && (
                <>
                  <Button onClick={() => setModalSeparatorTop(true)}>
                    Abrir Odontograma
                  </Button>

                  <Modal
                    open={modalSeparatorTop}
                    onClose={() => setModalSeparatorTop(false)}
                  >
                    <Odontograma
                      onClose={() => setModalSeparatorTop(false)}
                      selected={odontogramaSeparatorTop}
                      setSelected={setOdontogramaSeparatorTop}
                      subtitle="Moldagem Inferior"
                    />
                  </Modal>
                  <Styled.BoxDescription>
                    <Typography variant="h5">Instalou o separador?</Typography>
                    <Styled.BoxButtons>
                      <Button
                        variant={
                          installSeparatorTop === "NO"
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() => handleInstallSeparatorClickTop("NO")}
                      >
                        NÃO
                      </Button>
                      <Button
                        variant={
                          installSeparatorTop === "YES"
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() => handleInstallSeparatorClickTop("YES")}
                      >
                        SIM
                      </Button>
                    </Styled.BoxButtons>

                    {installSeparatorTop === "NO" && (
                      <TextField
                        label="Justifique"
                        onChange={(e) =>
                          setSeparatorJustificationTop(e.target.value)
                        }
                      />
                    )}
                  </Styled.BoxDescription>
                </>
              )}
            </>
          )}

          {newArrayValue[currentProcedureIndex] === "Moldagem Inferior" ? (
            <>
              {shouldShowBtnFixedBottom() && (
                <Styled.BtnFixed>
                  <Button onClick={handleReturn} fullWidth>
                    {currentProcedureIndex === 0 ? "Retornar" : "Anterior"}
                  </Button>
                  <Button onClick={handleContinue} fullWidth>
                    {currentProcedureIndex < otherProceduresValue.length - 1
                      ? "Próximo"
                      : "Continuar"}
                  </Button>
                </Styled.BtnFixed>
              )}
            </>
          ) : newArrayValue[currentProcedureIndex] === "Moldagem Superior" ? (
            <>
              {shouldShowBtnFixedTop() && (
                <Styled.BtnFixed>
                  <Button onClick={handleReturn} fullWidth>
                    {currentProcedureIndex === 0 ? "Retornar" : "Anterior"}
                  </Button>
                  <Button onClick={handleContinue} fullWidth>
                    {currentProcedureIndex < otherProceduresValue.length - 1
                      ? "Próximo"
                      : "Continuar"}
                  </Button>
                </Styled.BtnFixed>
              )}
            </>
          ) : (
            <Styled.BtnFixed>
              <Button onClick={handleReturn} fullWidth>
                {currentProcedureIndex === 0 ? "Retornar" : "Anterior"}
              </Button>
              <Button onClick={handleContinue} fullWidth>
                {currentProcedureIndex < newArrayValue.length - 1
                  ? "Próximo"
                  : "Continuar"}
              </Button>
            </Styled.BtnFixed>
          )}
        </>
      ) : (
        <>
          <>
            {questionsPlanning && questionsPlanning.length > 0 ? (
              <>
                {patientObj.proc_name !== "Emergência" && (
                  <Styled.BoxPlanning>
                    <Typography variant="h5">
                      Planejamento da consulta anterior
                    </Typography>

                    {questionsPlanning.filter((item) => item.id === "PPQ001")
                      .length > 0 ? (
                      <Styled.BoxIndication>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={aparateReturn}
                                onClick={() => {
                                  setAparateReturn(!aparateReturn);
                                  setAparateBraceReturn([]); // Reseta o estado ao desmarcar
                                  setVerifyChange01(true);
                                }}
                              />
                            }
                            label="Colagem da Aparatologia Ortodôntica"
                          />
                        </FormGroup>

                        {aparateReturn && (
                          <>
                            <Styled.BoxButtons>
                              <Button
                                variant={
                                  aparateBraceReturn.includes("SUP")
                                    ? "contained"
                                    : "outlined"
                                }
                                onClick={() => {
                                  if (aparateBraceReturn.includes("SUP")) {
                                    setAparateBraceReturn(
                                      aparateBraceReturn.filter(
                                        (type) => type !== "SUP"
                                      )
                                    );
                                  } else {
                                    setAparateBraceReturn([
                                      ...aparateBraceReturn,
                                      "SUP",
                                    ]);
                                  }
                                  setVerifyChange01(true);
                                }}
                              >
                                Superior
                              </Button>
                              <Button
                                variant={
                                  aparateBraceReturn.includes("INF")
                                    ? "contained"
                                    : "outlined"
                                }
                                onClick={() => {
                                  if (aparateBraceReturn.includes("INF")) {
                                    setAparateBraceReturn(
                                      aparateBraceReturn.filter(
                                        (type) => type !== "INF"
                                      )
                                    );
                                  } else {
                                    setAparateBraceReturn([
                                      ...aparateBraceReturn,
                                      "INF",
                                    ]);
                                  }
                                  setVerifyChange01(true);
                                }}
                              >
                                Inferior
                              </Button>
                            </Styled.BoxButtons>

                            {aparateBraceReturn.length > 0 && (
                              <Box
                                display="flex"
                                alignItems="center"
                                flexDirection="column"
                                mt={2}
                              >
                                {aparateBraceReturn.includes("SUP") && (
                                  <>
                                    <Typography>Superior</Typography>
                                    <Box
                                      display="flex"
                                      flexDirection="row"
                                      width="100%"
                                    >
                                      <FormControl fullWidth>
                                        <InputLabel
                                          id="demo-simple-select-label"
                                          sx={{
                                            backgroundColor: "white",
                                            padding: "2px",
                                          }}
                                        >
                                          Material
                                        </InputLabel>
                                        <Select
                                          displayEmpty
                                          variant="outlined"
                                          sx={{ width: "90%" }}
                                          value={materialReturn}
                                          style={{ marginRight: 16 }}
                                          onChange={(e) => {
                                            setMaterialReturn(
                                              e.target.value as string
                                            );
                                            setVerifyChange01(
                                              e.target.value === materialReturn
                                                ? false
                                                : true
                                            );
                                          }}
                                        >
                                          {materialOptions.map((option) => (
                                            <MenuItem
                                              key={option.value}
                                              value={option.value}
                                            >
                                              {option.label}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                      <FormControl fullWidth>
                                        <InputLabel
                                          id="demo-simple-select-label"
                                          sx={{
                                            backgroundColor: "white",
                                            padding: "2px",
                                          }}
                                        >
                                          N°
                                        </InputLabel>
                                        <Select
                                          displayEmpty
                                          variant="outlined"
                                          sx={{ width: "90%" }}
                                          style={{ marginRight: 16 }}
                                          onChange={(e) => {
                                            setNumberReturn(
                                              e.target.value as string
                                            );
                                            setVerifyChange01(
                                              e.target.value === numberReturn
                                                ? false
                                                : true
                                            );
                                          }}
                                          value={numberReturn}
                                        >
                                          {numbers.map((item, index) => (
                                            <MenuItem
                                              key={index}
                                              value={item.value}
                                            >
                                              {item.label}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>

                                      <TextField
                                        label="Outros"
                                        onChange={(e) => {
                                          setOthersReturn(
                                            e.target.value as string
                                          );
                                          setVerifyChange01(true);
                                        }}
                                        value={othersReturn}
                                      />
                                    </Box>
                                  </>
                                )}
                                {aparateBraceReturn.includes("INF") && (
                                  <>
                                    <Typography>Inferior</Typography>
                                    <Box
                                      display="flex"
                                      flexDirection="row"
                                      width="100%"
                                    >
                                      <FormControl fullWidth>
                                        <InputLabel
                                          id="demo-simple-select-label"
                                          sx={{
                                            backgroundColor: "white",
                                            padding: "2px",
                                          }}
                                        >
                                          Material
                                        </InputLabel>
                                        <Select
                                          displayEmpty
                                          variant="outlined"
                                          sx={{ width: "90%" }}
                                          value={materialReturnInf}
                                          style={{ marginRight: 16 }}
                                          onChange={(e) => {
                                            setMaterialReturnInf(
                                              e.target.value as string
                                            );
                                            setVerifyChange01(
                                              e.target.value === materialReturn
                                                ? false
                                                : true
                                            );
                                          }}
                                        >
                                          {materialOptions.map((option) => (
                                            <MenuItem
                                              key={option.value}
                                              value={option.value}
                                            >
                                              {option.label}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                      <FormControl fullWidth>
                                        <InputLabel
                                          id="demo-simple-select-label"
                                          sx={{
                                            backgroundColor: "white",
                                            padding: "2px",
                                          }}
                                        >
                                          N°
                                        </InputLabel>
                                        <Select
                                          displayEmpty
                                          variant="outlined"
                                          sx={{ width: "90%" }}
                                          style={{ marginRight: 16 }}
                                          onChange={(e) => {
                                            setNumberReturnInf(
                                              e.target.value as string
                                            );
                                            setVerifyChange01(
                                              e.target.value === numberReturn
                                                ? false
                                                : true
                                            );
                                          }}
                                          value={numberReturnInf}
                                        >
                                          {numbers.map((item, index) => (
                                            <MenuItem
                                              key={index}
                                              value={item.value}
                                            >
                                              {item.label}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>

                                      <TextField
                                        label="Outros"
                                        onChange={(e) => {
                                          setOthersReturnInf(
                                            e.target.value as string
                                          );
                                          setVerifyChange01(true);
                                        }}
                                        value={othersReturnInf}
                                      />
                                    </Box>
                                  </>
                                )}

                                <Button
                                  sx={{ margin: "20px 0" }}
                                  onClick={() => {
                                    setModalPlanningAparat(true);
                                  }}
                                  fullWidth
                                >
                                  Abrir Odontograma
                                </Button>

                                <Modal
                                  open={modalPlanningAparat}
                                  onClose={() => setModalPlanningAparat(false)}
                                >
                                  <Odontograma
                                    onClose={() =>
                                      setModalPlanningAparat(false)
                                    }
                                    selected={odontogramaPlanningAparatReturn}
                                    setSelected={
                                      setOdontogramaPlanningAparatReturn
                                    }
                                    subtitle="Colagem de aparatologia ortodôntica - Planejamento"
                                  />
                                </Modal>
                              </Box>
                            )}
                          </>
                        )}

                        {verifyChange01 && (
                          <TextField
                            label="Justifique a mudança"
                            onChange={(e) => setJustify01(e.target.value)}
                            error={verifyChange01 && justify01.length < 1}
                            helperText={
                              verifyChange01 && justify01.length < 1
                                ? "Justifique sua alteração"
                                : ""
                            }
                            sx={{ marginTop: "20px" }}
                          />
                        )}
                      </Styled.BoxIndication>
                    ) : (
                      ""
                    )}

                    {questionsPlanning.filter((item) => item.id === "PPQ002")
                      .length > 0 ? (
                      <Styled.BoxIndication>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={wireReturn}
                                onClick={() => {
                                  setWireReturn(!wireReturn);
                                  setChangeWireReturn([]); // Reseta o estado ao desmarcar
                                  setVerifyChange02(true);
                                }}
                              />
                            }
                            label="Trocar Fio"
                          />
                        </FormGroup>

                        {wireReturn && (
                          <>
                            <Styled.BoxButtons>
                              <Button
                                variant={
                                  changeWireReturn.includes("SUP")
                                    ? "contained"
                                    : "outlined"
                                }
                                onClick={() => {
                                  if (changeWireReturn.includes("SUP")) {
                                    setChangeWireReturn(
                                      changeWireReturn.filter(
                                        (type) => type !== "SUP"
                                      )
                                    );
                                  } else {
                                    setChangeWireReturn([
                                      ...changeWireReturn,
                                      "SUP",
                                    ]);
                                  }
                                  setVerifyChange02(true);
                                }}
                              >
                                Superior
                              </Button>
                              <Button
                                variant={
                                  changeWireReturn.includes("INF")
                                    ? "contained"
                                    : "outlined"
                                }
                                onClick={() => {
                                  if (changeWireReturn.includes("INF")) {
                                    setChangeWireReturn(
                                      changeWireReturn.filter(
                                        (type) => type !== "INF"
                                      )
                                    );
                                  } else {
                                    setChangeWireReturn([
                                      ...changeWireReturn,
                                      "INF",
                                    ]);
                                  }
                                  setVerifyChange02(true);
                                }}
                              >
                                Inferior
                              </Button>
                            </Styled.BoxButtons>

                            {changeWireReturn.length > 0 && (
                              <>
                                {changeWireReturn.includes("SUP") && (
                                  <>
                                    <Typography>Superior</Typography>
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      mt={2}
                                    >
                                      <FormControl fullWidth>
                                        <InputLabel
                                          id="demo-simple-select-label"
                                          sx={{
                                            backgroundColor: "white",
                                            padding: "2px",
                                          }}
                                        >
                                          Material
                                        </InputLabel>
                                        <Select
                                          displayEmpty
                                          variant="outlined"
                                          sx={{ width: "90%" }}
                                          style={{ marginRight: 16 }}
                                          onChange={(e) => {
                                            setMaterialChangeReturn(
                                              e.target.value as string
                                            );
                                            setVerifyChange02(
                                              e.target.value ===
                                                materialChangeReturn
                                                ? false
                                                : true
                                            );
                                          }}
                                          value={materialChangeReturn}
                                        >
                                          {materialOptions.map((option) => (
                                            <MenuItem
                                              key={option.value}
                                              value={option.value}
                                            >
                                              {option.label}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                      <FormControl fullWidth>
                                        <InputLabel
                                          id="demo-simple-select-label"
                                          sx={{
                                            backgroundColor: "white",
                                            padding: "2px",
                                          }}
                                        >
                                          N°
                                        </InputLabel>
                                        <Select
                                          displayEmpty
                                          variant="outlined"
                                          sx={{ width: "90%" }}
                                          style={{ marginRight: 16 }}
                                          onChange={(e) => {
                                            setNumberChangeReturn(
                                              e.target.value as string
                                            );
                                            setVerifyChange02(
                                              e.target.value ===
                                                numberChangeReturn
                                                ? false
                                                : true
                                            );
                                          }}
                                          value={numberChangeReturn}
                                        >
                                          {numbers.map((item, index) => (
                                            <MenuItem
                                              key={index}
                                              value={item.value}
                                            >
                                              {item.label}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>

                                      <TextField
                                        label="Outros"
                                        onChange={(e) => {
                                          setOthersChangeReturn(
                                            e.target.value as string
                                          );
                                          setVerifyChange02(true);
                                        }}
                                        value={othersChangeReturn}
                                      />
                                    </Box>
                                  </>
                                )}

                                {changeWireReturn.includes("INF") && (
                                  <>
                                    <Typography>Inferior</Typography>
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      mt={2}
                                    >
                                      <FormControl fullWidth>
                                        <InputLabel
                                          id="demo-simple-select-label"
                                          sx={{
                                            backgroundColor: "white",
                                            padding: "2px",
                                          }}
                                        >
                                          Material
                                        </InputLabel>
                                        <Select
                                          displayEmpty
                                          variant="outlined"
                                          sx={{ width: "90%" }}
                                          style={{ marginRight: 16 }}
                                          onChange={(e) => {
                                            setMaterialChangeReturnInf(
                                              e.target.value as string
                                            );
                                            setVerifyChange02(
                                              e.target.value ===
                                                materialChangeReturn
                                                ? false
                                                : true
                                            );
                                          }}
                                          value={materialChangeReturnInf}
                                        >
                                          {materialOptions.map((option) => (
                                            <MenuItem
                                              key={option.value}
                                              value={option.value}
                                            >
                                              {option.label}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                      <FormControl fullWidth>
                                        <InputLabel
                                          id="demo-simple-select-label"
                                          sx={{
                                            backgroundColor: "white",
                                            padding: "2px",
                                          }}
                                        >
                                          N°
                                        </InputLabel>
                                        <Select
                                          displayEmpty
                                          variant="outlined"
                                          sx={{ width: "90%" }}
                                          style={{ marginRight: 16 }}
                                          onChange={(e) => {
                                            setNumberChangeReturnInf(
                                              e.target.value as string
                                            );
                                            setVerifyChange02(
                                              e.target.value ===
                                                numberChangeReturn
                                                ? false
                                                : true
                                            );
                                          }}
                                          value={numberChangeReturnInf}
                                        >
                                          {numbers.map((item, index) => (
                                            <MenuItem
                                              key={index}
                                              value={item.value}
                                            >
                                              {item.label}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>

                                      <TextField
                                        label="Outros"
                                        onChange={(e) => {
                                          setOthersChangeReturnInf(
                                            e.target.value as string
                                          );
                                          setVerifyChange02(true);
                                        }}
                                        value={othersChangeReturnInf}
                                      />
                                    </Box>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}

                        {verifyChange02 && (
                          <TextField
                            label="Justifique a mudança"
                            onChange={(e) => setJustify02(e.target.value)}
                            sx={{ marginTop: "20px" }}
                            error={verifyChange02 && justify02.length < 1}
                            helperText={
                              verifyChange02 && justify02.length < 1
                                ? "Justifique sua alteração"
                                : ""
                            }
                          />
                        )}
                      </Styled.BoxIndication>
                    ) : (
                      ""
                    )}

                    {questionsPlanning.filter((item) => item.id === "PPQ003")
                      .length > 0 ? (
                      <Styled.BoxIndication>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={keepThreadReturn}
                                onClick={() => {
                                  setKeepThreadReturn(!keepThreadReturn);
                                  setKeepReturn([]); // Reseta o estado ao desmarcar
                                  setVerifyChange03(true);
                                }}
                              />
                            }
                            label="Manter Fio"
                          />
                        </FormGroup>

                        {keepThreadReturn && (
                          <>
                            <Styled.BoxButtons>
                              <Button
                                variant={
                                  keepReturn.includes("SUP")
                                    ? "contained"
                                    : "outlined"
                                }
                                onClick={() => {
                                  if (keepReturn.includes("SUP")) {
                                    setKeepReturn(
                                      keepReturn.filter(
                                        (type) => type !== "SUP"
                                      )
                                    );
                                  } else {
                                    setKeepReturn([...keepReturn, "SUP"]);
                                  }
                                  setVerifyChange03(true);
                                }}
                              >
                                Superior
                              </Button>
                              <Button
                                variant={
                                  keepReturn.includes("INF")
                                    ? "contained"
                                    : "outlined"
                                }
                                onClick={() => {
                                  if (keepReturn.includes("INF")) {
                                    setKeepReturn(
                                      keepReturn.filter(
                                        (type) => type !== "INF"
                                      )
                                    );
                                  } else {
                                    setKeepReturn([...keepReturn, "INF"]);
                                  }
                                  setVerifyChange03(true);
                                }}
                              >
                                Inferior
                              </Button>
                            </Styled.BoxButtons>

                            {keepReturn.length > 0 && (
                              <Box display="flex" alignItems="center" mt={2}>
                                <FormControl>
                                  <RadioGroup
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                    onChange={(e) => {
                                      setAtivationReturn(
                                        e.target.value as string
                                      );
                                      setVerifyChange03(
                                        e.target.value === ativationReturn
                                          ? false
                                          : true
                                      );
                                    }}
                                    value={ativationReturn}
                                  >
                                    <FormControlLabel
                                      value="yes"
                                      control={<Radio />}
                                      label="Com ativação"
                                    />
                                    <FormControlLabel
                                      value="no"
                                      control={<Radio />}
                                      label="Sem ativação"
                                    />
                                  </RadioGroup>
                                </FormControl>

                                <TextField
                                  label="Outros"
                                  onChange={(e) => {
                                    setOthersReturn(e.target.value as string);
                                    setVerifyChange03(true);
                                  }}
                                  value={othersReturn}
                                />
                              </Box>
                            )}
                          </>
                        )}

                        {verifyChange03 && (
                          <TextField
                            label="Justifique a mudança"
                            onChange={(e) => setJustify03(e.target.value)}
                            sx={{ marginTop: "20px" }}
                            error={verifyChange03 && justify03.length < 1}
                            helperText={
                              verifyChange03 && justify03.length < 1
                                ? "Justifique sua alteração"
                                : ""
                            }
                          />
                        )}
                      </Styled.BoxIndication>
                    ) : (
                      ""
                    )}

                    {questionsPlanning.filter((item) => item.id === "PPQ004")
                      .length > 0 ? (
                      <Styled.BoxIndication>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={cimentReturn}
                                onClick={() => {
                                  setCimentReturn(!cimentReturn);
                                  setCimentBandReturn([]); // Reseta o estado ao desmarcar
                                  setVerifyChange04(true);
                                }}
                              />
                            }
                            label="Cimentação de banda"
                          />
                        </FormGroup>

                        {cimentReturn && (
                          <>
                            <Styled.BoxButtons>
                              <Button
                                variant={
                                  cimentBandReturn.includes("SUP")
                                    ? "contained"
                                    : "outlined"
                                }
                                onClick={() => {
                                  if (cimentBandReturn.includes("SUP")) {
                                    setCimentBandReturn(
                                      cimentBandReturn.filter(
                                        (type) => type !== "SUP"
                                      )
                                    );
                                  } else {
                                    setCimentBandReturn([
                                      ...cimentBandReturn,
                                      "SUP",
                                    ]);
                                  }
                                  setVerifyChange04(true);
                                }}
                              >
                                Superior
                              </Button>
                              <Button
                                variant={
                                  cimentBandReturn.includes("INF")
                                    ? "contained"
                                    : "outlined"
                                }
                                onClick={() => {
                                  if (cimentBandReturn.includes("INF")) {
                                    setCimentBandReturn(
                                      cimentBandReturn.filter(
                                        (type) => type !== "INF"
                                      )
                                    );
                                  } else {
                                    setCimentBandReturn([
                                      ...cimentBandReturn,
                                      "INF",
                                    ]);
                                  }
                                  setVerifyChange04(true);
                                }}
                              >
                                Inferior
                              </Button>
                            </Styled.BoxButtons>

                            {cimentBandReturn.length > 0 && (
                              <Button
                                onClick={() => {
                                  setModalCiment(true);
                                }}
                                sx={{ marginTop: "10px" }}
                              >
                                Abrir Odontograma
                              </Button>
                            )}

                            <Modal
                              open={modalCiment}
                              onClose={() => setModalCiment(false)}
                            >
                              <Odontograma
                                onClose={() => setModalCiment(false)}
                                selected={odontogramaCimentReturn}
                                setSelected={setOdontogramaCimentReturn}
                                subtitle="Cimentação de banda"
                              />
                            </Modal>
                          </>
                        )}

                        {verifyChange04 && (
                          <TextField
                            label="Justifique a mudança"
                            onChange={(e) => setJustify04(e.target.value)}
                            sx={{ marginTop: "20px" }}
                            error={verifyChange04 && justify04.length < 1}
                            helperText={
                              verifyChange04 && justify04.length < 1
                                ? "Justifique sua alteração"
                                : ""
                            }
                          />
                        )}
                      </Styled.BoxIndication>
                    ) : (
                      ""
                    )}

                    {questionsPlanning.filter((item) => item.id === "PPQ005")
                      .length > 0 ? (
                      <Styled.BoxIndication>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={glueReturn}
                                onClick={() => {
                                  setGlueReturn(!glueReturn);
                                  setGlueTubReturn([]); // Reseta o estado ao desmarcar
                                  setVerifyChange05(true);
                                }}
                              />
                            }
                            label="Colagem do tubo"
                          />
                        </FormGroup>

                        {glueReturn && (
                          <>
                            <Styled.BoxButtons>
                              <Button
                                variant={
                                  glueTubReturn.includes("SUP")
                                    ? "contained"
                                    : "outlined"
                                }
                                onClick={() => {
                                  if (glueTubReturn.includes("SUP")) {
                                    setGlueTubReturn(
                                      glueTubReturn.filter(
                                        (type) => type !== "SUP"
                                      )
                                    );
                                  } else {
                                    setGlueTubReturn([...glueTubReturn, "SUP"]);
                                  }
                                  setVerifyChange05(true);
                                }}
                              >
                                Superior
                              </Button>
                              <Button
                                variant={
                                  glueTubReturn.includes("INF")
                                    ? "contained"
                                    : "outlined"
                                }
                                onClick={() => {
                                  if (glueTubReturn.includes("INF")) {
                                    setGlueTubReturn(
                                      glueTubReturn.filter(
                                        (type) => type !== "INF"
                                      )
                                    );
                                  } else {
                                    setGlueTubReturn([...glueTubReturn, "INF"]);
                                  }
                                  setVerifyChange05(true);
                                }}
                              >
                                Inferior
                              </Button>
                            </Styled.BoxButtons>

                            {glueTubReturn.length > 0 && (
                              <Button
                                onClick={() => {
                                  setModalCollage(true);
                                }}
                                sx={{ marginTop: "10px" }}
                              >
                                Abrir Odontograma
                              </Button>
                            )}

                            <Modal
                              open={modalCollage}
                              onClose={() => setModalCollage(false)}
                            >
                              <Odontograma
                                onClose={() => setModalCollage(false)}
                                selected={odontogramaCollageReturn}
                                setSelected={setOdontogramaCollageReturn}
                                subtitle="Colagem do tubo"
                              />
                            </Modal>
                          </>
                        )}

                        {verifyChange05 && (
                          <TextField
                            label="Justifique a mudança"
                            onChange={(e) => setJustify05(e.target.value)}
                            sx={{ marginTop: "20px" }}
                            error={verifyChange05 && justify05.length < 1}
                            helperText={
                              verifyChange05 && justify05.length < 1
                                ? "Justifique sua alteração"
                                : ""
                            }
                          />
                        )}
                      </Styled.BoxIndication>
                    ) : (
                      ""
                    )}

                    {questionsPlanning.filter((item) => item.id === "PPQ007")
                      .length > 0 ? (
                      <Styled.BoxIndication>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={complementReturn}
                                onClick={() => {
                                  setComplementReturn(!complementReturn);
                                  setCompReturn([]);
                                  setVerifyChange07(true);
                                }}
                              />
                            }
                            label="Aparelho Complementar"
                          />
                        </FormGroup>

                        {complementReturn && (
                          <>
                            <Autocomplete
                              options={complementary}
                              multiple
                              sx={{ margin: "10px 0" }}
                              onChange={(event, newValue) => {
                                setCompReturn(newValue);
                                setVerifyChange07(true);
                              }}
                              value={compReturn}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Aparelho complementar"
                                />
                              )}
                            />

                            {compReturn.length > 0 && (
                              <Button
                                onClick={() => {
                                  setModalComplement(true);
                                }}
                              >
                                Abrir Odontograma
                              </Button>
                            )}

                            <Modal
                              open={modalComplement}
                              onClose={() => setModalComplement(false)}
                            >
                              <Odontograma
                                onClose={() => setModalComplement(false)}
                                selected={odontogramaComplementReturn}
                                setSelected={setOdontogramaComplementReturn}
                                subtitle="Aparelho Complementar"
                              />
                            </Modal>
                          </>
                        )}
                        {verifyChange07 && (
                          <TextField
                            label="Justifique a mudança"
                            onChange={(e) => setJustify07(e.target.value)}
                            sx={{ marginTop: "20px" }}
                            error={verifyChange07 && justify07.length < 1}
                            helperText={
                              verifyChange07 && justify07.length < 1
                                ? "Justifique sua alteração"
                                : ""
                            }
                          />
                        )}
                      </Styled.BoxIndication>
                    ) : (
                      ""
                    )}

                    {questionsPlanning.filter((item) => item.id === "PPQ008")
                      .length > 0 ? (
                      <Styled.BoxIndication>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={othersProceduresReturn}
                                onClick={() => {
                                  setOthersProceduresReturn(
                                    !othersProceduresReturn
                                  );
                                  setOtherProceduresValue([]);
                                  setVerifyChange08(true);
                                }}
                              />
                            }
                            label="Outros procedimentos"
                          />
                        </FormGroup>

                        {othersProceduresReturn && (
                          <>
                            <Autocomplete
                              options={proceduresData.map(
                                (option) => option.label
                              )}
                              multiple
                              sx={{ margin: "10px 0" }}
                              onChange={(event, newValue) => {
                                setOtherProceduresValue(newValue);
                                setVerifyChange08(true);
                              }}
                              value={otherProceduresValue}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Outros procedimentos"
                                />
                              )}
                            />
                          </>
                        )}
                        {verifyChange08 && (
                          <TextField
                            label="Justifique a mudança"
                            onChange={(e) => setJustify08(e.target.value)}
                            sx={{ marginTop: "20px" }}
                            error={verifyChange08 && justify08.length < 1}
                            helperText={
                              verifyChange08 && justify08.length < 1
                                ? "Justifique sua alteração"
                                : ""
                            }
                          />
                        )}
                      </Styled.BoxIndication>
                    ) : (
                      ""
                    )}

                    {questionsPlanning.filter((item) => item.id === "PPQ009")
                      .length > 0 ? (
                      <Styled.BoxIndication>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={othersReferralsReturn}
                                onClick={() => {
                                  setOthersReferralsReturn(
                                    !othersReferralsReturn
                                  );
                                  setVerifyChange09(true);
                                }}
                              />
                            }
                            label="Encaminhamento"
                          />
                        </FormGroup>

                        {othersReferralsReturn && (
                          <>
                            <Autocomplete
                              options={referralsData}
                              multiple
                              sx={{ margin: "10px 0" }}
                              onChange={(event, newValue) => {
                                setValueEncReturn(newValue);
                                setVerifyChange09(true);
                              }}
                              value={valueEncReturn}
                              renderInput={(params) => (
                                <TextField {...params} label="Encaminhamento" />
                              )}
                            />
                          </>
                        )}
                        {verifyChange09 && (
                          <TextField
                            label="Justifique a mudança"
                            onChange={(e) => setJustify09(e.target.value)}
                            sx={{ marginTop: "20px" }}
                            error={verifyChange09 && justify09.length < 1}
                            helperText={
                              verifyChange09 && justify09.length < 1
                                ? "Justifique sua alteração"
                                : ""
                            }
                          />
                        )}
                      </Styled.BoxIndication>
                    ) : (
                      ""
                    )}
                  </Styled.BoxPlanning>
                )}
              </>
            ) : (
              ""
            )}
          </>

          {patientObj.aname_id > 0 ? (
            <>
              <Styled.BoxIndication>
                <Typography
                  variant="h5"
                  sx={{ marginTop: "5px", marginBottom: "10px" }}
                >
                  Colagem de aparatologia ortodôntica
                </Typography>
                <Styled.BoxButtons style={{ marginBottom: "10px" }}>
                  <Button
                    variant={
                      braceType.includes("UPPER") ? "contained" : "outlined"
                    }
                    onClick={() => {
                      if (braceType.includes("UPPER")) {
                        setBraceType(
                          braceType.filter((type) => type !== "UPPER")
                        );
                      } else {
                        setBraceType([...braceType, "UPPER"]);
                      }
                      setShowPainPrescriptionNeeded(true);
                    }}
                    sx={{
                      "&:hover": {
                        backgroundColor: braceType.includes("UPPER")
                          ? "#007DED"
                          : "white",
                        color: braceType.includes("UPPER")
                          ? "white"
                          : "#007DED",
                        borderColor: braceType.includes("UPPER")
                          ? "#007DED"
                          : "#EEEFF1",
                      },
                    }}
                  >
                    Superior
                  </Button>
                  <Button
                    variant={
                      braceType.includes("LOWER") ? "contained" : "outlined"
                    }
                    onClick={() => {
                      if (braceType.includes("LOWER")) {
                        setBraceType(
                          braceType.filter((type) => type !== "LOWER")
                        );
                      } else {
                        setBraceType([...braceType, "LOWER"]);
                      }
                      setShowPainPrescriptionNeeded(true);
                    }}
                    sx={{
                      "&:hover": {
                        backgroundColor: braceType.includes("LOWER")
                          ? "#007DED"
                          : "white",
                        color: braceType.includes("LOWER")
                          ? "white"
                          : "#007DED",
                        borderColor: braceType.includes("LOWER")
                          ? "#007DED"
                          : "#EEEFF1",
                      },
                    }}
                  >
                    Inferior
                  </Button>
                </Styled.BoxButtons>
                {braceType.length > 0 && (
                  <>
                    {braceType.includes("UPPER") && (
                      <>
                        <Typography mt={2}>Superior</Typography>
                        <Box display="flex" alignItems="center" mb={2}>
                          <FormControl fullWidth>
                            <InputLabel
                              id="material-select-label"
                              sx={{
                                backgroundColor: "white",
                                padding: "2px",
                              }}
                            >
                              Material
                            </InputLabel>
                            <Select
                              displayEmpty
                              variant="outlined"
                              sx={{ width: "90%" }}
                              style={{ marginRight: 16 }}
                              onChange={(e) =>
                                setMaterialProcedure(e.target.value as string)
                              }
                              value={materialProcedure}
                            >
                              {materialOptions.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl fullWidth>
                            <InputLabel
                              id="number-select-label"
                              sx={{
                                backgroundColor: "white",
                                padding: "2px",
                              }}
                            >
                              N°
                            </InputLabel>
                            <Select
                              displayEmpty
                              variant="outlined"
                              sx={{ width: "90%" }}
                              style={{ marginRight: 16 }}
                              onChange={(e) =>
                                setNumberProcedure(e.target.value as string)
                              }
                              value={numberProcedure}
                            >
                              {numbers.map((item, index) => (
                                <MenuItem key={index} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <TextField
                            label="Outros"
                            onChange={(e) =>
                              setOthersProceduresFixed(e.target.value as string)
                            }
                            value={othersProceduresFixed}
                          ></TextField>
                        </Box>
                      </>
                    )}

                    {braceType.includes("LOWER") && (
                      <>
                        <Typography mt={2}>Inferior</Typography>
                        <Box display="flex" alignItems="center" mb={2}>
                          <FormControl fullWidth>
                            <InputLabel
                              id="material-select-label"
                              sx={{
                                backgroundColor: "white",
                                padding: "2px",
                              }}
                            >
                              Material
                            </InputLabel>
                            <Select
                              displayEmpty
                              variant="outlined"
                              sx={{ width: "90%" }}
                              style={{ marginRight: 16 }}
                              onChange={(e) =>
                                setMaterialProceduresInf(
                                  e.target.value as string
                                )
                              }
                              value={materialProceduresInf}
                            >
                              {materialOptions.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl fullWidth>
                            <InputLabel
                              id="number-select-label"
                              sx={{
                                backgroundColor: "white",
                                padding: "2px",
                              }}
                            >
                              N°
                            </InputLabel>
                            <Select
                              displayEmpty
                              variant="outlined"
                              sx={{ width: "90%" }}
                              style={{ marginRight: 16 }}
                              onChange={(e) =>
                                setNumberProceduresInf(e.target.value as string)
                              }
                              value={numberProceduresInf}
                            >
                              {numbers.map((item, index) => (
                                <MenuItem key={index} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <TextField
                            label="Outros"
                            onChange={(e) =>
                              setOthersProceduresInf(e.target.value as string)
                            }
                            value={othersProceduresInf}
                          ></TextField>
                        </Box>
                      </>
                    )}
                    <Button
                      sx={{ margin: "20px 0" }}
                      onClick={() => setModalAparat(true)}
                    >
                      Abrir Odontograma
                    </Button>

                    <Modal
                      open={modalAparat}
                      onClose={() => setModalAparat(false)}
                    >
                      <Odontograma
                        onClose={() => setModalAparat(false)}
                        selected={odontogramaAparat}
                        setSelected={setOdontogramaAparat}
                        subtitle="Colagem de aparatologia ortodôntica"
                      />
                    </Modal>
                  </>
                )}
              </Styled.BoxIndication>

              {/* OUTROS PROCEDIMENTOS DE SEGUNDA CONSULTA */}
              <Styled.BoxIndication>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={wireProcedures}
                        onClick={() => {
                          setWireProcedures(!wireProcedures);
                          setChangeWireProcedures([]); // Reseta o estado ao desmarcar
                        }}
                      />
                    }
                    label="Trocar Fio"
                  />
                </FormGroup>

                {wireProcedures && (
                  <>
                    <Styled.BoxButtons>
                      <Button
                        variant={
                          changeWireProcedures.includes("SUP")
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() => {
                          if (changeWireProcedures.includes("SUP")) {
                            setChangeWireProcedures(
                              changeWireProcedures.filter(
                                (type) => type !== "SUP"
                              )
                            );
                          } else {
                            setChangeWireProcedures([
                              ...changeWireProcedures,
                              "SUP",
                            ]);
                          }
                        }}
                      >
                        Superior
                      </Button>
                      <Button
                        variant={
                          changeWireProcedures.includes("INF")
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() => {
                          if (changeWireProcedures.includes("INF")) {
                            setChangeWireProcedures(
                              changeWireProcedures.filter(
                                (type) => type !== "INF"
                              )
                            );
                          } else {
                            setChangeWireProcedures([
                              ...changeWireProcedures,
                              "INF",
                            ]);
                          }
                        }}
                      >
                        Inferior
                      </Button>
                    </Styled.BoxButtons>

                    {changeWireProcedures.length > 0 && (
                      <>
                        {changeWireProcedures.includes("SUP") && (
                          <>
                            <Typography mt={2}>Superior</Typography>
                            <Box display="flex" alignItems="center">
                              <FormControl fullWidth>
                                <InputLabel
                                  id="demo-simple-select-label"
                                  sx={{
                                    backgroundColor: "white",
                                    padding: "2px",
                                  }}
                                >
                                  Material
                                </InputLabel>
                                <Select
                                  displayEmpty
                                  variant="outlined"
                                  sx={{ width: "90%" }}
                                  style={{ marginRight: 16 }}
                                  onChange={(e) =>
                                    setMaterialChangeProcedures(
                                      e.target.value as string
                                    )
                                  }
                                  value={materialChangeProcedures}
                                >
                                  {materialOptions.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <FormControl fullWidth>
                                <InputLabel
                                  id="demo-simple-select-label"
                                  sx={{
                                    backgroundColor: "white",
                                    padding: "2px",
                                  }}
                                >
                                  N°
                                </InputLabel>
                                <Select
                                  displayEmpty
                                  variant="outlined"
                                  sx={{ width: "90%" }}
                                  style={{ marginRight: 16 }}
                                  onChange={(e) =>
                                    setNumberChangeProcedures(
                                      e.target.value as string
                                    )
                                  }
                                  value={numberChangeProcedures}
                                >
                                  {numbers.map((item, index) => (
                                    <MenuItem key={index} value={item.value}>
                                      {item.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>

                              <TextField
                                label="Outros"
                                onChange={(e) =>
                                  setOthersChangeProcedures(
                                    e.target.value as string
                                  )
                                }
                                value={othersChangeProcedures}
                              />
                            </Box>
                          </>
                        )}

                        {changeWireProcedures.includes("INF") && (
                          <>
                            <Typography mt={2}>Inferior</Typography>
                            <Box display="flex" alignItems="center">
                              <FormControl fullWidth>
                                <InputLabel
                                  id="demo-simple-select-label"
                                  sx={{
                                    backgroundColor: "white",
                                    padding: "2px",
                                  }}
                                >
                                  Material
                                </InputLabel>
                                <Select
                                  displayEmpty
                                  variant="outlined"
                                  sx={{ width: "90%" }}
                                  style={{ marginRight: 16 }}
                                  onChange={(e) =>
                                    setMaterialChangeProceduresInf(
                                      e.target.value as string
                                    )
                                  }
                                  value={materialChangeProceduresInf}
                                >
                                  {materialOptions.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <FormControl fullWidth>
                                <InputLabel
                                  id="demo-simple-select-label"
                                  sx={{
                                    backgroundColor: "white",
                                    padding: "2px",
                                  }}
                                >
                                  N°
                                </InputLabel>
                                <Select
                                  displayEmpty
                                  variant="outlined"
                                  sx={{ width: "90%" }}
                                  style={{ marginRight: 16 }}
                                  onChange={(e) =>
                                    setNumberChangeProceduresInf(
                                      e.target.value as string
                                    )
                                  }
                                  value={numberChangeProceduresInf}
                                >
                                  {numbers.map((item, index) => (
                                    <MenuItem key={index} value={item.value}>
                                      {item.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>

                              <TextField
                                label="Outros"
                                onChange={(e) =>
                                  setOthersChangeProceduresInf(
                                    e.target.value as string
                                  )
                                }
                                value={othersChangeProceduresInf}
                              />
                            </Box>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </Styled.BoxIndication>

              <Styled.BoxIndication>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={keepThreadProcedures}
                        onClick={() => {
                          setKeepThreadProcedures(!keepThreadProcedures);
                          setKeepProcedures([]); // Reseta o estado ao desmarcar
                        }}
                      />
                    }
                    label="Manter Fio"
                  />
                </FormGroup>

                {keepThreadProcedures && (
                  <>
                    <Styled.BoxButtons>
                      <Button
                        variant={
                          keepProcedures.includes("SUP")
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() => {
                          if (keepProcedures.includes("SUP")) {
                            setKeepProcedures(
                              keepProcedures.filter((type) => type !== "SUP")
                            );
                          } else {
                            setKeepProcedures([...keepProcedures, "SUP"]);
                          }
                        }}
                      >
                        Superior
                      </Button>
                      <Button
                        variant={
                          keepProcedures.includes("INF")
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() => {
                          if (keepProcedures.includes("INF")) {
                            setKeepProcedures(
                              keepProcedures.filter((type) => type !== "INF")
                            );
                          } else {
                            setKeepProcedures([...keepProcedures, "INF"]);
                          }
                        }}
                      >
                        Inferior
                      </Button>
                    </Styled.BoxButtons>

                    {keepProcedures.length > 0 && (
                      <Box display="flex" alignItems="center" mt={2}>
                        <FormControl>
                          <RadioGroup
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                            onChange={(e) =>
                              setAtivationProcedures(e.target.value as string)
                            }
                            value={ativationProcedures}
                          >
                            <FormControlLabel
                              value="yes"
                              control={<Radio />}
                              label="Com ativação"
                            />
                            <FormControlLabel
                              value="no"
                              control={<Radio />}
                              label="Sem ativação"
                            />
                          </RadioGroup>
                        </FormControl>

                        <TextField label="Outros" />
                      </Box>
                    )}
                  </>
                )}
              </Styled.BoxIndication>

              <Styled.BoxIndication>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={cimentProcedures}
                        onClick={() => {
                          setCimentProcedures(!cimentProcedures);
                          setCimentBandProcedures([]); // Reseta o estado ao desmarcar
                        }}
                      />
                    }
                    label="Cimentação de banda"
                  />
                </FormGroup>

                {cimentProcedures && (
                  <>
                    <Styled.BoxButtons>
                      <Button
                        variant={
                          cimentBandProcedures.includes("SUP")
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() => {
                          if (cimentBandProcedures.includes("SUP")) {
                            setCimentBandProcedures(
                              cimentBandProcedures.filter(
                                (type) => type !== "SUP"
                              )
                            );
                          } else {
                            setCimentBandProcedures([
                              ...cimentBandProcedures,
                              "SUP",
                            ]);
                          }
                        }}
                      >
                        Superior
                      </Button>
                      <Button
                        variant={
                          cimentBandProcedures.includes("INF")
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() => {
                          if (cimentBandProcedures.includes("INF")) {
                            setCimentBandProcedures(
                              cimentBandProcedures.filter(
                                (type) => type !== "INF"
                              )
                            );
                          } else {
                            setCimentBandProcedures([
                              ...cimentBandProcedures,
                              "INF",
                            ]);
                          }
                        }}
                      >
                        Inferior
                      </Button>
                    </Styled.BoxButtons>

                    {cimentBandProcedures.length > 0 && (
                      <Button
                        onClick={() => setOpenModalCimentBand(true)}
                        sx={{ marginTop: "15px" }}
                      >
                        Abrir Odontograma
                      </Button>
                    )}

                    <Modal
                      open={openModalCimentBand}
                      onClose={() => setOpenModalCimentBand(false)}
                    >
                      <Odontograma
                        onClose={() => setOpenModalCimentBand(false)}
                        selected={odontogramaCimentProcedures}
                        setSelected={setOdontogramaCimentProcedures}
                        subtitle="Cimentação de banda - Procedimentos Realizados"
                      />
                    </Modal>
                  </>
                )}
              </Styled.BoxIndication>

              <Styled.BoxIndication>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={glueProcedures}
                        onClick={() => {
                          setGlueProcedures(!glueProcedures);
                          setGlueTubProcedures([]); // Reseta o estado ao desmarcar
                        }}
                      />
                    }
                    label="Colagem do tubo"
                  />
                </FormGroup>

                {glueProcedures && (
                  <>
                    <Styled.BoxButtons>
                      <Button
                        variant={
                          glueTubProcedures.includes("SUP")
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() => {
                          if (glueTubProcedures.includes("SUP")) {
                            setGlueTubProcedures(
                              glueTubProcedures.filter((type) => type !== "SUP")
                            );
                          } else {
                            setGlueTubProcedures([...glueTubProcedures, "SUP"]);
                          }
                        }}
                      >
                        Superior
                      </Button>
                      <Button
                        variant={
                          glueTubProcedures.includes("INF")
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() => {
                          if (glueTubProcedures.includes("INF")) {
                            setGlueTubProcedures(
                              glueTubProcedures.filter((type) => type !== "INF")
                            );
                          } else {
                            setGlueTubProcedures([...glueTubProcedures, "INF"]);
                          }
                        }}
                      >
                        Inferior
                      </Button>
                    </Styled.BoxButtons>

                    {glueTubProcedures.length > 0 && (
                      <Button onClick={() => setOpenModalColagemTub(true)}>
                        Abrir Odontograma
                      </Button>
                    )}

                    <Modal
                      open={openModalColagemTub}
                      onClose={() => setOpenModalColagemTub(false)}
                    >
                      <Odontograma
                        onClose={() => setOpenModalColagemTub(false)}
                        selected={odontogramaCollageProcedures}
                        setSelected={setOdontogramaCollageProcedures}
                        subtitle="Colagem do tubo - Procedimentos Realizados"
                      />
                    </Modal>
                  </>
                )}
              </Styled.BoxIndication>

              {/* OUTRAS PERGUNTAS ACIMA*/}

              <Styled.BoxIndication>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={rep}
                        onClick={() => {
                          setRep(!rep);
                        }}
                      />
                    }
                    label="Reposição ou Recolagem de Braquete"
                  />
                </FormGroup>
                {rep && (
                  <>
                    <Styled.BoxButtons>
                      <Button
                        variant={
                          repOrRec.includes("REP") ? "contained" : "outlined"
                        }
                        onClick={() => {
                          if (repOrRec.includes("REP")) {
                            setRepOrRec(
                              repOrRec.filter((type) => type !== "REP")
                            );
                          } else {
                            setRepOrRec([...repOrRec, "REP"]);
                          }
                        }}
                        sx={{
                          "&:hover": {
                            backgroundColor: repOrRec.includes("REP")
                              ? "#007DED"
                              : "white",
                            color: repOrRec.includes("REP")
                              ? "white"
                              : "#007DED",
                            borderColor: repOrRec.includes("REP")
                              ? "#007DED"
                              : "#EEEFF1",
                          },
                        }}
                      >
                        Reposição
                      </Button>
                      <Button
                        variant={
                          repOrRec.includes("REC") ? "contained" : "outlined"
                        }
                        onClick={() => {
                          if (repOrRec.includes("REC")) {
                            setRepOrRec(
                              repOrRec.filter((type) => type !== "REC")
                            );
                          } else {
                            setRepOrRec([...repOrRec, "REC"]);
                          }
                        }}
                        sx={{
                          "&:hover": {
                            backgroundColor: repOrRec.includes("REC")
                              ? "#007DED"
                              : "white",
                            color: repOrRec.includes("REC")
                              ? "white"
                              : "#007DED",
                            borderColor: repOrRec.includes("REC")
                              ? "#007DED"
                              : "#EEEFF1",
                          },
                        }}
                      >
                        Recolagem
                      </Button>
                    </Styled.BoxButtons>

                    {(repOrRec.includes("REP") || repOrRec.includes("REC")) && (
                      <Styled.BoxButtons style={{ marginTop: "15px" }}>
                        <Button
                          variant={
                            repOrRecBrace.includes("SUP")
                              ? "contained"
                              : "outlined"
                          }
                          onClick={() => {
                            if (repOrRecBrace.includes("SUP")) {
                              setRepOrRecBrace(
                                repOrRecBrace.filter((type) => type !== "SUP")
                              );
                            } else {
                              setRepOrRecBrace([...repOrRecBrace, "SUP"]);
                            }
                          }}
                          sx={{
                            "&:hover": {
                              backgroundColor: repOrRecBrace.includes("SUP")
                                ? "#007DED"
                                : "white",
                              color: repOrRecBrace.includes("SUP")
                                ? "white"
                                : "#007DED",
                              borderColor: repOrRecBrace.includes("SUP")
                                ? "#007DED"
                                : "#EEEFF1",
                            },
                          }}
                        >
                          Superior
                        </Button>
                        <Button
                          variant={
                            repOrRecBrace.includes("INF")
                              ? "contained"
                              : "outlined"
                          }
                          onClick={() => {
                            if (repOrRecBrace.includes("INF")) {
                              setRepOrRecBrace(
                                repOrRecBrace.filter((type) => type !== "INF")
                              );
                            } else {
                              setRepOrRecBrace([...repOrRecBrace, "INF"]);
                            }
                          }}
                          sx={{
                            "&:hover": {
                              backgroundColor: repOrRecBrace.includes("INF")
                                ? "#007DED"
                                : "white",
                              color: repOrRecBrace.includes("INF")
                                ? "white"
                                : "#007DED",
                              borderColor: repOrRecBrace.includes("INF")
                                ? "#007DED"
                                : "#EEEFF1",
                            },
                          }}
                        >
                          Inferior
                        </Button>
                      </Styled.BoxButtons>
                    )}

                    {(repOrRecBrace.includes("SUP") ||
                      repOrRecBrace.includes("INF")) && (
                      <>
                        <Button
                          onClick={() => setModalRecOrRep(true)}
                          sx={{ marginTop: "10px" }}
                        >
                          Abrir Odontogama
                        </Button>
                        <Modal
                          open={modalRecOrRep}
                          onClose={() => setModalRecOrRep(false)}
                        >
                          <Odontograma
                            onClose={() => setModalRecOrRep(false)}
                            selected={odontogramaRepOrRec}
                            setSelected={setOdontogramaRepOrRec}
                            subtitle="Reposição ou Recolagem"
                          />
                        </Modal>
                      </>
                    )}
                  </>
                )}
              </Styled.BoxIndication>

              <Styled.BoxIndication>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={hasComplement}
                        onClick={() => {
                          setHasComplement(!hasComplement);
                          setAdditionalDevice([]);
                        }}
                      />
                    }
                    label="Aparelho Complementar"
                  />
                </FormGroup>
                {hasComplement && (
                  <>
                    <Autocomplete
                      options={complementary.map((option) => option.label)}
                      multiple
                      sx={{ margin: "10px 0" }}
                      onChange={(event, newValue) => {
                        setAdditionalDevice(newValue);
                      }}
                      value={additionalDevice}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          value={additionalDevice}
                          label="Aparelho complementar"
                        />
                      )}
                    />
                    {additionalDevice.length > 0 && (
                      <>
                        <Button sx={{ margin: "20px 0" }}>
                          Abrir Odontograma
                        </Button>
                        <Modal
                          open={modalComplement}
                          onClose={() => setModalComplement(false)}
                        >
                          <Odontograma
                            onClose={() => setModalComplement(false)}
                            selected={odontogramaComplement}
                            setSelected={setOdontogramaComplement}
                            subtitle="Aparelho Complementar"
                          />
                        </Modal>
                      </>
                    )}
                  </>
                )}
              </Styled.BoxIndication>

              <Styled.BoxIndication>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={hasOtherProcedures}
                        onClick={() =>
                          setHasOtherProcedures(!hasOtherProcedures)
                        }
                      />
                    }
                    label="Outros procedimentos"
                  />
                </FormGroup>
                {hasOtherProcedures && (
                  <>
                    <Autocomplete
                      options={proceduresData.map((option) => option.label)}
                      multiple
                      sx={{ margin: "10px 0" }}
                      onChange={(event, newValue) =>
                        setOtherProceduresValueSecondConsult(newValue)
                      }
                      value={otherProceduresValueSecondConsult}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          value={otherProceduresValueSecondConsult}
                          label="Outros procedimentos"
                        />
                      )}
                    />
                  </>
                )}
              </Styled.BoxIndication>

              <Styled.BoxIndication>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={hasOtherReferrals}
                        onClick={() => setHasOtherReferrals(!hasOtherReferrals)}
                      />
                    }
                    label="Encaminhamentos"
                  />
                </FormGroup>
                {hasOtherReferrals && (
                  <>
                    <Autocomplete
                      options={referralsData.map((option) => option.label)}
                      multiple
                      sx={{ margin: "10px 0" }}
                      onChange={(event, newValue) => {
                        setReferralValue(newValue);
                      }}
                      value={referralValue}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          value={referralValue}
                          label="Encaminhamentos"
                        />
                      )}
                    />
                  </>
                )}
              </Styled.BoxIndication>

              <Styled.BoxIndication>
                <Typography
                  variant="h5"
                  sx={{ marginTop: "5px", marginBottom: "10px" }}
                >
                  Precisa de receita para o caso de dor?
                </Typography>
                <Styled.BoxButtons style={{ marginBottom: "10px" }}>
                  <Button
                    variant={
                      painPrescriptionNeeded === "YES"
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => {
                      setPainPrescriptionNeeded("YES");
                      setShowInformedBasicCare(true);
                    }}
                  >
                    Sim
                  </Button>
                  <Button
                    variant={
                      painPrescriptionNeeded === "NO" ? "contained" : "outlined"
                    }
                    onClick={() => {
                      setPainPrescriptionNeeded("NO");
                      setShowInformedBasicCare(true);
                    }}
                  >
                    Não
                  </Button>
                </Styled.BoxButtons>
              </Styled.BoxIndication>

              <Styled.BoxIndication>
                <Typography
                  variant="h5"
                  sx={{ marginTop: "5px", marginBottom: "10px" }}
                >
                  Informou o paciente sobre os cuidados básicos com o aparelho?
                </Typography>
                <Styled.BoxButtons style={{ marginBottom: "10px" }}>
                  <Button
                    variant={
                      informedBasicCare === "YES" ? "contained" : "outlined"
                    }
                    onClick={() => {
                      setInformedBasicCare("YES");
                      setShowDeliveredTreatmentManual(true);
                    }}
                  >
                    Sim
                  </Button>
                  <Button
                    variant={
                      informedBasicCare === "NO" ? "contained" : "outlined"
                    }
                    onClick={() => {
                      setInformedBasicCare("NO");
                      setShowDeliveredTreatmentManual(true);
                    }}
                  >
                    Não
                  </Button>
                </Styled.BoxButtons>
                {informedBasicCare === "NO" && (
                  <TextField
                    fullWidth
                    label="Justifique"
                    variant="outlined"
                    sx={{ marginTop: "10px", paddingBottom: "5px" }}
                    value={basicCareObservations}
                    onChange={(e) => {
                      setBasicCareObservations(e.target.value);
                      setErrorBasicCare(false);
                    }}
                    error={errorBasicCare}
                    helperText={
                      errorBasicCare ? "Justificação é obrigatória" : ""
                    }
                  />
                )}
              </Styled.BoxIndication>

              <Styled.BoxIndication>
                <Typography
                  variant="h5"
                  sx={{ marginTop: "5px", marginBottom: "10px" }}
                >
                  Orientou o paciente a solicitar passa fio e cera na recepção?
                </Typography>
                <Styled.BoxButtons style={{ marginBottom: "10px" }}>
                  <Button
                    variant={
                      advisedWireAndWax === "YES" ? "contained" : "outlined"
                    }
                    onClick={() => {
                      setAdvisedWireAndWax("YES");
                      setShowAdditionalDevice(true);
                    }}
                  >
                    Sim
                  </Button>
                  <Button
                    variant={
                      advisedWireAndWax === "NO" ? "contained" : "outlined"
                    }
                    onClick={() => {
                      setAdvisedWireAndWax("NO");
                      setShowAdditionalDevice(true);
                    }}
                  >
                    Não
                  </Button>
                </Styled.BoxButtons>
                {advisedWireAndWax === "NO" && (
                  <TextField
                    fullWidth
                    label="Justifique"
                    variant="outlined"
                    sx={{ marginTop: "10px", paddingBottom: "5px" }}
                    value={wireAndWaxObservations}
                    onChange={(e) => {
                      setWireAndWaxObservations(e.target.value);
                      setErrorWireAndWax(false);
                    }}
                    error={errorWireAndWax}
                    helperText={
                      errorWireAndWax ? "Justificação é obrigatória" : ""
                    }
                  />
                )}
              </Styled.BoxIndication>

              {questionsPlanning.length > 0 &&
              questionsPlanning.filter((item) => item.id === "PPQ001").length >
                0 ? (
                <Styled.BoxIndication>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "15px",
                      }}
                    >
                      <FaVideo color="#007DED" size={30} /> Vídeo do
                      funcionamento do aparelho
                    </Typography>
                    <Button
                      onClick={() => {
                        setOpenModalVideo(true);
                        setVideoClicked(true);
                      }}
                    >
                      Ver vídeo
                    </Button>
                  </Box>
                </Styled.BoxIndication>
              ) : (
                ""
              )}

              {braceType.length > 0 && questionsPlanning.length < 1 && (
                <Styled.BoxIndication>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "15px",
                      }}
                    >
                      <FaVideo color="#007DED" size={30} /> Vídeo do
                      funcionamento do aparelho
                    </Typography>
                    <Button
                      onClick={() => {
                        setOpenModalVideo(true);
                        setVideoClicked(true);
                      }}
                    >
                      Ver vídeo
                    </Button>
                  </Box>
                </Styled.BoxIndication>
              )}

              <Styled.BoxIndication>
                <TextField
                  label="Observações gerais"
                  fullWidth
                  margin="normal"
                  value={allObservationsProcedure}
                  onChange={(e) => setAllObservationsProcedure(e.target.value)}
                />
              </Styled.BoxIndication>
            </>
          ) : (
            <>
              <Styled.BoxIndication>
                <Typography
                  variant="h5"
                  sx={{ marginTop: "5px", marginBottom: "10px" }}
                >
                  Colagem de aparatologia ortodôntica
                </Typography>
                <Styled.BoxButtons style={{ marginBottom: "10px" }}>
                  <Button
                    variant={
                      braceType.includes("UPPER") ? "contained" : "outlined"
                    }
                    onClick={() => {
                      if (braceType.includes("UPPER")) {
                        setBraceType(
                          braceType.filter((type) => type !== "UPPER")
                        );
                      } else {
                        setBraceType([...braceType, "UPPER"]);
                      }
                      setShowPainPrescriptionNeeded(true);
                    }}
                    sx={{
                      "&:hover": {
                        backgroundColor: braceType.includes("UPPER")
                          ? "#007DED"
                          : "white",
                        color: braceType.includes("UPPER")
                          ? "white"
                          : "#007DED",
                        borderColor: braceType.includes("UPPER")
                          ? "#007DED"
                          : "#EEEFF1",
                      },
                    }}
                  >
                    Superior
                  </Button>
                  <Button
                    variant={
                      braceType.includes("LOWER") ? "contained" : "outlined"
                    }
                    onClick={() => {
                      if (braceType.includes("LOWER")) {
                        setBraceType(
                          braceType.filter((type) => type !== "LOWER")
                        );
                      } else {
                        setBraceType([...braceType, "LOWER"]);
                      }
                      setShowPainPrescriptionNeeded(true);
                    }}
                    sx={{
                      "&:hover": {
                        backgroundColor: braceType.includes("LOWER")
                          ? "#007DED"
                          : "white",
                        color: braceType.includes("LOWER")
                          ? "white"
                          : "#007DED",
                        borderColor: braceType.includes("LOWER")
                          ? "#007DED"
                          : "#EEEFF1",
                      },
                    }}
                  >
                    Inferior
                  </Button>
                </Styled.BoxButtons>
                {braceType.length > 0 && (
                  <>
                    {braceType.includes("UPPER") && (
                      <>
                        <Typography mt={2}>Superior</Typography>
                        <Box display="flex" alignItems="center" mb={2}>
                          <FormControl fullWidth>
                            <InputLabel
                              id="material-select-label"
                              sx={{
                                backgroundColor: "white",
                                padding: "2px",
                              }}
                            >
                              Material
                            </InputLabel>
                            <Select
                              displayEmpty
                              variant="outlined"
                              sx={{ width: "90%" }}
                              style={{ marginRight: 16 }}
                              onChange={(e) =>
                                setMaterialProcedure(e.target.value as string)
                              }
                              value={materialProcedure}
                            >
                              {materialOptions.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl fullWidth>
                            <InputLabel
                              id="number-select-label"
                              sx={{
                                backgroundColor: "white",
                                padding: "2px",
                              }}
                            >
                              N°
                            </InputLabel>
                            <Select
                              displayEmpty
                              variant="outlined"
                              sx={{ width: "90%" }}
                              style={{ marginRight: 16 }}
                              onChange={(e) =>
                                setNumberProcedure(e.target.value as string)
                              }
                              value={numberProcedure}
                            >
                              {numbers.map((item, index) => (
                                <MenuItem key={index} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <TextField
                            label="Outros"
                            onChange={(e) =>
                              setOthersProceduresFixed(e.target.value as string)
                            }
                            value={othersProceduresFixed}
                          ></TextField>
                        </Box>
                      </>
                    )}

                    {braceType.includes("LOWER") && (
                      <>
                        <Typography mt={2}>Inferior</Typography>
                        <Box display="flex" alignItems="center" mb={2}>
                          <FormControl fullWidth>
                            <InputLabel
                              id="material-select-label"
                              sx={{
                                backgroundColor: "white",
                                padding: "2px",
                              }}
                            >
                              Material
                            </InputLabel>
                            <Select
                              displayEmpty
                              variant="outlined"
                              sx={{ width: "90%" }}
                              style={{ marginRight: 16 }}
                              onChange={(e) =>
                                setMaterialProceduresInf(
                                  e.target.value as string
                                )
                              }
                              value={materialProceduresInf}
                            >
                              {materialOptions.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl fullWidth>
                            <InputLabel
                              id="number-select-label"
                              sx={{
                                backgroundColor: "white",
                                padding: "2px",
                              }}
                            >
                              N°
                            </InputLabel>
                            <Select
                              displayEmpty
                              variant="outlined"
                              sx={{ width: "90%" }}
                              style={{ marginRight: 16 }}
                              onChange={(e) =>
                                setNumberProceduresInf(e.target.value as string)
                              }
                              value={numberProceduresInf}
                            >
                              {numbers.map((item, index) => (
                                <MenuItem key={index} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <TextField
                            label="Outros"
                            onChange={(e) =>
                              setOthersProceduresInf(e.target.value as string)
                            }
                            value={othersProceduresInf}
                          ></TextField>
                        </Box>
                      </>
                    )}

                    <Button
                      sx={{ margin: "20px 0" }}
                      onClick={() => setModalAparat(true)}
                    >
                      Abrir Odontograma
                    </Button>

                    <Modal
                      open={modalAparat}
                      onClose={() => setModalAparat(false)}
                    >
                      <Odontograma
                        onClose={() => setModalAparat(false)}
                        selected={odontogramaAparat}
                        setSelected={setOdontogramaAparat}
                        subtitle="Colagem de aparatologia ortodôntica"
                      />
                    </Modal>
                  </>
                )}
              </Styled.BoxIndication>

              <Styled.BoxIndication>
                <Typography
                  variant="h5"
                  sx={{ marginTop: "5px", marginBottom: "10px" }}
                >
                  Foi realizada a limpeza?
                </Typography>
                <Styled.BoxButtons style={{ marginBottom: "10px" }}>
                  <Button
                    variant={realizeClear === "YES" ? "contained" : "outlined"}
                    onClick={() => {
                      setRealizeClear("YES");
                    }}
                  >
                    Sim
                  </Button>
                  <Button
                    variant={realizeClear === "NO" ? "contained" : "outlined"}
                    onClick={() => {
                      setRealizeClear("NO");
                    }}
                  >
                    Não
                  </Button>
                </Styled.BoxButtons>
              </Styled.BoxIndication>

              <Styled.BoxIndication>
                <Typography
                  variant="h5"
                  sx={{ marginTop: "5px", marginBottom: "10px" }}
                >
                  Precisa de receita para o caso de dor?
                </Typography>
                <Styled.BoxButtons style={{ marginBottom: "10px" }}>
                  <Button
                    variant={
                      painPrescriptionNeeded === "YES"
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => {
                      setPainPrescriptionNeeded("YES");
                      setShowInformedBasicCare(true);
                    }}
                  >
                    Sim
                  </Button>
                  <Button
                    variant={
                      painPrescriptionNeeded === "NO" ? "contained" : "outlined"
                    }
                    onClick={() => {
                      setPainPrescriptionNeeded("NO");
                      setShowInformedBasicCare(true);
                    }}
                  >
                    Não
                  </Button>
                </Styled.BoxButtons>
              </Styled.BoxIndication>

              <Styled.BoxIndication>
                <Typography
                  variant="h5"
                  sx={{ marginTop: "5px", marginBottom: "10px" }}
                >
                  Informou o paciente sobre os cuidados básicos com o aparelho?
                </Typography>
                <Styled.BoxButtons style={{ marginBottom: "10px" }}>
                  <Button
                    variant={
                      informedBasicCare === "YES" ? "contained" : "outlined"
                    }
                    onClick={() => {
                      setInformedBasicCare("YES");
                      setShowDeliveredTreatmentManual(true);
                    }}
                  >
                    Sim
                  </Button>
                  <Button
                    variant={
                      informedBasicCare === "NO" ? "contained" : "outlined"
                    }
                    onClick={() => {
                      setInformedBasicCare("NO");
                      setShowDeliveredTreatmentManual(true);
                    }}
                  >
                    Não
                  </Button>
                </Styled.BoxButtons>
                {informedBasicCare === "NO" && (
                  <TextField
                    fullWidth
                    label="Justifique"
                    variant="outlined"
                    sx={{ marginTop: "10px", paddingBottom: "5px" }}
                    value={basicCareObservations}
                    onChange={(e) => {
                      setBasicCareObservations(e.target.value);
                      setErrorBasicCare(false);
                    }}
                    error={errorBasicCare}
                    helperText={
                      errorBasicCare ? "Justificação é obrigatória" : ""
                    }
                  />
                )}
              </Styled.BoxIndication>

              <Styled.BoxIndication>
                <Typography
                  variant="h5"
                  sx={{ marginTop: "5px", marginBottom: "10px" }}
                >
                  Entregou o manual do tratamento ortodôntico?
                </Typography>
                <Styled.BoxButtons style={{ marginBottom: "10px" }}>
                  <Button
                    variant={
                      deliveredTreatmentManual === "YES"
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => {
                      setDeliveredTreatmentManual("YES");
                      setShowAdvisedWireAndWax(true);
                    }}
                  >
                    Sim
                  </Button>
                  <Button
                    variant={
                      deliveredTreatmentManual === "NO"
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => {
                      setDeliveredTreatmentManual("NO");
                      setShowAdvisedWireAndWax(true);
                    }}
                  >
                    Não
                  </Button>
                </Styled.BoxButtons>
                {deliveredTreatmentManual === "NO" && (
                  <TextField
                    fullWidth
                    label="Justifique"
                    variant="outlined"
                    sx={{ marginTop: "10px", paddingBottom: "5px" }}
                    value={treatmentManualObservations}
                    onChange={(e) => {
                      setTreatmentManualObservations(e.target.value);
                      setErrorTreatmentManual(false);
                    }}
                    error={errorTreatmentManual}
                    helperText={
                      errorTreatmentManual ? "Justificação é obrigatória" : ""
                    }
                  />
                )}
              </Styled.BoxIndication>

              <Styled.BoxIndication>
                <Typography
                  variant="h5"
                  sx={{ marginTop: "5px", marginBottom: "10px" }}
                >
                  Orientou o paciente a solicitar passa fio e cera na recepção?
                </Typography>
                <Styled.BoxButtons style={{ marginBottom: "10px" }}>
                  <Button
                    variant={
                      advisedWireAndWax === "YES" ? "contained" : "outlined"
                    }
                    onClick={() => {
                      setAdvisedWireAndWax("YES");
                      setShowAdditionalDevice(true);
                    }}
                  >
                    Sim
                  </Button>
                  <Button
                    variant={
                      advisedWireAndWax === "NO" ? "contained" : "outlined"
                    }
                    onClick={() => {
                      setAdvisedWireAndWax("NO");
                      setShowAdditionalDevice(true);
                    }}
                  >
                    Não
                  </Button>
                </Styled.BoxButtons>
                {advisedWireAndWax === "NO" && (
                  <TextField
                    fullWidth
                    label="Justifique"
                    variant="outlined"
                    sx={{ marginTop: "10px", paddingBottom: "5px" }}
                    value={wireAndWaxObservations}
                    onChange={(e) => {
                      setWireAndWaxObservations(e.target.value);
                      setErrorWireAndWax(false);
                    }}
                    error={errorWireAndWax}
                    helperText={
                      errorWireAndWax ? "Justificação é obrigatória" : ""
                    }
                  />
                )}
              </Styled.BoxIndication>

              <Styled.BoxIndication>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={rep}
                        onClick={() => {
                          setRep(!rep);
                        }}
                      />
                    }
                    label="Reposição ou Recolagem de Braquete"
                  />
                </FormGroup>
                {rep && (
                  <>
                    <Styled.BoxButtons>
                      <Button
                        variant={
                          repOrRec.includes("REP") ? "contained" : "outlined"
                        }
                        onClick={() => {
                          if (repOrRec.includes("REP")) {
                            setRepOrRec(
                              repOrRec.filter((type) => type !== "REP")
                            );
                          } else {
                            setRepOrRec([...repOrRec, "REP"]);
                          }
                        }}
                        sx={{
                          "&:hover": {
                            backgroundColor: repOrRec.includes("REP")
                              ? "#007DED"
                              : "white",
                            color: repOrRec.includes("REP")
                              ? "white"
                              : "#007DED",
                            borderColor: repOrRec.includes("REP")
                              ? "#007DED"
                              : "#EEEFF1",
                          },
                        }}
                      >
                        Reposição
                      </Button>
                      <Button
                        variant={
                          repOrRec.includes("REC") ? "contained" : "outlined"
                        }
                        onClick={() => {
                          if (repOrRec.includes("REC")) {
                            setRepOrRec(
                              repOrRec.filter((type) => type !== "REC")
                            );
                          } else {
                            setRepOrRec([...repOrRec, "REC"]);
                          }
                        }}
                        sx={{
                          "&:hover": {
                            backgroundColor: repOrRec.includes("REC")
                              ? "#007DED"
                              : "white",
                            color: repOrRec.includes("REC")
                              ? "white"
                              : "#007DED",
                            borderColor: repOrRec.includes("REC")
                              ? "#007DED"
                              : "#EEEFF1",
                          },
                        }}
                      >
                        Recolagem
                      </Button>
                    </Styled.BoxButtons>

                    {(repOrRec.includes("REP") || repOrRec.includes("REC")) && (
                      <Styled.BoxButtons style={{ marginTop: "15px" }}>
                        <Button
                          variant={
                            repOrRecBrace.includes("SUP")
                              ? "contained"
                              : "outlined"
                          }
                          onClick={() => {
                            if (repOrRecBrace.includes("SUP")) {
                              setRepOrRecBrace(
                                repOrRecBrace.filter((type) => type !== "SUP")
                              );
                            } else {
                              setRepOrRecBrace([...repOrRecBrace, "SUP"]);
                            }
                          }}
                          sx={{
                            "&:hover": {
                              backgroundColor: repOrRecBrace.includes("SUP")
                                ? "#007DED"
                                : "white",
                              color: repOrRecBrace.includes("SUP")
                                ? "white"
                                : "#007DED",
                              borderColor: repOrRecBrace.includes("SUP")
                                ? "#007DED"
                                : "#EEEFF1",
                            },
                          }}
                        >
                          Superior
                        </Button>
                        <Button
                          variant={
                            repOrRecBrace.includes("INF")
                              ? "contained"
                              : "outlined"
                          }
                          onClick={() => {
                            if (repOrRecBrace.includes("INF")) {
                              setRepOrRecBrace(
                                repOrRecBrace.filter((type) => type !== "INF")
                              );
                            } else {
                              setRepOrRecBrace([...repOrRecBrace, "INF"]);
                            }
                          }}
                          sx={{
                            "&:hover": {
                              backgroundColor: repOrRecBrace.includes("INF")
                                ? "#007DED"
                                : "white",
                              color: repOrRecBrace.includes("INF")
                                ? "white"
                                : "#007DED",
                              borderColor: repOrRecBrace.includes("INF")
                                ? "#007DED"
                                : "#EEEFF1",
                            },
                          }}
                        >
                          Inferior
                        </Button>
                      </Styled.BoxButtons>
                    )}

                    {(repOrRecBrace.includes("SUP") ||
                      repOrRecBrace.includes("INF")) && (
                      <>
                        <Button
                          onClick={() => setModalRecOrRep(true)}
                          sx={{ marginTop: "10px" }}
                        >
                          Abrir Odontogama
                        </Button>
                        <Modal
                          open={modalRecOrRep}
                          onClose={() => setModalRecOrRep(false)}
                        >
                          <Odontograma
                            onClose={() => setModalRecOrRep(false)}
                            selected={odontogramaRepOrRec}
                            setSelected={setOdontogramaRepOrRec}
                            subtitle="Reposição ou Recolagem"
                          />
                        </Modal>
                      </>
                    )}
                  </>
                )}
              </Styled.BoxIndication>

              {questionsPlanning.length > 0 &&
              questionsPlanning.filter((item) => item.id === "PPQ001").length >
                0 ? (
                <Styled.BoxIndication>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "15px",
                      }}
                    >
                      <FaVideo color="#007DED" size={30} /> Vídeo do
                      funcionamento do aparelho
                    </Typography>
                    <Button
                      onClick={() => {
                        setOpenModalVideo(true);
                        setVideoClicked(true);
                      }}
                    >
                      Ver vídeo
                    </Button>
                  </Box>
                </Styled.BoxIndication>
              ) : (
                ""
              )}

              {braceType.length > 0 && questionsPlanning.length < 1 && (
                <Styled.BoxIndication>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "15px",
                      }}
                    >
                      <FaVideo color="#007DED" size={30} /> Vídeo do
                      funcionamento do aparelho
                    </Typography>
                    <Button
                      onClick={() => {
                        setOpenModalVideo(true);
                        setVideoClicked(true);
                      }}
                    >
                      Ver vídeo
                    </Button>
                  </Box>
                </Styled.BoxIndication>
              )}

              <Styled.BoxIndication>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={hasComplement}
                        onClick={() => {
                          setHasComplement(!hasComplement);
                          setAdditionalDevice([]);
                        }}
                      />
                    }
                    label="Aparelho Complementar"
                  />
                </FormGroup>
                {hasComplement && (
                  <>
                    <Autocomplete
                      options={complementary}
                      multiple
                      sx={{ margin: "10px 0" }}
                      onChange={(event, newValue) => {
                        setAdditionalDevice(newValue);
                      }}
                      value={additionalDevice}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          value={additionalDevice}
                          label="Aparelho complementar"
                        />
                      )}
                    />
                    {additionalDevice.length > 0 && (
                      <>
                        <Button sx={{ margin: "20px 0" }}>
                          Abrir Odontograma
                        </Button>
                        <Modal
                          open={modalComplement}
                          onClose={() => setModalComplement(false)}
                        >
                          <Odontograma
                            onClose={() => setModalComplement(false)}
                            selected={odontogramaComplement}
                            setSelected={setOdontogramaComplement}
                            subtitle="Aparelho Complementar"
                          />
                        </Modal>
                      </>
                    )}
                  </>
                )}
              </Styled.BoxIndication>

              <Styled.BoxIndication>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={hasOtherProcedures}
                        onClick={() =>
                          setHasOtherProcedures(!hasOtherProcedures)
                        }
                      />
                    }
                    label="Outros procedimentos"
                  />
                </FormGroup>
                {hasOtherProcedures && (
                  <>
                    <Autocomplete
                      options={proceduresData.map((option) => option.label)}
                      multiple
                      sx={{ margin: "10px 0" }}
                      onChange={(event, newValue) =>
                        setOtherProceduresValueSecondConsult(newValue)
                      }
                      value={otherProceduresValueSecondConsult}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          value={otherProceduresValueSecondConsult}
                          label="Outros procedimentos"
                        />
                      )}
                    />
                  </>
                )}
              </Styled.BoxIndication>

              <Styled.BoxIndication>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={hasOtherReferrals}
                        onClick={() => setHasOtherReferrals(!hasOtherReferrals)}
                      />
                    }
                    label="Encaminhamentos"
                  />
                </FormGroup>
                {hasOtherReferrals && (
                  <>
                    <Autocomplete
                      options={referralsData}
                      multiple
                      sx={{ margin: "10px 0" }}
                      onChange={(event, newValue) => setReferralValue(newValue)}
                      value={referralValue}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          value={referralValue}
                          label="Encaminhamentos"
                        />
                      )}
                    />
                  </>
                )}
              </Styled.BoxIndication>

              <Styled.BoxIndication>
                <TextField
                  label="Observações gerais"
                  fullWidth
                  margin="normal"
                  value={allObservationsProcedure}
                  onChange={(e) => setAllObservationsProcedure(e.target.value)}
                />
              </Styled.BoxIndication>
            </>
          )}

          {patientObj.aname_id > 0 ? (
            <Styled.BtnFixedAnam>
              <Button fullWidth onClick={next}>
                Continuar
              </Button>
            </Styled.BtnFixedAnam>
          ) : (
            <Styled.BtnFixed>
              <Button fullWidth onClick={() => setStep(2)} variant="outlined">
                Voltar
              </Button>
              <Button fullWidth onClick={next}>
                Continuar
              </Button>
            </Styled.BtnFixed>
          )}

          <Modal open={openModalVideo} onClose={() => setOpenModalVideo(false)}>
            <Box
              sx={{
                backgroundColor: "white",
                padding: "25px",
                borderRadius: "5px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "25px",
                width: "90%",
              }}
            >
              {patientObj.aname_id > 0 ? (
                <iframe
                  width="100%"
                  height="385"
                  src="https://www.youtube.com/embed/xQaS7drnHBo"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              ) : (
                <iframe
                  width="100%"
                  height="385"
                  src="https://www.youtube.com/embed/0Hm09_f0nXY"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              )}

              <Button fullWidth onClick={() => setOpenModalVideo(false)}>
                Fechar
              </Button>
            </Box>
          </Modal>
        </>
      )}
    </Styled.Box>
  );
};

export { ProcedurePerformedFixed };
