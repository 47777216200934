import React from "react";
import * as Styled from "./styles";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useFirstConsult } from "shared/providers/FirstConsult";

import { materialOptions, numbers } from "shared/mock";

import { useAuth } from "shared/providers/auth";
import toast from "react-hot-toast";
import { Odontograma } from "components/Odontograma";

const ProcedurePerformedInvisible: React.FC = () => {
  const {
    setStep,

    hasOtherProcedures,
    setHasOtherProcedures,
    otherProceduresValue,
    setOtherProceduresValue,

    patientObj,
    questionsPlanning,
    aparateReturn,
    setAparateReturn,
    aparateBraceReturn,
    setAparateBraceReturn,
    materialReturn,
    setMaterialReturn,
    numberReturn,
    setNumberReturn,
    othersReturn,
    setOthersReturn,
    wireReturn,
    setWireReturn,
    changeWireReturn,
    setChangeWireReturn,
    materialChangeReturn,
    setMaterialChangeReturn,
    numberChangeReturn,
    setNumberChangeReturn,
    othersChangeReturn,
    setOthersChangeReturn,
    keepThreadReturn,
    setKeepThreadReturn,
    keepReturn,
    setKeepReturn,
    setAtivationReturn,
    ativationReturn,
    cimentReturn,
    setCimentReturn,
    cimentBandReturn,
    setCimentBandReturn,
    glueTubReturn,
    glueReturn,
    setGlueTubReturn,
    setGlueReturn,
    complementReturn,
    setComplementReturn,
    compReturn,
    setCompReturn,
    othersProceduresReturn,
    setOthersProceduresReturn,
    //setValueOthersReturn,
    othersReferralsReturn,
    setOthersReferralsReturn,
    setValueEncReturn,
    //valueOthersReturn,
    valueEncReturn,
    justify01,
    setJustify01,
    justify02,
    setJustify02,
    justify03,
    setJustify03,
    justify04,
    setJustify04,
    justify05,
    setJustify05,
    justify07,
    setJustify07,
    justify08,
    setJustify08,
    justify09,
    setJustify09,

    realize,
    setRealize,
    setJustification,
    realizeContent,
    setRealizeContent,
    realizeAparat,
    setRealizeAparat,
    realizeEruption,
    setRealizeEruption,
    realizeIntermaxilary,
    setRealizeIntermaxilary,
    realizeCollage,
    setRealizeCollage,
    realizeRebonding,
    setRealizeRebonding,
    realizeBite,
    setRealizeBite,
    route,
    setRoute,
    setJustifyRoute,
    realizeRelease,
    setRealizeRelease,
    setJustificationContent,
    setInformateRegionContent,
    setJustificationAparat,
    setJustificationEruption,
    setInformateRegionEruption,
    setJustificationIntermaxilary,
    setJustificationCollage,
    setJustificationRebonding,
    setJustificationBite,
    setJustificationRelease,
    setInformateRegion,
    currentProcedureIndex,
    setCurrentProcedureIndex,

    odontogramaComplementReturn,
    setOdontogramaComplementReturn,
    odontogramaCollageReturn,
    setOdontogramaCollageReturn,
    odontogramaCimentReturn,
    setOdontogramaCimentReturn,

    odontogramaRemote,
    setOdontogramaRemote,
    odontogramaInstall,
    setOdontogramaInstall,
    odontogramaButton,
    setOdontogramaButton,
    odontogramaIntermax,
    setOdontogramaIntermax,
    odontogramaLevant,
    setOdontogramaLevant,

    odontogramaPlanningAparatReturn,
    setOdontogramaPlanningAparatReturn,
    braceTypeMaintenance,
    setBraceTypeMaintenance,
    repOrRecProcedure,
    setRepOrRecProcedure,
    repOrRecBraceProcedure,
    setRepOrRecBraceProcedure,
    repProcedure,
    setRepProcedure,
    painPrescriptionNeededMaintenance,
    setPainPrescriptionNeededMaintenance,
    informedBasicCareMaintenance,
    setInformedBasicCareMaintenance,
    setMaterialProcedureMaintenance,
    setNumberProcedureMaintenance,
    setOthersProceduresFixedMaintenance,
    basicCareObservationsMaintenance,
    setBasicCareObservationsMaintenance,

    showOtherProcedureScreen,
    setShowOtherProcedureScreen,

    valueReferralsProcedures,
    setValueReferralsProcedures,

    setReceiveHigh,
    receiveHigh,

    materialReturnInf,
    setMaterialReturnInf,
    setNumberReturnInf,
    numberReturnInf,
    setOthersReturnInf,
    othersReturnInf,
    setMaterialChangeReturnInf,
    materialChangeReturnInf,
    setNumberChangeReturnInf,
    numberChangeReturnInf,
    setOthersChangeReturnInf,
    othersChangeReturnInf,

    setAllObservationsProcedure,
    allObservationsProcedure,

    needsBandBottom,
    setNeedsBandBottom,
    needsSeparatorBottom,
    setNeedsSeparatorBottom,
    performMoldingBottom,
    setPerformMoldingBottom,
    installSeparatorBottom,
    setInstallSeparatorBottom,

    setMoldingJustificationBottom,

    setSeparatorJustificationBottom,
    odontogramaSeparatorBottom,
    setOdontogramaSeparatorBottom,

    needsBandTop,
    setNeedsBandTop,
    needsSeparatorTop,
    setNeedsSeparatorTop,
    performMoldingTop,
    setPerformMoldingTop,
    installSeparatorTop,
    setInstallSeparatorTop,

    setMoldingJustificationTop,

    setSeparatorJustificationTop,
    odontogramaSeparatorTop,
    setOdontogramaSeparatorTop,

    setOtherProceduresValueSecondConsult,
    otherProceduresValueSecondConsult,

    attachment,
    setAttachment,
    changeAttachment,
    setChangeAttachment,
    odontogramaAttachment,
    setOdontogramaAttachment,

    realizeIPR,
    setRealizeIPR,

    odontogramaIPR,
    setOdontogramaIPR,
    orientInvisible,
    setOrientInvisible,

    motiveNoOrient,
    setMotiveNoOrient,
    orientPeriod,
    setOrientPeriod,
    motivePeriod,
    setMotivePeriod,
    daysPeriod,
    setDaysPeriod,
    plates,
    setPlates,
    elasticRequired,
    setElasticRequired,
  } = useFirstConsult();
  const {
    complementary,
    proceduresData,
    referralsData,
    proceduresDataWithFlow,
  } = useAuth();

  const [modalOdontoAttachment, setModalOdontoAttachment] =
    React.useState<boolean>(false);

  const [modalIPR, setModalIPR] = React.useState<boolean>(false);

  const [modalSeparatorBottom, setModalSeparatorBottom] =
    React.useState<boolean>(false);

  const [modalSeparatorTop, setModalSeparatorTop] =
    React.useState<boolean>(false);

  const [modalComplement, setModalComplement] = React.useState<boolean>(false);

  const [modalRemote, setModalRemote] = React.useState<boolean>(false);
  const [modalInstall, setModalInstall] = React.useState<boolean>(false);
  const [modalButton, setModalButton] = React.useState<boolean>(false);
  const [modalIntermax, setModalIntermax] = React.useState<boolean>(false);
  const [modalLevant, setModalLevant] = React.useState<boolean>(false);

  const [modalCiment, setModalCiment] = React.useState<boolean>(false);
  const [modalCollage, setModalCollage] = React.useState<boolean>(false);
  const [modalPlanningAparat, setModalPlanningAparat] =
    React.useState<boolean>(false);

  const [verifyChange01, setVerifyChange01] = React.useState<boolean>(false);
  const [verifyChange02, setVerifyChange02] = React.useState<boolean>(false);
  const [verifyChange03, setVerifyChange03] = React.useState<boolean>(false);
  const [verifyChange04, setVerifyChange04] = React.useState<boolean>(false);
  const [verifyChange05, setVerifyChange05] = React.useState<boolean>(false);
  const [verifyChange07, setVerifyChange07] = React.useState<boolean>(false);
  const [verifyChange08, setVerifyChange08] = React.useState<boolean>(false);
  const [verifyChange09, setVerifyChange09] = React.useState<boolean>(false);

  const descriptionsWithFlowZero = proceduresData
    .filter((procedure) => procedure.flow === 0)
    .map((procedure) => procedure.label);

  const combinedProcedures = [
    ...otherProceduresValue,
    ...otherProceduresValueSecondConsult,
  ];

  const newArrayValue: string[] = combinedProcedures
    .filter(
      (procedure: string) => !descriptionsWithFlowZero.includes(procedure)
    )
    .reduce<string[]>((uniqueArray, procedure) => {
      if (!uniqueArray.includes(procedure)) {
        uniqueArray.push(procedure);
      }
      return uniqueArray;
    }, []);

  const handleContinue = () => {
    if (
      newArrayValue.length > 0 &&
      newArrayValue[0].length > 1 &&
      patientObj.proc_name !== "Emergência"
    ) {
      if (showOtherProcedureScreen) {
        if (currentProcedureIndex < newArrayValue.length - 1) {
          if (newArrayValue[currentProcedureIndex] === "Mini Implante") {
            if (realize === "NOT") {
              toast.error("Responda a pergunta e tente novamente");
              return false;
            }
          }

          if (
            newArrayValue[currentProcedureIndex] === "Controle de Contenção"
          ) {
            if (realizeContent === "NOT") {
              toast.error("Responda a pergunta e tente novamente");
              return false;
            }
          }

          if (newArrayValue[currentProcedureIndex] === "Remoção de Aparelho") {
            if (realizeAparat === "NOT") {
              toast.error("Responda a pergunta e tente novamente");
              return false;
            }
          }

          if (newArrayValue[currentProcedureIndex] === "Controle de Erupção") {
            if (realizeEruption === "NOT") {
              toast.error("Responda a pergunta e tente novamente");
              return false;
            }
          }

          if (
            newArrayValue[currentProcedureIndex] ===
            "Instalacao de elasticos intermaxilares"
          ) {
            if (realizeIntermaxilary === "NOT") {
              toast.error("Responda a pergunta e tente novamente");
              return false;
            }
          }

          if (newArrayValue[currentProcedureIndex] === "Colagem de Botão") {
            if (realizeCollage === "NOT") {
              toast.error("Responda a pergunta e tente novamente");
              return false;
            }
          }

          if (newArrayValue[currentProcedureIndex] === "Recolagem Contenção") {
            if (realizeRebonding === "NOT") {
              toast.error("Responda a pergunta e tente novamente");
              return false;
            }
          }

          if (newArrayValue[currentProcedureIndex] === "Levante de Mordida") {
            if (realizeBite === "NOT") {
              toast.error("Responda a pergunta e tente novamente");
              return false;
            }
          }

          if (newArrayValue[currentProcedureIndex] === "Alta") {
            if (realizeRelease === "NOT") {
              toast.error("Responda a pergunta e tente novamente");
              return false;
            }
          }

          if (receiveHigh) {
            setStep(6);
          }

          setCurrentProcedureIndex(currentProcedureIndex + 1);
        } else {
          if (
            patientObj.proc_name === "Emergência" ||
            patientObj.future.scheduling === true
          ) {
            setStep(6);
          } else {
            setStep(4);
          }
          /*if (patientObj.aname_id > 0) {
                if (
                  braceType === "NOT" &&
                  keepThreadProcedures === false &&
                  wireProcedures === false &&
                  glueProcedures === false &&
                  cimentProcedures === false &&
                  painPrescriptionNeeded === "NOT" &&
                  informedBasicCare === "NOT" &&
                  advisedWireAndWax === "NOT" &&
                  rep === false &&
                  hasComplement === false &&
                  hasOtherProcedures === false &&
                  hasOtherReferrals === false
                ) {
                  toast.error("Selecione algum procedimento para continuar");
                  return false;
                }
                setStep(4);
              }*/
        }
      } else {
        setCurrentProcedureIndex(0);
        setShowOtherProcedureScreen(true);
      }
    } else {
      if (
        patientObj.proc_name === "Emergência" ||
        patientObj.future.scheduling === true
      ) {
        setStep(6);
      } else {
        setStep(4);
      }
    }
  };

  const next = () => {
    if (verifyChange01 && justify01.length < 1) {
      toast.error("Preencha o campo justificativa");
      return false;
    }

    if (verifyChange02 && justify02.length < 1) {
      toast.error("Preencha o campo justificativa");
      return false;
    }

    if (verifyChange03 && justify03.length < 1) {
      toast.error("Preencha o campo justificativa");
      return false;
    }

    if (verifyChange04 && justify04.length < 1) {
      toast.error("Preencha o campo justificativa");
      return false;
    }

    if (verifyChange05 && justify05.length < 1) {
      toast.error("Preencha o campo justificativa");
      return false;
    }

    if (verifyChange07 && justify07.length < 1) {
      toast.error("Preencha o campo justificativa");
      return false;
    }

    if (verifyChange08 && justify08.length < 1) {
      toast.error("Preencha o campo justificativa");
      return false;
    }

    if (verifyChange09 && justify09.length < 1) {
      toast.error("Preencha o campo justificativa");
      return false;
    }

    if (attachment && changeAttachment.length < 0) {
      toast.error("Preencha se é superior ou inferior");
      return false;
    }

    if (realizeIPR === "NOT") {
      toast.error("Responda se foi realizado o IPR");
      return false;
    }

    if (orientInvisible === "NOT") {
      toast.error(
        "Responda se orientou o paciente sobre o uso do aparelho invisível"
      );
      return false;
    }

    if (orientPeriod === "NOT") {
      toast.error("Responda se orientou o paciente sobre o período de troca");
      return false;
    }

    if (orientPeriod === "YES" && daysPeriod === 0) {
      toast.error("Selecione os dias do período de troca");
      return false;
    }

    if (orientPeriod === "NO" && motivePeriod.length < 1) {
      toast.error("Responda o motivo de não orientar");
      return false;
    }

    if (elasticRequired === "NOT" && patientObj.aname_id > 0) {
      toast.error("Responda se é necessário elasticos");
      return false;
    }

    handleContinue();
  };

  const handleReturn = () => {
    if (currentProcedureIndex > 0) {
      setCurrentProcedureIndex(currentProcedureIndex - 1);
    } else {
      setShowOtherProcedureScreen(false);
    }
  };

  const handleNeedsBandClickBottom = (value: "NO" | "YES") => {
    setNeedsBandBottom(value);
    setNeedsSeparatorBottom("NOT");
    setPerformMoldingBottom("NOT");
    setInstallSeparatorBottom("NOT");
    setMoldingJustificationBottom("");
    setSeparatorJustificationBottom("");
  };

  const handleNeedsSeparatorClickBottom = (value: "NO" | "YES") => {
    setNeedsSeparatorBottom(value);
    setPerformMoldingBottom("NOT");
    setInstallSeparatorBottom("NOT");
    setMoldingJustificationBottom("");
    setSeparatorJustificationBottom("");
  };

  const handlePerformMoldingClickBottom = (value: "NO" | "YES") => {
    setPerformMoldingBottom(value);
    if (value === "YES") setMoldingJustificationBottom("");
  };

  const handleInstallSeparatorClickBottom = (value: "NO" | "YES") => {
    setInstallSeparatorBottom(value);
    if (value === "YES") setSeparatorJustificationBottom("");
  };

  const shouldShowBtnFixedBottom = () => {
    return performMoldingBottom !== "NOT" || installSeparatorBottom !== "NOT";
  };

  const handleNeedsBandClickTop = (value: "NO" | "YES") => {
    setNeedsBandTop(value);
    setNeedsSeparatorTop("NOT");
    setPerformMoldingTop("NOT");
    setInstallSeparatorTop("NOT");
    setMoldingJustificationTop("");
    setSeparatorJustificationTop("");
  };

  const handleNeedsSeparatorClickTop = (value: "NO" | "YES") => {
    setNeedsSeparatorTop(value);
    setPerformMoldingTop("NOT");
    setInstallSeparatorTop("NOT");
    setMoldingJustificationTop("");
    setSeparatorJustificationTop("");
  };

  const handlePerformMoldingClickTop = (value: "NO" | "YES") => {
    setPerformMoldingTop(value);
    if (value === "YES") setMoldingJustificationTop("");
  };

  const handleInstallSeparatorClickTop = (value: "NO" | "YES") => {
    setInstallSeparatorTop(value);
    if (value === "YES") setSeparatorJustificationTop("");
  };

  const shouldShowBtnFixedTop = () => {
    return performMoldingTop !== "NOT" || installSeparatorTop !== "NOT";
  };

  return (
    <Styled.Box>
      <>
        {showOtherProcedureScreen ? (
          <>
            <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
              {newArrayValue[currentProcedureIndex]}
            </Typography>

            {newArrayValue[currentProcedureIndex] === "Mini Implante" && (
              <Styled.BoxDescription>
                <Typography variant="h5">
                  Foi Realizado o Mini Implante ortodôntico?
                </Typography>
                <Styled.BoxButtons>
                  <Button
                    variant={realize === "NO" ? "contained" : "outlined"}
                    onClick={() => setRealize("NO")}
                  >
                    NÃO
                  </Button>
                  <Button
                    variant={realize === "YES" ? "contained" : "outlined"}
                    onClick={() => setRealize("YES")}
                  >
                    SIM
                  </Button>
                </Styled.BoxButtons>
                {realize === "NO" && (
                  <TextField
                    label="Justificativa"
                    fullWidth
                    margin="normal"
                    onChange={(e) => setJustification(e.target.value)}
                  />
                )}

                {realize === "YES" && (
                  <TextField
                    label="Informe a região onde foi realizado o procedimento"
                    fullWidth
                    margin="normal"
                    onChange={(e) => setInformateRegion(e.target.value)}
                  />
                )}
                {realize !== "NOT" && (
                  <>
                    <Autocomplete
                      options={proceduresDataWithFlow.map(
                        (option) => option.label
                      )}
                      multiple
                      sx={{ margin: "10px 0" }}
                      onChange={(event, newValue) => {
                        const uniqueValues = newValue.filter(
                          (value) => !otherProceduresValue.includes(value)
                        );

                        // Atualiza o estado com os valores únicos
                        setOtherProceduresValue((prevValue: any) => [
                          ...prevValue,
                          ...uniqueValues,
                        ]);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Outros procedimentos" />
                      )}
                    />

                    <Autocomplete
                      options={referralsData}
                      multiple
                      sx={{ margin: "10px 0" }}
                      onChange={(event, newValue) => {
                        const uniqueValues = newValue.filter(
                          (value) => !valueReferralsProcedures.includes(value)
                        );

                        // Atualiza o estado com os valores únicos
                        setValueReferralsProcedures((prevValue: any) => [
                          ...prevValue,
                          ...uniqueValues,
                        ]);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Encaminhamentos" />
                      )}
                    />
                  </>
                )}
              </Styled.BoxDescription>
            )}

            {newArrayValue[currentProcedureIndex] ===
              "Controle de Contenção" && (
              <Styled.BoxDescription>
                <Typography variant="h5">
                  Foi Realizado Controle de Contenção?
                </Typography>
                <Styled.BoxButtons>
                  <Button
                    variant={realizeContent === "NO" ? "contained" : "outlined"}
                    onClick={() => setRealizeContent("NO")}
                  >
                    NÃO
                  </Button>
                  <Button
                    variant={
                      realizeContent === "YES" ? "contained" : "outlined"
                    }
                    onClick={() => setRealizeContent("YES")}
                  >
                    SIM
                  </Button>
                </Styled.BoxButtons>
                {realizeContent === "NO" && (
                  <TextField
                    label="Justificativa"
                    fullWidth
                    margin="normal"
                    onChange={(e) => setJustificationContent(e.target.value)}
                  />
                )}
                {realizeContent === "YES" && (
                  <TextField
                    label="Faça o registro do caso"
                    fullWidth
                    margin="normal"
                    onChange={(e) => setInformateRegionContent(e.target.value)}
                  />
                )}

                {realizeContent !== "NOT" && (
                  <>
                    <Autocomplete
                      options={proceduresDataWithFlow.map(
                        (option) => option.label
                      )}
                      multiple
                      sx={{ margin: "10px 0" }}
                      onChange={(event, newValue) => {
                        const uniqueValues = newValue.filter(
                          (value) => !otherProceduresValue.includes(value)
                        );

                        // Atualiza o estado com os valores únicos
                        setOtherProceduresValue((prevValue: any) => [
                          ...prevValue,
                          ...uniqueValues,
                        ]);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Outros procedimentos" />
                      )}
                    />
                    <Autocomplete
                      options={referralsData}
                      multiple
                      sx={{ margin: "10px 0" }}
                      onChange={(event, newValue) => {
                        const uniqueValues = newValue.filter(
                          (value) => !valueReferralsProcedures.includes(value)
                        );

                        // Atualiza o estado com os valores únicos
                        setValueReferralsProcedures((prevValue: any) => [
                          ...prevValue,
                          ...uniqueValues,
                        ]);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Encaminhamentos" />
                      )}
                    />
                  </>
                )}
              </Styled.BoxDescription>
            )}

            {newArrayValue[currentProcedureIndex] === "Remoção de Aparelho" && (
              <Styled.BoxDescription>
                <Typography variant="h5">
                  Foi Realizada a Remoção da Aparatologia Ortodôntica?
                </Typography>
                <Styled.BoxButtons>
                  <Button
                    variant={realizeAparat === "NO" ? "contained" : "outlined"}
                    onClick={() => setRealizeAparat("NO")}
                  >
                    NÃO
                  </Button>
                  <Button
                    variant={realizeAparat === "YES" ? "contained" : "outlined"}
                    onClick={() => setRealizeAparat("YES")}
                  >
                    SIM
                  </Button>
                </Styled.BoxButtons>
                {realizeAparat === "NO" && (
                  <TextField
                    label="Justificativa"
                    fullWidth
                    margin="normal"
                    onChange={(e) => setJustificationAparat(e.target.value)}
                  />
                )}

                {realizeAparat === "YES" && (
                  <>
                    <Button
                      sx={{ margin: "20px 0" }}
                      onClick={() => setModalRemote(true)}
                    >
                      Abrir Odontograma
                    </Button>

                    <Modal
                      open={modalRemote}
                      onClose={() => setModalRemote(false)}
                    >
                      <Odontograma
                        onClose={() => setModalRemote(false)}
                        selected={odontogramaRemote}
                        setSelected={setOdontogramaRemote}
                        subtitle="Remoção de Aparatologia Ortodôntica"
                      />
                    </Modal>
                  </>
                )}

                {realizeAparat !== "NOT" && (
                  <>
                    <Autocomplete
                      options={proceduresDataWithFlow.map(
                        (option) => option.label
                      )}
                      multiple
                      sx={{ margin: "10px 0" }}
                      onChange={(event, newValue) => {
                        const uniqueValues = newValue.filter(
                          (value) => !otherProceduresValue.includes(value)
                        );

                        // Atualiza o estado com os valores únicos
                        setOtherProceduresValue((prevValue: any) => [
                          ...prevValue,
                          ...uniqueValues,
                        ]);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Outros procedimentos" />
                      )}
                    />
                    <Autocomplete
                      options={referralsData}
                      multiple
                      sx={{ margin: "10px 0" }}
                      onChange={(event, newValue) => {
                        const uniqueValues = newValue.filter(
                          (value) => !valueReferralsProcedures.includes(value)
                        );

                        // Atualiza o estado com os valores únicos
                        setValueReferralsProcedures((prevValue: any) => [
                          ...prevValue,
                          ...uniqueValues,
                        ]);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Encaminhamentos" />
                      )}
                    />
                  </>
                )}
              </Styled.BoxDescription>
            )}

            {newArrayValue[currentProcedureIndex] === "Controle de Erupção" && (
              <Styled.BoxDescription>
                <Typography variant="h5">
                  Foi Realizado Controle de Erupção?
                </Typography>
                <Styled.BoxButtons>
                  <Button
                    variant={
                      realizeEruption === "NO" ? "contained" : "outlined"
                    }
                    onClick={() => setRealizeEruption("NO")}
                  >
                    NÃO
                  </Button>
                  <Button
                    variant={
                      realizeEruption === "YES" ? "contained" : "outlined"
                    }
                    onClick={() => setRealizeEruption("YES")}
                  >
                    SIM
                  </Button>
                </Styled.BoxButtons>
                {realizeEruption === "NO" && (
                  <TextField
                    label="Justificativa"
                    fullWidth
                    margin="normal"
                    onChange={(e) => setJustificationEruption(e.target.value)}
                  />
                )}
                {realizeEruption === "YES" && (
                  <TextField
                    label="Faça o registro do caso"
                    fullWidth
                    margin="normal"
                    onChange={(e) => setInformateRegionEruption(e.target.value)}
                  />
                )}

                {realizeEruption !== "NOT" && (
                  <>
                    <Autocomplete
                      options={proceduresDataWithFlow.map(
                        (option) => option.label
                      )}
                      multiple
                      sx={{ margin: "10px 0" }}
                      onChange={(event, newValue) => {
                        const uniqueValues = newValue.filter(
                          (value) => !otherProceduresValue.includes(value)
                        );

                        // Atualiza o estado com os valores únicos
                        setOtherProceduresValue((prevValue: any) => [
                          ...prevValue,
                          ...uniqueValues,
                        ]);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Outros procedimentos" />
                      )}
                    />
                    <Autocomplete
                      options={referralsData}
                      multiple
                      sx={{ margin: "10px 0" }}
                      onChange={(event, newValue) => {
                        const uniqueValues = newValue.filter(
                          (value) => !valueReferralsProcedures.includes(value)
                        );

                        // Atualiza o estado com os valores únicos
                        setValueReferralsProcedures((prevValue: any) => [
                          ...prevValue,
                          ...uniqueValues,
                        ]);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Encaminhamentos" />
                      )}
                    />
                  </>
                )}
              </Styled.BoxDescription>
            )}

            {newArrayValue[currentProcedureIndex] ===
              "Instalacao de elasticos intermaxilares" && (
              <Styled.BoxDescription>
                <Typography variant="h5">Foi Colocado Elástico?</Typography>
                <Styled.BoxButtons>
                  <Button
                    variant={
                      realizeIntermaxilary === "NO" ? "contained" : "outlined"
                    }
                    onClick={() => setRealizeIntermaxilary("NO")}
                  >
                    NÃO
                  </Button>
                  <Button
                    variant={
                      realizeIntermaxilary === "YES" ? "contained" : "outlined"
                    }
                    onClick={() => setRealizeIntermaxilary("YES")}
                  >
                    SIM
                  </Button>
                </Styled.BoxButtons>
                {realizeIntermaxilary === "NO" && (
                  <TextField
                    label="Justificativa"
                    fullWidth
                    margin="normal"
                    onChange={(e) =>
                      setJustificationIntermaxilary(e.target.value)
                    }
                  />
                )}

                {realizeIntermaxilary === "YES" && (
                  <>
                    <Button
                      sx={{ margin: "20px 0" }}
                      onClick={() => setModalInstall(true)}
                    >
                      Abrir Odontograma
                    </Button>

                    <Modal
                      open={modalInstall}
                      onClose={() => setModalInstall(false)}
                    >
                      <Odontograma
                        onClose={() => setModalInstall(false)}
                        selected={odontogramaInstall}
                        setSelected={setOdontogramaInstall}
                        subtitle="Instalação de elásticos intermaxilares"
                      />
                    </Modal>
                  </>
                )}

                {realizeIntermaxilary !== "NOT" && (
                  <>
                    <Autocomplete
                      options={proceduresDataWithFlow.map(
                        (option) => option.label
                      )}
                      multiple
                      sx={{ margin: "10px 0" }}
                      onChange={(event, newValue) => {
                        const uniqueValues = newValue.filter(
                          (value) => !otherProceduresValue.includes(value)
                        );

                        // Atualiza o estado com os valores únicos
                        setOtherProceduresValue((prevValue: any) => [
                          ...prevValue,
                          ...uniqueValues,
                        ]);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Outros procedimentos" />
                      )}
                    />

                    <Autocomplete
                      options={referralsData}
                      multiple
                      sx={{ margin: "10px 0" }}
                      onChange={(event, newValue) => {
                        const uniqueValues = newValue.filter(
                          (value) => !valueReferralsProcedures.includes(value)
                        );

                        // Atualiza o estado com os valores únicos
                        setValueReferralsProcedures((prevValue: any) => [
                          ...prevValue,
                          ...uniqueValues,
                        ]);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Encaminhamentos" />
                      )}
                    />
                  </>
                )}
              </Styled.BoxDescription>
            )}

            {newArrayValue[currentProcedureIndex] === "Colagem de Botão" && (
              <Styled.BoxDescription>
                <Typography variant="h5">Colagem de botão?</Typography>
                <Styled.BoxButtons>
                  <Button
                    variant={realizeCollage === "NO" ? "contained" : "outlined"}
                    onClick={() => setRealizeCollage("NO")}
                  >
                    NÃO
                  </Button>
                  <Button
                    variant={
                      realizeCollage === "YES" ? "contained" : "outlined"
                    }
                    onClick={() => setRealizeCollage("YES")}
                  >
                    SIM
                  </Button>
                </Styled.BoxButtons>
                {realizeCollage === "NO" && (
                  <TextField
                    label="Justificativa"
                    fullWidth
                    margin="normal"
                    onChange={(e) => setJustificationCollage(e.target.value)}
                  />
                )}

                {realizeCollage === "YES" && (
                  <>
                    <Button
                      sx={{ margin: "20px 0" }}
                      onClick={() => setModalButton(true)}
                    >
                      Abrir Odontograma
                    </Button>

                    <Modal
                      open={modalButton}
                      onClose={() => setModalButton(false)}
                    >
                      <Odontograma
                        onClose={() => setModalButton(false)}
                        selected={odontogramaButton}
                        setSelected={setOdontogramaButton}
                        subtitle="Colagem de Botão"
                      />
                    </Modal>
                  </>
                )}

                {realizeCollage !== "NOT" && (
                  <>
                    <Autocomplete
                      options={proceduresDataWithFlow.map(
                        (option) => option.label
                      )}
                      multiple
                      sx={{ margin: "10px 0" }}
                      onChange={(event, newValue) => {
                        const uniqueValues = newValue.filter(
                          (value) => !otherProceduresValue.includes(value)
                        );

                        // Atualiza o estado com os valores únicos
                        setOtherProceduresValue((prevValue: any) => [
                          ...prevValue,
                          ...uniqueValues,
                        ]);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Outros procedimentos" />
                      )}
                    />

                    <Autocomplete
                      options={referralsData}
                      multiple
                      sx={{ margin: "10px 0" }}
                      onChange={(event, newValue) => {
                        const uniqueValues = newValue.filter(
                          (value) => !valueReferralsProcedures.includes(value)
                        );

                        // Atualiza o estado com os valores únicos
                        setValueReferralsProcedures((prevValue: any) => [
                          ...prevValue,
                          ...uniqueValues,
                        ]);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Encaminhamentos" />
                      )}
                    />
                  </>
                )}
              </Styled.BoxDescription>
            )}

            {newArrayValue[currentProcedureIndex] === "Recolagem Contenção" && (
              <Styled.BoxDescription>
                <Typography variant="h5">
                  Foi realizada a recolagem de contenção?
                </Typography>
                <Styled.BoxButtons>
                  <Button
                    variant={
                      realizeRebonding === "NO" ? "contained" : "outlined"
                    }
                    onClick={() => setRealizeRebonding("NO")}
                  >
                    NÃO
                  </Button>
                  <Button
                    variant={
                      realizeRebonding === "YES" ? "contained" : "outlined"
                    }
                    onClick={() => setRealizeRebonding("YES")}
                  >
                    SIM
                  </Button>
                </Styled.BoxButtons>
                {realizeRebonding === "NO" && (
                  <TextField
                    label="Justificativa"
                    fullWidth
                    margin="normal"
                    onChange={(e) => setJustificationRebonding(e.target.value)}
                  />
                )}

                {realizeRebonding === "YES" && (
                  <>
                    <Button
                      sx={{ margin: "20px 0" }}
                      onClick={() => setModalIntermax(true)}
                    >
                      Abrir Odontograma
                    </Button>

                    <Modal
                      open={modalIntermax}
                      onClose={() => setModalIntermax(false)}
                    >
                      <Odontograma
                        onClose={() => setModalIntermax(false)}
                        selected={odontogramaIntermax}
                        setSelected={setOdontogramaIntermax}
                        subtitle="Recolagem de Contenção"
                      />
                    </Modal>
                  </>
                )}

                {realizeRebonding !== "NOT" && (
                  <>
                    <Autocomplete
                      options={proceduresDataWithFlow.map(
                        (option) => option.label
                      )}
                      multiple
                      sx={{ margin: "10px 0" }}
                      onChange={(event, newValue) => {
                        const uniqueValues = newValue.filter(
                          (value) => !otherProceduresValue.includes(value)
                        );

                        // Atualiza o estado com os valores únicos
                        setOtherProceduresValue((prevValue: any) => [
                          ...prevValue,
                          ...uniqueValues,
                        ]);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Outros procedimentos" />
                      )}
                    />

                    <Autocomplete
                      options={referralsData}
                      multiple
                      sx={{ margin: "10px 0" }}
                      onChange={(event, newValue) => {
                        const uniqueValues = newValue.filter(
                          (value) => !valueReferralsProcedures.includes(value)
                        );

                        // Atualiza o estado com os valores únicos
                        setValueReferralsProcedures((prevValue: any) => [
                          ...prevValue,
                          ...uniqueValues,
                        ]);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Encaminhamentos" />
                      )}
                    />
                  </>
                )}
              </Styled.BoxDescription>
            )}

            {newArrayValue[currentProcedureIndex] === "Levante de Mordida" && (
              <Styled.BoxDescription>
                <Typography variant="h5">
                  Foi realizado levante de mordida?
                </Typography>
                <Styled.BoxButtons>
                  <Button
                    variant={realizeBite === "NO" ? "contained" : "outlined"}
                    onClick={() => setRealizeBite("NO")}
                  >
                    NÃO
                  </Button>
                  <Button
                    variant={realizeBite === "YES" ? "contained" : "outlined"}
                    onClick={() => setRealizeBite("YES")}
                  >
                    SIM
                  </Button>
                </Styled.BoxButtons>
                {realizeBite === "NO" && (
                  <TextField
                    label="Justificativa"
                    fullWidth
                    margin="normal"
                    onChange={(e) => setJustificationBite(e.target.value)}
                  />
                )}

                {realizeBite === "YES" && (
                  <>
                    <Button
                      sx={{ margin: "20px 0" }}
                      onClick={() => setModalLevant(true)}
                    >
                      Abrir Odontograma
                    </Button>

                    <Modal
                      open={modalLevant}
                      onClose={() => setModalLevant(false)}
                    >
                      <Odontograma
                        onClose={() => setModalLevant(false)}
                        selected={odontogramaLevant}
                        setSelected={setOdontogramaLevant}
                        subtitle="Levante de Mordida"
                      />
                    </Modal>
                  </>
                )}

                {realizeBite !== "NOT" && (
                  <>
                    <Autocomplete
                      options={proceduresDataWithFlow.map(
                        (option) => option.label
                      )}
                      multiple
                      sx={{ margin: "10px 0" }}
                      onChange={(event, newValue) => {
                        const uniqueValues = newValue.filter(
                          (value) => !otherProceduresValue.includes(value)
                        );

                        // Atualiza o estado com os valores únicos
                        setOtherProceduresValue((prevValue: any) => [
                          ...prevValue,
                          ...uniqueValues,
                        ]);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Outros procedimentos" />
                      )}
                    />

                    <Autocomplete
                      options={referralsData}
                      multiple
                      sx={{ margin: "10px 0" }}
                      onChange={(event, newValue) => {
                        const uniqueValues = newValue.filter(
                          (value) => !valueReferralsProcedures.includes(value)
                        );

                        // Atualiza o estado com os valores únicos
                        setValueReferralsProcedures((prevValue: any) => [
                          ...prevValue,
                          ...uniqueValues,
                        ]);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Encaminhamentos" />
                      )}
                    />
                  </>
                )}
              </Styled.BoxDescription>
            )}

            {newArrayValue[currentProcedureIndex] === "Alta" && (
              <>
                <Styled.BoxDescription>
                  <Typography variant="h5">Paciente recebeu alta?</Typography>
                  <Styled.BoxButtons>
                    <Button
                      variant={
                        realizeRelease === "NO" ? "contained" : "outlined"
                      }
                      onClick={() => {
                        setRealizeRelease("NO");
                        setReceiveHigh(false);
                      }}
                    >
                      NÃO
                    </Button>
                    <Button
                      variant={
                        realizeRelease === "YES" ? "contained" : "outlined"
                      }
                      onClick={() => {
                        setRealizeRelease("YES");
                        setReceiveHigh(true);
                      }}
                    >
                      SIM
                    </Button>
                  </Styled.BoxButtons>
                  {realizeRelease === "NO" && (
                    <TextField
                      label="Justificativa"
                      fullWidth
                      margin="normal"
                      onChange={(e) => setJustificationRelease(e.target.value)}
                    />
                  )}
                </Styled.BoxDescription>

                {realizeRelease === "YES" && (
                  <Styled.BoxDescription>
                    <Typography variant="h5">
                      Paciente foi encaminhado para a documentação final?
                    </Typography>
                    <Styled.BoxButtons>
                      <Button
                        variant={route === "NO" ? "contained" : "outlined"}
                        onClick={() => setRoute("NO")}
                      >
                        NÃO
                      </Button>
                      <Button
                        variant={route === "YES" ? "contained" : "outlined"}
                        onClick={() => setRoute("YES")}
                      >
                        SIM
                      </Button>
                    </Styled.BoxButtons>
                    {route === "NO" && (
                      <TextField
                        label="Justificativa"
                        fullWidth
                        margin="normal"
                        onChange={(e) => setJustifyRoute(e.target.value)}
                      />
                    )}
                  </Styled.BoxDescription>
                )}

                {realizeRelease !== "NOT" && (
                  <>
                    <Autocomplete
                      options={proceduresDataWithFlow.map(
                        (option) => option.label
                      )}
                      multiple
                      sx={{ margin: "10px 0" }}
                      onChange={(event, newValue) => {
                        const uniqueValues = newValue.filter(
                          (value) => !otherProceduresValue.includes(value)
                        );

                        // Atualiza o estado com os valores únicos
                        setOtherProceduresValue((prevValue: any) => [
                          ...prevValue,
                          ...uniqueValues,
                        ]);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Outros procedimentos" />
                      )}
                    />

                    <Autocomplete
                      options={referralsData}
                      multiple
                      sx={{ margin: "10px 0" }}
                      onChange={(event, newValue) => {
                        const uniqueValues = newValue.filter(
                          (value) => !valueReferralsProcedures.includes(value)
                        );

                        // Atualiza o estado com os valores únicos
                        setValueReferralsProcedures((prevValue: any) => [
                          ...prevValue,
                          ...uniqueValues,
                        ]);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Encaminhamentos" />
                      )}
                    />
                  </>
                )}
              </>
            )}

            {newArrayValue[currentProcedureIndex] === "Manutenção" && (
              <>
                <Styled.BoxIndication>
                  <Styled.BoxButtons
                    style={{ marginBottom: "10px", marginTop: "10px" }}
                  >
                    <Button
                      variant={
                        braceTypeMaintenance === "UPPER"
                          ? "contained"
                          : "outlined"
                      }
                      onClick={() => {
                        setBraceTypeMaintenance("UPPER");
                      }}
                    >
                      Trocar fio
                    </Button>
                    <Button
                      variant={
                        braceTypeMaintenance === "LOWER"
                          ? "contained"
                          : "outlined"
                      }
                      onClick={() => {
                        setBraceTypeMaintenance("LOWER");
                      }}
                    >
                      Manter fio
                    </Button>
                  </Styled.BoxButtons>
                  {braceTypeMaintenance !== "NOT" && (
                    <Box display="flex" alignItems="center" mt={2} mb={2}>
                      <FormControl fullWidth>
                        <InputLabel
                          id="material-select-label"
                          sx={{ backgroundColor: "white", padding: "2px" }}
                        >
                          Material
                        </InputLabel>
                        <Select
                          displayEmpty
                          variant="outlined"
                          sx={{ width: "90%" }}
                          style={{ marginRight: 16 }}
                          onChange={(e) =>
                            setMaterialProcedureMaintenance(
                              e.target.value as string
                            )
                          }
                        >
                          {materialOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl fullWidth>
                        <InputLabel
                          id="number-select-label"
                          sx={{ backgroundColor: "white", padding: "2px" }}
                        >
                          N°
                        </InputLabel>
                        <Select
                          displayEmpty
                          variant="outlined"
                          sx={{ width: "90%" }}
                          style={{ marginRight: 16 }}
                          onChange={(e) =>
                            setNumberProcedureMaintenance(
                              e.target.value as string
                            )
                          }
                        >
                          {numbers.map((item, index) => (
                            <MenuItem key={index} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <TextField
                        label="Outros"
                        onChange={(e) =>
                          setOthersProceduresFixedMaintenance(
                            e.target.value as string
                          )
                        }
                      ></TextField>
                    </Box>
                  )}
                </Styled.BoxIndication>

                <Styled.BoxIndication>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={repProcedure}
                          onClick={() => {
                            setRepProcedure(!repProcedure);
                          }}
                        />
                      }
                      label="Reposição ou Recolagem de Braquete"
                    />
                  </FormGroup>
                  {repProcedure && (
                    <>
                      <Styled.BoxButtons>
                        <Button
                          variant={
                            repOrRecProcedure === "REP"
                              ? "contained"
                              : "outlined"
                          }
                          onClick={() => setRepOrRecProcedure("REP")}
                        >
                          Reposição
                        </Button>
                        <Button
                          variant={
                            repOrRecProcedure === "REC"
                              ? "contained"
                              : "outlined"
                          }
                          onClick={() => setRepOrRecProcedure("REC")}
                        >
                          Recolagem
                        </Button>
                      </Styled.BoxButtons>

                      {repOrRecProcedure !== "NOT" && (
                        <Styled.BoxButtons
                          style={{ marginTop: "20px", paddingBottom: "10px" }}
                        >
                          <Button
                            variant={
                              repOrRecBraceProcedure === "SUP"
                                ? "contained"
                                : "outlined"
                            }
                            onClick={() => setRepOrRecBraceProcedure("SUP")}
                          >
                            Superior
                          </Button>
                          <Button
                            variant={
                              repOrRecBraceProcedure === "INF"
                                ? "contained"
                                : "outlined"
                            }
                            onClick={() => setRepOrRecBraceProcedure("INF")}
                          >
                            Inferior
                          </Button>
                        </Styled.BoxButtons>
                      )}
                    </>
                  )}
                </Styled.BoxIndication>

                <Styled.BoxIndication>
                  <Typography
                    variant="h5"
                    sx={{ marginTop: "5px", marginBottom: "10px" }}
                  >
                    Precisa de receita para o caso de dor?
                  </Typography>
                  <Styled.BoxButtons style={{ marginBottom: "10px" }}>
                    <Button
                      variant={
                        painPrescriptionNeededMaintenance === "YES"
                          ? "contained"
                          : "outlined"
                      }
                      onClick={() => {
                        setPainPrescriptionNeededMaintenance("YES");
                      }}
                    >
                      Sim
                    </Button>
                    <Button
                      variant={
                        painPrescriptionNeededMaintenance === "NO"
                          ? "contained"
                          : "outlined"
                      }
                      onClick={() => {
                        setPainPrescriptionNeededMaintenance("NO");
                      }}
                    >
                      Não
                    </Button>
                  </Styled.BoxButtons>
                </Styled.BoxIndication>

                <Styled.BoxIndication>
                  <Typography
                    variant="h5"
                    sx={{ marginTop: "5px", marginBottom: "10px" }}
                  >
                    Informou o paciente sobre os cuidados básicos com o
                    aparelho?
                  </Typography>
                  <Styled.BoxButtons style={{ marginBottom: "10px" }}>
                    <Button
                      variant={
                        informedBasicCareMaintenance === "YES"
                          ? "contained"
                          : "outlined"
                      }
                      onClick={() => {
                        setInformedBasicCareMaintenance("YES");
                      }}
                    >
                      Sim
                    </Button>
                    <Button
                      variant={
                        informedBasicCareMaintenance === "NO"
                          ? "contained"
                          : "outlined"
                      }
                      onClick={() => {
                        setInformedBasicCareMaintenance("NO");
                      }}
                    >
                      Não
                    </Button>
                  </Styled.BoxButtons>
                  {informedBasicCareMaintenance === "NO" && (
                    <TextField
                      fullWidth
                      label="Justifique"
                      variant="outlined"
                      sx={{ marginTop: "10px", paddingBottom: "5px" }}
                      value={basicCareObservationsMaintenance}
                      onChange={(e) => {
                        setBasicCareObservationsMaintenance(e.target.value);
                      }}
                    />
                  )}

                  {braceTypeMaintenance !== "NOT" && (
                    <>
                      <Autocomplete
                        options={proceduresDataWithFlow.map(
                          (option) => option.label
                        )}
                        multiple
                        sx={{ margin: "10px 0" }}
                        onChange={(event, newValue) => {
                          const uniqueValues = newValue.filter(
                            (value) => !otherProceduresValue.includes(value)
                          );

                          // Atualiza o estado com os valores únicos
                          setOtherProceduresValue((prevValue: any) => [
                            ...prevValue,
                            ...uniqueValues,
                          ]);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Outros procedimentos" />
                        )}
                      />

                      <Autocomplete
                        options={referralsData}
                        multiple
                        sx={{ margin: "10px 0" }}
                        onChange={(event, newValue) => {
                          const uniqueValues = newValue.filter(
                            (value) => !valueReferralsProcedures.includes(value)
                          );

                          // Atualiza o estado com os valores únicos
                          setValueReferralsProcedures((prevValue: any) => [
                            ...prevValue,
                            ...uniqueValues,
                          ]);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Encaminhamentos" />
                        )}
                      />
                    </>
                  )}
                </Styled.BoxIndication>
              </>
            )}

            {newArrayValue[currentProcedureIndex] === "Moldagem Inferior" && (
              <>
                <Styled.BoxDescription>
                  <Typography variant="h5">Vai precisar de banda?</Typography>
                  <Styled.BoxButtons>
                    <Button
                      variant={
                        needsBandBottom === "NO" ? "contained" : "outlined"
                      }
                      onClick={() => handleNeedsBandClickBottom("NO")}
                    >
                      NÃO
                    </Button>
                    <Button
                      variant={
                        needsBandBottom === "YES" ? "contained" : "outlined"
                      }
                      onClick={() => handleNeedsBandClickBottom("YES")}
                    >
                      SIM
                    </Button>
                  </Styled.BoxButtons>
                </Styled.BoxDescription>

                {needsBandBottom === "NO" && (
                  <Styled.BoxDescription>
                    <Typography variant="h5">Realizou a moldagem?</Typography>
                    <Styled.BoxButtons>
                      <Button
                        variant={
                          performMoldingBottom === "NO"
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() => handlePerformMoldingClickBottom("NO")}
                      >
                        NÃO
                      </Button>
                      <Button
                        variant={
                          performMoldingBottom === "YES"
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() => handlePerformMoldingClickBottom("YES")}
                      >
                        SIM
                      </Button>
                    </Styled.BoxButtons>
                    {performMoldingBottom === "NO" && (
                      <TextField
                        label="Justifique"
                        onChange={(e) =>
                          setMoldingJustificationBottom(e.target.value)
                        }
                      />
                    )}
                  </Styled.BoxDescription>
                )}

                {needsBandBottom === "YES" && (
                  <Styled.BoxDescription>
                    <Typography variant="h5">
                      Vai precisar de separador?
                    </Typography>
                    <Styled.BoxButtons>
                      <Button
                        variant={
                          needsSeparatorBottom === "NO"
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() => handleNeedsSeparatorClickBottom("NO")}
                      >
                        NÃO
                      </Button>
                      <Button
                        variant={
                          needsSeparatorBottom === "YES"
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() => handleNeedsSeparatorClickBottom("YES")}
                      >
                        SIM
                      </Button>
                    </Styled.BoxButtons>
                  </Styled.BoxDescription>
                )}

                {needsSeparatorBottom === "NO" && needsBandBottom === "YES" && (
                  <>
                    <Button onClick={() => setModalSeparatorBottom(true)}>
                      Abrir Odontograma
                    </Button>

                    <Modal
                      open={modalSeparatorBottom}
                      onClose={() => setModalSeparatorBottom(false)}
                    >
                      <Odontograma
                        onClose={() => setModalSeparatorBottom(false)}
                        selected={odontogramaSeparatorBottom}
                        setSelected={setOdontogramaSeparatorBottom}
                        subtitle="Moldagem Inferior"
                      />
                    </Modal>
                    <Styled.BoxDescription>
                      <Typography variant="h5">Realizou a moldagem?</Typography>
                      <Styled.BoxButtons>
                        <Button
                          variant={
                            performMoldingBottom === "NO"
                              ? "contained"
                              : "outlined"
                          }
                          onClick={() => handlePerformMoldingClickBottom("NO")}
                        >
                          NÃO
                        </Button>
                        <Button
                          variant={
                            performMoldingBottom === "YES"
                              ? "contained"
                              : "outlined"
                          }
                          onClick={() => handlePerformMoldingClickBottom("YES")}
                        >
                          SIM
                        </Button>
                      </Styled.BoxButtons>
                      {performMoldingBottom === "NO" && (
                        <TextField
                          label="Justifique"
                          onChange={(e) =>
                            setMoldingJustificationBottom(e.target.value)
                          }
                        />
                      )}
                    </Styled.BoxDescription>
                  </>
                )}

                {needsSeparatorBottom === "YES" &&
                  needsBandBottom === "YES" && (
                    <>
                      <Button onClick={() => setModalSeparatorBottom(true)}>
                        Abrir Odontograma
                      </Button>

                      <Modal
                        open={modalSeparatorBottom}
                        onClose={() => setModalSeparatorBottom(false)}
                      >
                        <Odontograma
                          onClose={() => setModalSeparatorBottom(false)}
                          selected={odontogramaSeparatorBottom}
                          setSelected={setOdontogramaSeparatorBottom}
                          subtitle="Moldagem Inferior"
                        />
                      </Modal>
                      <Styled.BoxDescription>
                        <Typography variant="h5">
                          Instalou o separador?
                        </Typography>
                        <Styled.BoxButtons>
                          <Button
                            variant={
                              installSeparatorBottom === "NO"
                                ? "contained"
                                : "outlined"
                            }
                            onClick={() =>
                              handleInstallSeparatorClickBottom("NO")
                            }
                          >
                            NÃO
                          </Button>
                          <Button
                            variant={
                              installSeparatorBottom === "YES"
                                ? "contained"
                                : "outlined"
                            }
                            onClick={() =>
                              handleInstallSeparatorClickBottom("YES")
                            }
                          >
                            SIM
                          </Button>
                        </Styled.BoxButtons>

                        {installSeparatorBottom === "NO" && (
                          <TextField
                            label="Justifique"
                            onChange={(e) =>
                              setSeparatorJustificationBottom(e.target.value)
                            }
                          />
                        )}
                      </Styled.BoxDescription>
                    </>
                  )}
              </>
            )}

            {newArrayValue[currentProcedureIndex] === "Moldagem Superior" && (
              <>
                <Styled.BoxDescription>
                  <Typography variant="h5">Vai precisar de banda?</Typography>
                  <Styled.BoxButtons>
                    <Button
                      variant={needsBandTop === "NO" ? "contained" : "outlined"}
                      onClick={() => handleNeedsBandClickTop("NO")}
                    >
                      NÃO
                    </Button>
                    <Button
                      variant={
                        needsBandTop === "YES" ? "contained" : "outlined"
                      }
                      onClick={() => handleNeedsBandClickTop("YES")}
                    >
                      SIM
                    </Button>
                  </Styled.BoxButtons>
                </Styled.BoxDescription>

                {needsBandTop === "NO" && (
                  <Styled.BoxDescription>
                    <Typography variant="h5">Realizou a moldagem?</Typography>
                    <Styled.BoxButtons>
                      <Button
                        variant={
                          performMoldingTop === "NO" ? "contained" : "outlined"
                        }
                        onClick={() => handlePerformMoldingClickTop("NO")}
                      >
                        NÃO
                      </Button>
                      <Button
                        variant={
                          performMoldingTop === "YES" ? "contained" : "outlined"
                        }
                        onClick={() => handlePerformMoldingClickTop("YES")}
                      >
                        SIM
                      </Button>
                    </Styled.BoxButtons>
                    {performMoldingTop === "NO" && (
                      <TextField
                        label="Justifique"
                        onChange={(e) =>
                          setMoldingJustificationTop(e.target.value)
                        }
                      />
                    )}
                  </Styled.BoxDescription>
                )}

                {needsBandTop === "YES" && (
                  <Styled.BoxDescription>
                    <Typography variant="h5">
                      Vai precisar de separador?
                    </Typography>
                    <Styled.BoxButtons>
                      <Button
                        variant={
                          needsSeparatorTop === "NO" ? "contained" : "outlined"
                        }
                        onClick={() => handleNeedsSeparatorClickTop("NO")}
                      >
                        NÃO
                      </Button>
                      <Button
                        variant={
                          needsSeparatorTop === "YES" ? "contained" : "outlined"
                        }
                        onClick={() => handleNeedsSeparatorClickTop("YES")}
                      >
                        SIM
                      </Button>
                    </Styled.BoxButtons>
                  </Styled.BoxDescription>
                )}

                {needsSeparatorTop === "NO" && needsBandTop === "YES" && (
                  <>
                    <Button onClick={() => setModalSeparatorTop(true)}>
                      Abrir Odontograma
                    </Button>

                    <Modal
                      open={modalSeparatorTop}
                      onClose={() => setModalSeparatorTop(false)}
                    >
                      <Odontograma
                        onClose={() => setModalSeparatorTop(false)}
                        selected={odontogramaSeparatorTop}
                        setSelected={setOdontogramaSeparatorTop}
                        subtitle="Moldagem Inferior"
                      />
                    </Modal>
                    <Styled.BoxDescription>
                      <Typography variant="h5">Realizou a moldagem?</Typography>
                      <Styled.BoxButtons>
                        <Button
                          variant={
                            performMoldingTop === "NO"
                              ? "contained"
                              : "outlined"
                          }
                          onClick={() => handlePerformMoldingClickTop("NO")}
                        >
                          NÃO
                        </Button>
                        <Button
                          variant={
                            performMoldingTop === "YES"
                              ? "contained"
                              : "outlined"
                          }
                          onClick={() => handlePerformMoldingClickTop("YES")}
                        >
                          SIM
                        </Button>
                      </Styled.BoxButtons>
                      {performMoldingTop === "NO" && (
                        <TextField
                          label="Justifique"
                          onChange={(e) =>
                            setMoldingJustificationTop(e.target.value)
                          }
                        />
                      )}
                    </Styled.BoxDescription>
                  </>
                )}

                {needsSeparatorTop === "YES" && needsBandTop === "YES" && (
                  <>
                    <Button onClick={() => setModalSeparatorTop(true)}>
                      Abrir Odontograma
                    </Button>

                    <Modal
                      open={modalSeparatorTop}
                      onClose={() => setModalSeparatorTop(false)}
                    >
                      <Odontograma
                        onClose={() => setModalSeparatorTop(false)}
                        selected={odontogramaSeparatorTop}
                        setSelected={setOdontogramaSeparatorTop}
                        subtitle="Moldagem Inferior"
                      />
                    </Modal>
                    <Styled.BoxDescription>
                      <Typography variant="h5">
                        Instalou o separador?
                      </Typography>
                      <Styled.BoxButtons>
                        <Button
                          variant={
                            installSeparatorTop === "NO"
                              ? "contained"
                              : "outlined"
                          }
                          onClick={() => handleInstallSeparatorClickTop("NO")}
                        >
                          NÃO
                        </Button>
                        <Button
                          variant={
                            installSeparatorTop === "YES"
                              ? "contained"
                              : "outlined"
                          }
                          onClick={() => handleInstallSeparatorClickTop("YES")}
                        >
                          SIM
                        </Button>
                      </Styled.BoxButtons>

                      {installSeparatorTop === "NO" && (
                        <TextField
                          label="Justifique"
                          onChange={(e) =>
                            setSeparatorJustificationTop(e.target.value)
                          }
                        />
                      )}
                    </Styled.BoxDescription>
                  </>
                )}
              </>
            )}

            {newArrayValue[currentProcedureIndex] === "Moldagem Inferior" ? (
              <>
                {shouldShowBtnFixedBottom() && (
                  <Styled.BtnFixed>
                    <Button onClick={handleReturn} fullWidth>
                      {currentProcedureIndex === 0 ? "Retornar" : "Anterior"}
                    </Button>
                    <Button onClick={handleContinue} fullWidth>
                      {currentProcedureIndex < otherProceduresValue.length - 1
                        ? "Próximo"
                        : "Continuar"}
                    </Button>
                  </Styled.BtnFixed>
                )}
              </>
            ) : newArrayValue[currentProcedureIndex] === "Moldagem Superior" ? (
              <>
                {shouldShowBtnFixedTop() && (
                  <Styled.BtnFixed>
                    <Button onClick={handleReturn} fullWidth>
                      {currentProcedureIndex === 0 ? "Retornar" : "Anterior"}
                    </Button>
                    <Button onClick={handleContinue} fullWidth>
                      {currentProcedureIndex < otherProceduresValue.length - 1
                        ? "Próximo"
                        : "Continuar"}
                    </Button>
                  </Styled.BtnFixed>
                )}
              </>
            ) : (
              <Styled.BtnFixed>
                <Button onClick={handleReturn} fullWidth>
                  {currentProcedureIndex === 0 ? "Retornar" : "Anterior"}
                </Button>
                <Button onClick={handleContinue} fullWidth>
                  {currentProcedureIndex < newArrayValue.length - 1
                    ? "Próximo"
                    : "Continuar"}
                </Button>
              </Styled.BtnFixed>
            )}
          </>
        ) : (
          <>
            <>
              {questionsPlanning && questionsPlanning.length > 0 ? (
                <>
                  {patientObj.proc_name !== "Emergência" && (
                    <Styled.BoxPlanning>
                      <Typography variant="h5">
                        Planejamento da consulta anterior
                      </Typography>

                      {questionsPlanning.filter((item) => item.id === "PPQ001")
                        .length > 0 ? (
                        <Styled.BoxIndication>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={aparateReturn}
                                  onClick={() => {
                                    setAparateReturn(!aparateReturn);
                                    setAparateBraceReturn([]); // Reseta o estado ao desmarcar
                                    setVerifyChange01(true);
                                  }}
                                />
                              }
                              label="Colagem da Aparatologia Ortodôntica"
                            />
                          </FormGroup>

                          {aparateReturn && (
                            <>
                              <Styled.BoxButtons>
                                <Button
                                  variant={
                                    aparateBraceReturn.includes("SUP")
                                      ? "contained"
                                      : "outlined"
                                  }
                                  onClick={() => {
                                    if (aparateBraceReturn.includes("SUP")) {
                                      setAparateBraceReturn(
                                        aparateBraceReturn.filter(
                                          (type) => type !== "SUP"
                                        )
                                      );
                                    } else {
                                      setAparateBraceReturn([
                                        ...aparateBraceReturn,
                                        "SUP",
                                      ]);
                                    }
                                    setVerifyChange01(true);
                                  }}
                                >
                                  Superior
                                </Button>
                                <Button
                                  variant={
                                    aparateBraceReturn.includes("INF")
                                      ? "contained"
                                      : "outlined"
                                  }
                                  onClick={() => {
                                    if (aparateBraceReturn.includes("INF")) {
                                      setAparateBraceReturn(
                                        aparateBraceReturn.filter(
                                          (type) => type !== "INF"
                                        )
                                      );
                                    } else {
                                      setAparateBraceReturn([
                                        ...aparateBraceReturn,
                                        "INF",
                                      ]);
                                    }
                                    setVerifyChange01(true);
                                  }}
                                >
                                  Inferior
                                </Button>
                              </Styled.BoxButtons>

                              {aparateBraceReturn.length > 0 && (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  flexDirection="column"
                                  mt={2}
                                >
                                  {aparateBraceReturn.includes("SUP") && (
                                    <>
                                      <Typography>Superior</Typography>
                                      <Box
                                        display="flex"
                                        flexDirection="row"
                                        width="100%"
                                      >
                                        <FormControl fullWidth>
                                          <InputLabel
                                            id="demo-simple-select-label"
                                            sx={{
                                              backgroundColor: "white",
                                              padding: "2px",
                                            }}
                                          >
                                            Material
                                          </InputLabel>
                                          <Select
                                            displayEmpty
                                            variant="outlined"
                                            sx={{ width: "90%" }}
                                            value={materialReturn}
                                            style={{ marginRight: 16 }}
                                            onChange={(e) => {
                                              setMaterialReturn(
                                                e.target.value as string
                                              );
                                              setVerifyChange01(
                                                e.target.value ===
                                                  materialReturn
                                                  ? false
                                                  : true
                                              );
                                            }}
                                          >
                                            {materialOptions.map((option) => (
                                              <MenuItem
                                                key={option.value}
                                                value={option.value}
                                              >
                                                {option.label}
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </FormControl>
                                        <FormControl fullWidth>
                                          <InputLabel
                                            id="demo-simple-select-label"
                                            sx={{
                                              backgroundColor: "white",
                                              padding: "2px",
                                            }}
                                          >
                                            N°
                                          </InputLabel>
                                          <Select
                                            displayEmpty
                                            variant="outlined"
                                            sx={{ width: "90%" }}
                                            style={{ marginRight: 16 }}
                                            onChange={(e) => {
                                              setNumberReturn(
                                                e.target.value as string
                                              );
                                              setVerifyChange01(
                                                e.target.value === numberReturn
                                                  ? false
                                                  : true
                                              );
                                            }}
                                            value={numberReturn}
                                          >
                                            {numbers.map((item, index) => (
                                              <MenuItem
                                                key={index}
                                                value={item.value}
                                              >
                                                {item.label}
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </FormControl>

                                        <TextField
                                          label="Outros"
                                          onChange={(e) => {
                                            setOthersReturn(
                                              e.target.value as string
                                            );
                                            setVerifyChange01(true);
                                          }}
                                          value={othersReturn}
                                        />
                                      </Box>
                                    </>
                                  )}
                                  {aparateBraceReturn.includes("INF") && (
                                    <>
                                      <Typography>Inferior</Typography>
                                      <Box
                                        display="flex"
                                        flexDirection="row"
                                        width="100%"
                                      >
                                        <FormControl fullWidth>
                                          <InputLabel
                                            id="demo-simple-select-label"
                                            sx={{
                                              backgroundColor: "white",
                                              padding: "2px",
                                            }}
                                          >
                                            Material
                                          </InputLabel>
                                          <Select
                                            displayEmpty
                                            variant="outlined"
                                            sx={{ width: "90%" }}
                                            value={materialReturnInf}
                                            style={{ marginRight: 16 }}
                                            onChange={(e) => {
                                              setMaterialReturnInf(
                                                e.target.value as string
                                              );
                                              setVerifyChange01(
                                                e.target.value ===
                                                  materialReturn
                                                  ? false
                                                  : true
                                              );
                                            }}
                                          >
                                            {materialOptions.map((option) => (
                                              <MenuItem
                                                key={option.value}
                                                value={option.value}
                                              >
                                                {option.label}
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </FormControl>
                                        <FormControl fullWidth>
                                          <InputLabel
                                            id="demo-simple-select-label"
                                            sx={{
                                              backgroundColor: "white",
                                              padding: "2px",
                                            }}
                                          >
                                            N°
                                          </InputLabel>
                                          <Select
                                            displayEmpty
                                            variant="outlined"
                                            sx={{ width: "90%" }}
                                            style={{ marginRight: 16 }}
                                            onChange={(e) => {
                                              setNumberReturnInf(
                                                e.target.value as string
                                              );
                                              setVerifyChange01(
                                                e.target.value === numberReturn
                                                  ? false
                                                  : true
                                              );
                                            }}
                                            value={numberReturnInf}
                                          >
                                            {numbers.map((item, index) => (
                                              <MenuItem
                                                key={index}
                                                value={item.value}
                                              >
                                                {item.label}
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </FormControl>

                                        <TextField
                                          label="Outros"
                                          onChange={(e) => {
                                            setOthersReturnInf(
                                              e.target.value as string
                                            );
                                            setVerifyChange01(true);
                                          }}
                                          value={othersReturnInf}
                                        />
                                      </Box>
                                    </>
                                  )}

                                  <Button
                                    sx={{ margin: "20px 0" }}
                                    onClick={() => {
                                      setModalPlanningAparat(true);
                                    }}
                                    fullWidth
                                  >
                                    Abrir Odontograma
                                  </Button>

                                  <Modal
                                    open={modalPlanningAparat}
                                    onClose={() =>
                                      setModalPlanningAparat(false)
                                    }
                                  >
                                    <Odontograma
                                      onClose={() =>
                                        setModalPlanningAparat(false)
                                      }
                                      selected={odontogramaPlanningAparatReturn}
                                      setSelected={
                                        setOdontogramaPlanningAparatReturn
                                      }
                                      subtitle="Colagem de aparatologia ortodôntica - Planejamento"
                                    />
                                  </Modal>
                                </Box>
                              )}
                            </>
                          )}

                          {verifyChange01 && (
                            <TextField
                              label="Justifique a mudança"
                              value={justify01}
                              onChange={(e) => setJustify01(e.target.value)}
                              error={verifyChange01 && justify01.length < 1}
                              helperText={
                                verifyChange01 && justify01.length < 1
                                  ? "Justifique sua alteração"
                                  : ""
                              }
                              sx={{ marginTop: "20px" }}
                            />
                          )}
                        </Styled.BoxIndication>
                      ) : (
                        ""
                      )}

                      {questionsPlanning.filter((item) => item.id === "PPQ002")
                        .length > 0 ? (
                        <Styled.BoxIndication>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={wireReturn}
                                  onClick={() => {
                                    setWireReturn(!wireReturn);
                                    setChangeWireReturn([]); // Reseta o estado ao desmarcar
                                    setVerifyChange02(true);
                                  }}
                                />
                              }
                              label="Trocar Fio"
                            />
                          </FormGroup>

                          {wireReturn && (
                            <>
                              <Styled.BoxButtons>
                                <Button
                                  variant={
                                    changeWireReturn.includes("SUP")
                                      ? "contained"
                                      : "outlined"
                                  }
                                  onClick={() => {
                                    if (changeWireReturn.includes("SUP")) {
                                      setChangeWireReturn(
                                        changeWireReturn.filter(
                                          (type) => type !== "SUP"
                                        )
                                      );
                                    } else {
                                      setChangeWireReturn([
                                        ...changeWireReturn,
                                        "SUP",
                                      ]);
                                    }
                                    setVerifyChange02(true);
                                  }}
                                >
                                  Superior
                                </Button>
                                <Button
                                  variant={
                                    changeWireReturn.includes("INF")
                                      ? "contained"
                                      : "outlined"
                                  }
                                  onClick={() => {
                                    if (changeWireReturn.includes("INF")) {
                                      setChangeWireReturn(
                                        changeWireReturn.filter(
                                          (type) => type !== "INF"
                                        )
                                      );
                                    } else {
                                      setChangeWireReturn([
                                        ...changeWireReturn,
                                        "INF",
                                      ]);
                                    }
                                    setVerifyChange02(true);
                                  }}
                                >
                                  Inferior
                                </Button>
                              </Styled.BoxButtons>

                              {changeWireReturn.length > 0 && (
                                <>
                                  {changeWireReturn.includes("SUP") && (
                                    <>
                                      <Typography>Superior</Typography>
                                      <Box
                                        display="flex"
                                        alignItems="center"
                                        mt={2}
                                      >
                                        <FormControl fullWidth>
                                          <InputLabel
                                            id="demo-simple-select-label"
                                            sx={{
                                              backgroundColor: "white",
                                              padding: "2px",
                                            }}
                                          >
                                            Material
                                          </InputLabel>
                                          <Select
                                            displayEmpty
                                            variant="outlined"
                                            sx={{ width: "90%" }}
                                            style={{ marginRight: 16 }}
                                            onChange={(e) => {
                                              setMaterialChangeReturn(
                                                e.target.value as string
                                              );
                                              setVerifyChange02(
                                                e.target.value ===
                                                  materialChangeReturn
                                                  ? false
                                                  : true
                                              );
                                            }}
                                            value={materialChangeReturn}
                                          >
                                            {materialOptions.map((option) => (
                                              <MenuItem
                                                key={option.value}
                                                value={option.value}
                                              >
                                                {option.label}
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </FormControl>
                                        <FormControl fullWidth>
                                          <InputLabel
                                            id="demo-simple-select-label"
                                            sx={{
                                              backgroundColor: "white",
                                              padding: "2px",
                                            }}
                                          >
                                            N°
                                          </InputLabel>
                                          <Select
                                            displayEmpty
                                            variant="outlined"
                                            sx={{ width: "90%" }}
                                            style={{ marginRight: 16 }}
                                            onChange={(e) => {
                                              setNumberChangeReturn(
                                                e.target.value as string
                                              );
                                              setVerifyChange02(
                                                e.target.value ===
                                                  numberChangeReturn
                                                  ? false
                                                  : true
                                              );
                                            }}
                                            value={numberChangeReturn}
                                          >
                                            {numbers.map((item, index) => (
                                              <MenuItem
                                                key={index}
                                                value={item.value}
                                              >
                                                {item.label}
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </FormControl>

                                        <TextField
                                          label="Outros"
                                          onChange={(e) => {
                                            setOthersChangeReturn(
                                              e.target.value as string
                                            );
                                            setVerifyChange02(true);
                                          }}
                                          value={othersChangeReturn}
                                        />
                                      </Box>
                                    </>
                                  )}

                                  {changeWireReturn.includes("INF") && (
                                    <>
                                      <Typography>Inferior</Typography>
                                      <Box
                                        display="flex"
                                        alignItems="center"
                                        mt={2}
                                      >
                                        <FormControl fullWidth>
                                          <InputLabel
                                            id="demo-simple-select-label"
                                            sx={{
                                              backgroundColor: "white",
                                              padding: "2px",
                                            }}
                                          >
                                            Material
                                          </InputLabel>
                                          <Select
                                            displayEmpty
                                            variant="outlined"
                                            sx={{ width: "90%" }}
                                            style={{ marginRight: 16 }}
                                            onChange={(e) => {
                                              setMaterialChangeReturnInf(
                                                e.target.value as string
                                              );
                                              setVerifyChange02(
                                                e.target.value ===
                                                  materialChangeReturn
                                                  ? false
                                                  : true
                                              );
                                            }}
                                            value={materialChangeReturnInf}
                                          >
                                            {materialOptions.map((option) => (
                                              <MenuItem
                                                key={option.value}
                                                value={option.value}
                                              >
                                                {option.label}
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </FormControl>
                                        <FormControl fullWidth>
                                          <InputLabel
                                            id="demo-simple-select-label"
                                            sx={{
                                              backgroundColor: "white",
                                              padding: "2px",
                                            }}
                                          >
                                            N°
                                          </InputLabel>
                                          <Select
                                            displayEmpty
                                            variant="outlined"
                                            sx={{ width: "90%" }}
                                            style={{ marginRight: 16 }}
                                            onChange={(e) => {
                                              setNumberChangeReturnInf(
                                                e.target.value as string
                                              );
                                              setVerifyChange02(
                                                e.target.value ===
                                                  numberChangeReturn
                                                  ? false
                                                  : true
                                              );
                                            }}
                                            value={numberChangeReturnInf}
                                          >
                                            {numbers.map((item, index) => (
                                              <MenuItem
                                                key={index}
                                                value={item.value}
                                              >
                                                {item.label}
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </FormControl>

                                        <TextField
                                          label="Outros"
                                          onChange={(e) => {
                                            setOthersChangeReturnInf(
                                              e.target.value as string
                                            );
                                            setVerifyChange02(true);
                                          }}
                                          value={othersChangeReturnInf}
                                        />
                                      </Box>
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}

                          {verifyChange02 && (
                            <TextField
                              label="Justifique a mudança"
                              value={justify02}
                              onChange={(e) => setJustify02(e.target.value)}
                              sx={{ marginTop: "20px" }}
                              error={verifyChange02 && justify02.length < 1}
                              helperText={
                                verifyChange02 && justify02.length < 1
                                  ? "Justifique sua alteração"
                                  : ""
                              }
                            />
                          )}
                        </Styled.BoxIndication>
                      ) : (
                        ""
                      )}

                      {questionsPlanning.filter((item) => item.id === "PPQ003")
                        .length > 0 ? (
                        <Styled.BoxIndication>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={keepThreadReturn}
                                  onClick={() => {
                                    setKeepThreadReturn(!keepThreadReturn);
                                    setKeepReturn([]); // Reseta o estado ao desmarcar
                                    setVerifyChange03(true);
                                  }}
                                />
                              }
                              label="Manter Fio"
                            />
                          </FormGroup>

                          {keepThreadReturn && (
                            <>
                              <Styled.BoxButtons>
                                <Button
                                  variant={
                                    keepReturn.includes("SUP")
                                      ? "contained"
                                      : "outlined"
                                  }
                                  onClick={() => {
                                    if (keepReturn.includes("SUP")) {
                                      setKeepReturn(
                                        keepReturn.filter(
                                          (type) => type !== "SUP"
                                        )
                                      );
                                    } else {
                                      setKeepReturn([...keepReturn, "SUP"]);
                                    }
                                    setVerifyChange03(true);
                                  }}
                                >
                                  Superior
                                </Button>
                                <Button
                                  variant={
                                    keepReturn.includes("INF")
                                      ? "contained"
                                      : "outlined"
                                  }
                                  onClick={() => {
                                    if (keepReturn.includes("INF")) {
                                      setKeepReturn(
                                        keepReturn.filter(
                                          (type) => type !== "INF"
                                        )
                                      );
                                    } else {
                                      setKeepReturn([...keepReturn, "INF"]);
                                    }
                                    setVerifyChange03(true);
                                  }}
                                >
                                  Inferior
                                </Button>
                              </Styled.BoxButtons>

                              {keepReturn.length > 0 && (
                                <Box display="flex" alignItems="center" mt={2}>
                                  <FormControl>
                                    <RadioGroup
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                      onChange={(e) => {
                                        setAtivationReturn(
                                          e.target.value as string
                                        );
                                        setVerifyChange03(
                                          e.target.value === ativationReturn
                                            ? false
                                            : true
                                        );
                                      }}
                                      value={ativationReturn}
                                    >
                                      <FormControlLabel
                                        value="yes"
                                        control={<Radio />}
                                        label="Com ativação"
                                      />
                                      <FormControlLabel
                                        value="no"
                                        control={<Radio />}
                                        label="Sem ativação"
                                      />
                                    </RadioGroup>
                                  </FormControl>

                                  <TextField
                                    label="Outros"
                                    onChange={(e) => {
                                      setOthersReturn(e.target.value as string);
                                      setVerifyChange03(true);
                                    }}
                                    value={othersReturn}
                                  />
                                </Box>
                              )}
                            </>
                          )}

                          {verifyChange03 && (
                            <TextField
                              label="Justifique a mudança"
                              value={justify03}
                              onChange={(e) => setJustify03(e.target.value)}
                              sx={{ marginTop: "20px" }}
                              error={verifyChange03 && justify03.length < 1}
                              helperText={
                                verifyChange03 && justify03.length < 1
                                  ? "Justifique sua alteração"
                                  : ""
                              }
                            />
                          )}
                        </Styled.BoxIndication>
                      ) : (
                        ""
                      )}

                      {questionsPlanning.filter((item) => item.id === "PPQ004")
                        .length > 0 ? (
                        <Styled.BoxIndication>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={cimentReturn}
                                  onClick={() => {
                                    setCimentReturn(!cimentReturn);
                                    setCimentBandReturn([]); // Reseta o estado ao desmarcar
                                    setVerifyChange04(true);
                                  }}
                                />
                              }
                              label="Cimentação de banda"
                            />
                          </FormGroup>

                          {cimentReturn && (
                            <>
                              <Styled.BoxButtons>
                                <Button
                                  variant={
                                    cimentBandReturn.includes("SUP")
                                      ? "contained"
                                      : "outlined"
                                  }
                                  onClick={() => {
                                    if (cimentBandReturn.includes("SUP")) {
                                      setCimentBandReturn(
                                        cimentBandReturn.filter(
                                          (type) => type !== "SUP"
                                        )
                                      );
                                    } else {
                                      setCimentBandReturn([
                                        ...cimentBandReturn,
                                        "SUP",
                                      ]);
                                    }
                                    setVerifyChange04(true);
                                  }}
                                >
                                  Superior
                                </Button>
                                <Button
                                  variant={
                                    cimentBandReturn.includes("INF")
                                      ? "contained"
                                      : "outlined"
                                  }
                                  onClick={() => {
                                    if (cimentBandReturn.includes("INF")) {
                                      setCimentBandReturn(
                                        cimentBandReturn.filter(
                                          (type) => type !== "INF"
                                        )
                                      );
                                    } else {
                                      setCimentBandReturn([
                                        ...cimentBandReturn,
                                        "INF",
                                      ]);
                                    }
                                    setVerifyChange04(true);
                                  }}
                                >
                                  Inferior
                                </Button>
                              </Styled.BoxButtons>

                              {cimentBandReturn.length > 0 && (
                                <Button
                                  onClick={() => {
                                    setModalCiment(true);
                                  }}
                                  sx={{ marginTop: "10px" }}
                                >
                                  Abrir Odontograma
                                </Button>
                              )}

                              <Modal
                                open={modalCiment}
                                onClose={() => setModalCiment(false)}
                              >
                                <Odontograma
                                  onClose={() => setModalCiment(false)}
                                  selected={odontogramaCimentReturn}
                                  setSelected={setOdontogramaCimentReturn}
                                  subtitle="Cimentação de banda"
                                />
                              </Modal>
                            </>
                          )}

                          {verifyChange04 && (
                            <TextField
                              label="Justifique a mudança"
                              value={justify04}
                              onChange={(e) => setJustify04(e.target.value)}
                              sx={{ marginTop: "20px" }}
                              error={verifyChange04 && justify04.length < 1}
                              helperText={
                                verifyChange04 && justify04.length < 1
                                  ? "Justifique sua alteração"
                                  : ""
                              }
                            />
                          )}
                        </Styled.BoxIndication>
                      ) : (
                        ""
                      )}

                      {questionsPlanning.filter((item) => item.id === "PPQ005")
                        .length > 0 ? (
                        <Styled.BoxIndication>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={glueReturn}
                                  onClick={() => {
                                    setGlueReturn(!glueReturn);
                                    setGlueTubReturn([]); // Reseta o estado ao desmarcar
                                    setVerifyChange05(true);
                                  }}
                                />
                              }
                              label="Colagem do tubo"
                            />
                          </FormGroup>

                          {glueReturn && (
                            <>
                              <Styled.BoxButtons>
                                <Button
                                  variant={
                                    glueTubReturn.includes("SUP")
                                      ? "contained"
                                      : "outlined"
                                  }
                                  onClick={() => {
                                    if (glueTubReturn.includes("SUP")) {
                                      setGlueTubReturn(
                                        glueTubReturn.filter(
                                          (type) => type !== "SUP"
                                        )
                                      );
                                    } else {
                                      setGlueTubReturn([
                                        ...glueTubReturn,
                                        "SUP",
                                      ]);
                                    }
                                    setVerifyChange05(true);
                                  }}
                                >
                                  Superior
                                </Button>
                                <Button
                                  variant={
                                    glueTubReturn.includes("INF")
                                      ? "contained"
                                      : "outlined"
                                  }
                                  onClick={() => {
                                    if (glueTubReturn.includes("INF")) {
                                      setGlueTubReturn(
                                        glueTubReturn.filter(
                                          (type) => type !== "INF"
                                        )
                                      );
                                    } else {
                                      setGlueTubReturn([
                                        ...glueTubReturn,
                                        "INF",
                                      ]);
                                    }
                                    setVerifyChange05(true);
                                  }}
                                >
                                  Inferior
                                </Button>
                              </Styled.BoxButtons>

                              {glueTubReturn.length > 0 && (
                                <Button
                                  onClick={() => {
                                    setModalCollage(true);
                                  }}
                                  sx={{ marginTop: "10px" }}
                                >
                                  Abrir Odontograma
                                </Button>
                              )}

                              <Modal
                                open={modalCollage}
                                onClose={() => setModalCollage(false)}
                              >
                                <Odontograma
                                  onClose={() => setModalCollage(false)}
                                  selected={odontogramaCollageReturn}
                                  setSelected={setOdontogramaCollageReturn}
                                  subtitle="Colagem do tubo"
                                />
                              </Modal>
                            </>
                          )}

                          {verifyChange05 && (
                            <TextField
                              label="Justifique a mudança"
                              value={justify05}
                              onChange={(e) => setJustify05(e.target.value)}
                              sx={{ marginTop: "20px" }}
                              error={verifyChange05 && justify05.length < 1}
                              helperText={
                                verifyChange05 && justify05.length < 1
                                  ? "Justifique sua alteração"
                                  : ""
                              }
                            />
                          )}
                        </Styled.BoxIndication>
                      ) : (
                        ""
                      )}

                      {questionsPlanning.filter((item) => item.id === "PPQ007")
                        .length > 0 ? (
                        <Styled.BoxIndication>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={complementReturn}
                                  onClick={() => {
                                    setComplementReturn(!complementReturn);
                                    setCompReturn([]);
                                    setVerifyChange07(true);
                                  }}
                                />
                              }
                              label="Aparelho Complementar"
                            />
                          </FormGroup>

                          {complementReturn && (
                            <>
                              <Autocomplete
                                options={complementary}
                                multiple
                                sx={{ margin: "10px 0" }}
                                onChange={(event, newValue) => {
                                  setCompReturn(newValue);
                                  setVerifyChange07(true);
                                }}
                                value={compReturn}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Aparelho complementar"
                                  />
                                )}
                              />

                              {compReturn.length > 0 && (
                                <Button
                                  onClick={() => {
                                    setModalComplement(true);
                                  }}
                                >
                                  Abrir Odontograma
                                </Button>
                              )}

                              <Modal
                                open={modalComplement}
                                onClose={() => setModalComplement(false)}
                              >
                                <Odontograma
                                  onClose={() => setModalComplement(false)}
                                  selected={odontogramaComplementReturn}
                                  setSelected={setOdontogramaComplementReturn}
                                  subtitle="Aparelho Complementar"
                                />
                              </Modal>
                            </>
                          )}
                          {verifyChange07 && (
                            <TextField
                              value={justify07}
                              label="Justifique a mudança"
                              onChange={(e) => setJustify07(e.target.value)}
                              sx={{ marginTop: "20px" }}
                              error={verifyChange07 && justify07.length < 1}
                              helperText={
                                verifyChange07 && justify07.length < 1
                                  ? "Justifique sua alteração"
                                  : ""
                              }
                            />
                          )}
                        </Styled.BoxIndication>
                      ) : (
                        ""
                      )}

                      {questionsPlanning.filter((item) => item.id === "PPQ008")
                        .length > 0 ? (
                        <Styled.BoxIndication>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={othersProceduresReturn}
                                  onClick={() => {
                                    setOthersProceduresReturn(
                                      !othersProceduresReturn
                                    );
                                    setOtherProceduresValue([]);
                                    setVerifyChange08(true);
                                  }}
                                />
                              }
                              label="Outros procedimentos"
                            />
                          </FormGroup>

                          {othersProceduresReturn && (
                            <>
                              <Autocomplete
                                options={proceduresData.map(
                                  (option) => option.label
                                )}
                                multiple
                                sx={{ margin: "10px 0" }}
                                onChange={(event, newValue) => {
                                  setOtherProceduresValue(newValue);
                                  setVerifyChange08(true);
                                }}
                                value={otherProceduresValue}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Outros procedimentos"
                                  />
                                )}
                              />
                            </>
                          )}
                          {verifyChange08 && (
                            <TextField
                              label="Justifique a mudança"
                              value={justify08}
                              onChange={(e) => setJustify08(e.target.value)}
                              sx={{ marginTop: "20px" }}
                              error={verifyChange08 && justify08.length < 1}
                              helperText={
                                verifyChange08 && justify08.length < 1
                                  ? "Justifique sua alteração"
                                  : ""
                              }
                            />
                          )}
                        </Styled.BoxIndication>
                      ) : (
                        ""
                      )}

                      {questionsPlanning.filter((item) => item.id === "PPQ009")
                        .length > 0 ? (
                        <Styled.BoxIndication>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={othersReferralsReturn}
                                  onClick={() => {
                                    setOthersReferralsReturn(
                                      !othersReferralsReturn
                                    );
                                    setVerifyChange09(true);
                                  }}
                                />
                              }
                              label="Encaminhamento"
                            />
                          </FormGroup>

                          {othersReferralsReturn && (
                            <>
                              <Autocomplete
                                options={referralsData}
                                multiple
                                sx={{ margin: "10px 0" }}
                                onChange={(event, newValue) => {
                                  setValueEncReturn(newValue);
                                  setVerifyChange09(true);
                                }}
                                value={valueEncReturn}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Encaminhamento"
                                  />
                                )}
                              />
                            </>
                          )}
                          {verifyChange09 && (
                            <TextField
                              label="Justifique a mudança"
                              value={justify09}
                              onChange={(e) => setJustify09(e.target.value)}
                              sx={{ marginTop: "20px" }}
                              error={verifyChange09 && justify09.length < 1}
                              helperText={
                                verifyChange09 && justify09.length < 1
                                  ? "Justifique sua alteração"
                                  : ""
                              }
                            />
                          )}
                        </Styled.BoxIndication>
                      ) : (
                        ""
                      )}
                    </Styled.BoxPlanning>
                  )}
                </>
              ) : (
                ""
              )}
            </>

            {/*patientObj.aname_id > 0 ? (
              <>
                <Styled.BoxIndication>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={attachment}
                          onClick={() => {
                            setAttachment(!attachment);
                            setChangeAttachment([]);
                          }}
                        />
                      }
                      label="Troca de Attachment"
                    />
                  </FormGroup>

                  {attachment && (
                    <>
                      <Styled.BoxButtons>
                        <Button
                          variant={
                            changeAttachment.includes("SUP")
                              ? "contained"
                              : "outlined"
                          }
                          onClick={() => {
                            if (changeAttachment.includes("SUP")) {
                              setChangeAttachment(
                                changeAttachment.filter(
                                  (type) => type !== "SUP"
                                )
                              );
                            } else {
                              setChangeAttachment([...changeAttachment, "SUP"]);
                            }
                          }}
                        >
                          Superior
                        </Button>
                        <Button
                          variant={
                            changeAttachment.includes("INF")
                              ? "contained"
                              : "outlined"
                          }
                          onClick={() => {
                            if (changeAttachment.includes("INF")) {
                              setChangeAttachment(
                                changeAttachment.filter(
                                  (type) => type !== "INF"
                                )
                              );
                            } else {
                              setChangeAttachment([...changeAttachment, "INF"]);
                            }
                          }}
                        >
                          Inferior
                        </Button>
                      </Styled.BoxButtons>
                    </>
                  )}
                  {changeAttachment.length > 0 && (
                    <>
                      <Button
                        sx={{ margin: "20px 0" }}
                        onClick={() => setModalOdontoAttachment(true)}
                      >
                        Abrir Odontograma
                      </Button>

                      <Modal
                        open={modalOdontoAttachment}
                        onClose={() => setModalOdontoAttachment(false)}
                      >
                        <Odontograma
                          onClose={() => setModalOdontoAttachment(false)}
                          selected={odontogramaAttachment}
                          setSelected={setOdontogramaAttachment}
                          subtitle="Troca de Attachment"
                        />
                      </Modal>
                    </>
                  )}
                </Styled.BoxIndication>

                <Styled.BoxIndication>
                  <Typography
                    variant="h5"
                    sx={{ marginTop: "5px", marginBottom: "10px" }}
                  >
                    Necessidade de IPR ?
                  </Typography>
                  <Styled.BoxButtons style={{ marginBottom: "10px" }}>
                    <Button
                      variant={realizeIPR === "YES" ? "contained" : "outlined"}
                      onClick={() => {
                        setRealizeIPR("YES");
                      }}
                    >
                      Sim
                    </Button>
                    <Button
                      variant={realizeIPR === "NO" ? "contained" : "outlined"}
                      onClick={() => {
                        setRealizeIPR("NO");
                      }}
                    >
                      Não
                    </Button>
                  </Styled.BoxButtons>
                </Styled.BoxIndication>

                <Styled.BoxIndication>
                  <TextField
                    label="Observações gerais"
                    fullWidth
                    margin="normal"
                    value={allObservationsProcedure}
                    onChange={(e) =>
                      setAllObservationsProcedure(e.target.value)
                    }
                  />
                </Styled.BoxIndication>
              </>
            ) : (
              <></>
            )*/}
            <>
              <Styled.BoxIndication>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={attachment}
                        onClick={() => {
                          setAttachment(!attachment);
                          setChangeAttachment([]);
                        }}
                      />
                    }
                    label="Colagem Attachment"
                  />
                </FormGroup>

                {attachment && (
                  <>
                    <Styled.BoxButtons>
                      <Button
                        variant={
                          changeAttachment.includes("SUP")
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() => {
                          if (changeAttachment.includes("SUP")) {
                            setChangeAttachment(
                              changeAttachment.filter((type) => type !== "SUP")
                            );
                          } else {
                            setChangeAttachment([...changeAttachment, "SUP"]);
                          }
                        }}
                      >
                        Superior
                      </Button>
                      <Button
                        variant={
                          changeAttachment.includes("INF")
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() => {
                          if (changeAttachment.includes("INF")) {
                            setChangeAttachment(
                              changeAttachment.filter((type) => type !== "INF")
                            );
                          } else {
                            setChangeAttachment([...changeAttachment, "INF"]);
                          }
                        }}
                      >
                        Inferior
                      </Button>
                    </Styled.BoxButtons>
                  </>
                )}

                {changeAttachment.length > 0 && (
                  <>
                    <Button
                      sx={{ margin: "20px 0" }}
                      onClick={() => setModalOdontoAttachment(true)}
                    >
                      Abrir Odontograma
                    </Button>

                    <Modal
                      open={modalOdontoAttachment}
                      onClose={() => setModalOdontoAttachment(false)}
                    >
                      <Odontograma
                        onClose={() => setModalOdontoAttachment(false)}
                        selected={odontogramaAttachment}
                        setSelected={setOdontogramaAttachment}
                        subtitle="Colagem Attachment"
                      />
                    </Modal>
                  </>
                )}
              </Styled.BoxIndication>

              <Styled.BoxIndication>
                <Typography
                  variant="h5"
                  sx={{ marginTop: "5px", marginBottom: "10px" }}
                >
                  Foi realizada a IPR ?
                </Typography>
                <Styled.BoxButtons style={{ marginBottom: "10px" }}>
                  <Button
                    variant={realizeIPR === "YES" ? "contained" : "outlined"}
                    onClick={() => {
                      setRealizeIPR("YES");
                    }}
                  >
                    Sim
                  </Button>
                  <Button
                    variant={realizeIPR === "NO" ? "contained" : "outlined"}
                    onClick={() => {
                      setRealizeIPR("NO");
                    }}
                  >
                    Não
                  </Button>
                </Styled.BoxButtons>

                {realizeIPR === "YES" && (
                  <>
                    <Button
                      sx={{ margin: "20px 0" }}
                      onClick={() => setModalIPR(true)}
                    >
                      Abrir Odontograma
                    </Button>

                    <Modal open={modalIPR} onClose={() => setModalIPR(false)}>
                      <Odontograma
                        onClose={() => setModalIPR(false)}
                        selected={odontogramaIPR}
                        setSelected={setOdontogramaIPR}
                        subtitle="Realizado a IPR"
                      />
                    </Modal>
                  </>
                )}
              </Styled.BoxIndication>

              {patientObj.aname_id > 0 && (
                <Styled.BoxIndication>
                  <Typography
                    variant="h5"
                    sx={{ marginTop: "5px", marginBottom: "10px" }}
                  >
                    Há necessidade de uso do elástico?
                  </Typography>
                  <Styled.BoxButtons style={{ marginBottom: "10px" }}>
                    <Button
                      variant={
                        elasticRequired === "YES" ? "contained" : "outlined"
                      }
                      onClick={() => {
                        setElasticRequired("YES");
                      }}
                    >
                      Sim
                    </Button>
                    <Button
                      variant={
                        elasticRequired === "NO" ? "contained" : "outlined"
                      }
                      onClick={() => {
                        setElasticRequired("NO");
                      }}
                    >
                      Não
                    </Button>
                  </Styled.BoxButtons>
                </Styled.BoxIndication>
              )}

              <Styled.BoxIndication>
                <Typography
                  variant="h5"
                  sx={{ marginTop: "5px", marginBottom: "10px" }}
                >
                  Orientou o paciente sobre o uso do aparelho invisível?
                </Typography>
                <Styled.BoxButtons style={{ marginBottom: "10px" }}>
                  <Button
                    variant={
                      orientInvisible === "YES" ? "contained" : "outlined"
                    }
                    onClick={() => {
                      setOrientInvisible("YES");
                    }}
                  >
                    Sim
                  </Button>
                  <Button
                    variant={
                      orientInvisible === "NO" ? "contained" : "outlined"
                    }
                    onClick={() => {
                      setOrientInvisible("NO");
                    }}
                  >
                    Não
                  </Button>
                </Styled.BoxButtons>

                {orientInvisible === "NO" && (
                  <>
                    <TextField
                      label="Justificativa*"
                      fullWidth
                      margin="normal"
                      value={motiveNoOrient}
                      onChange={(e) => setMotiveNoOrient(e.target.value)}
                    />
                  </>
                )}
              </Styled.BoxIndication>

              <Styled.BoxIndication>
                <Typography
                  variant="h5"
                  sx={{ marginTop: "5px", marginBottom: "10px" }}
                >
                  Orientou o paciente sobre o período de troca?
                </Typography>
                <Styled.BoxButtons style={{ marginBottom: "10px" }}>
                  <Button
                    variant={orientPeriod === "YES" ? "contained" : "outlined"}
                    onClick={() => {
                      setOrientPeriod("YES");
                    }}
                  >
                    Sim
                  </Button>
                  <Button
                    variant={orientPeriod === "NO" ? "contained" : "outlined"}
                    onClick={() => {
                      setOrientPeriod("NO");
                    }}
                  >
                    Não
                  </Button>
                </Styled.BoxButtons>

                {orientPeriod === "NO" && (
                  <>
                    <TextField
                      label="Justificativa*"
                      fullWidth
                      margin="normal"
                      value={motivePeriod}
                      onChange={(e) => setMotivePeriod(e.target.value)}
                    />
                  </>
                )}

                {orientPeriod === "YES" && (
                  <Styled.BoxDays>
                    <Button
                      className={daysPeriod === 7 ? "activeBtn" : "disabledBtn"}
                      onClick={() => setDaysPeriod(7)}
                    >
                      7 dias
                    </Button>
                    <Button
                      className={
                        daysPeriod === 10 ? "activeBtn" : "disabledBtn"
                      }
                      onClick={() => setDaysPeriod(10)}
                    >
                      10 dias
                    </Button>
                    <Button
                      className={
                        daysPeriod === 15 ? "activeBtn" : "disabledBtn"
                      }
                      onClick={() => setDaysPeriod(15)}
                    >
                      15 dias
                    </Button>
                  </Styled.BoxDays>
                )}
              </Styled.BoxIndication>

              <Styled.BoxIndication>
                <Typography
                  variant="h5"
                  sx={{ marginTop: "5px", marginBottom: "10px" }}
                >
                  Quantas placas vieram?
                </Typography>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={plates}
                    onChange={(e) => setPlates(String(e.target.value))}
                  >
                    {[...Array(16).keys()].map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Styled.BoxIndication>

              <Styled.BoxIndication>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={hasOtherProcedures}
                        onClick={() =>
                          setHasOtherProcedures(!hasOtherProcedures)
                        }
                      />
                    }
                    label="Outros procedimentos"
                  />
                </FormGroup>
                {hasOtherProcedures && (
                  <>
                    <Autocomplete
                      options={proceduresDataWithFlow.map(
                        (option: { label: any }) => option.label
                      )}
                      multiple
                      sx={{ margin: "10px 0" }}
                      onChange={(event, newValue) =>
                        setOtherProceduresValueSecondConsult(newValue)
                      }
                      value={otherProceduresValueSecondConsult}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          value={otherProceduresValueSecondConsult}
                          label="Outros procedimentos"
                        />
                      )}
                    />
                  </>
                )}
              </Styled.BoxIndication>

              <Styled.BoxIndication>
                <TextField
                  label="Observações gerais"
                  fullWidth
                  margin="normal"
                  value={allObservationsProcedure}
                  onChange={(e) => setAllObservationsProcedure(e.target.value)}
                />
              </Styled.BoxIndication>
            </>

            {patientObj.aname_id > 0 ? (
              <Styled.BtnFixedAnam>
                <Button fullWidth onClick={next}>
                  Continuar
                </Button>
              </Styled.BtnFixedAnam>
            ) : (
              <Styled.BtnFixed>
                <Button fullWidth onClick={() => setStep(2)} variant="outlined">
                  Voltar
                </Button>
                <Button fullWidth onClick={next}>
                  Continuar
                </Button>
              </Styled.BtnFixed>
            )}
          </>
        )}
      </>
    </Styled.Box>
  );
};

export { ProcedurePerformedInvisible };
