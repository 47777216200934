import React from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { authLogin, disconnect } from "requests/Auth";
import { getTimeReset } from "requests/Config";
import { getMaterials } from "requests/Material";
import { getProcedures } from "requests/Procedure";
import { getReferrals } from "requests/Referrals";
import { getSchedule } from "requests/Schedule";
//import { authLogin } from "requests/Auth";
import { setAuthenticatedUser } from "shared/hocs";
import {
  IAuth,
  IAuthLogin,
  IAuthenticated,
  ILabelValue,
  ILabelValueProcedure,
} from "shared/types/Auth";
import { ITypes, IWire } from "shared/types/Material";
import { ISchedule, Schedule, TimeSlot } from "shared/types/Schedule";

interface IProps {
  children: any;
}

export interface IAuthContext {
  signIn: (formData: IAuthLogin, callback?: () => void) => void;
  loading: boolean;
  freeTime: boolean;
  setFreeTime: (value: boolean) => void;
  deslogged: (id: string) => void;
  searchPatients: string;
  setSearchPatients: (value: string) => void;
  schedules: (value: ISchedule) => void;
  user: IAuth;
  scheduleData: TimeSlot[];
  complementary: ILabelValue[];
  type: ITypes[];
  wire: IWire[];
  proceduresData: ILabelValueProcedure[];
  loadingSchedule: boolean;
  materials: (value: { userId: string; idPac: number }) => void;
  procedures: (value: { userId: string; type: number; idPac: number }) => void;
  referralsData: ILabelValue[];
  referrals: (value: { id: string; idSchedule: number; idPac: number }) => void;

  alerts: any[];
  setAlerts: (value: any[]) => void;
  modalOpen: boolean;
  setModalOpen: (value: boolean) => void;
  responses: { id: string; description: string; message: string }[];
  setResponses: (
    value: { id: string; description: string; message: string }[]
  ) => void;

  timeChoosen: string;
  setTimeChoosen: (value: string) => void;

  timeSelect: string;
  setTimeSelect: (value: string) => void;

  showServed: boolean;
  setShowServed: (value: boolean) => void;

  controlArrived: "SCHEDULE" | "ARRIVED";
  setControlArrived: (value: "SCHEDULE" | "ARRIVED") => void;

  timeRefresh: number;

  proceduresDataWithFlow: ILabelValueProcedure[];
}

const AuthContext = React.createContext({} as IAuthContext);

const AuthProvider: React.FC<IProps> = ({ children }) => {
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [freeTime, setFreeTime] = React.useState<boolean>(false);
  const [showServed, setShowServed] = React.useState<boolean>(false);
  const [searchPatients, setSearchPatients] = React.useState<string>("");
  //const [user, setUser] = React.useState<IAuth>({} as IAuth);
  const [scheduleData, setScheduleData] = React.useState<TimeSlot[]>([]);
  const [complementary, setComplementary] = React.useState<ILabelValue[]>([]);
  const [proceduresData, setProceduresData] = React.useState<
    ILabelValueProcedure[]
  >([]);

  const [proceduresDataWithFlow, setProceduresDataWithFlow] = React.useState<
    ILabelValueProcedure[]
  >([]);
  const [referralsData, setReferralsData] = React.useState<ILabelValue[]>([]);

  const [type, setType] = React.useState<ITypes[]>([]);

  const [wire, setWire] = React.useState<IWire[]>([]);

  const [loadingSchedule, setLoadingSchedule] = React.useState<boolean>(false);

  const [alerts, setAlerts] = React.useState<any[]>([]);
  const [modalOpen, setModalOpen] = React.useState(false);

  const [responses, setResponses] = React.useState<
    { id: string; description: string; message: string }[]
  >([]);

  const [timeChoosen, setTimeChoosen] = React.useState<string>("");

  const [timeSelect, setTimeSelect] = React.useState<string>("");

  const [timeRefresh, setTimeRefres] = React.useState<number>(36000);

  const [controlArrived, setControlArrived] = React.useState<
    "SCHEDULE" | "ARRIVED"
  >("ARRIVED");

  const deslogged = React.useCallback(
    async (id: string) => {
      const { status, data } = await disconnect(id);

      if ([200, 201, 204].includes(status)) {
        localStorage.clear();
        navigate("/login");
      } else {
        toast.error(data.message);
      }
    },
    [navigate]
  );

  const transformScheduleData = React.useCallback(
    (scheduleData: Record<string, Schedule[] | []>): TimeSlot[] => {
      const result: TimeSlot[] = [];

      Object.entries(scheduleData).forEach(([time, data]) => {
        /*data &&
          data.forEach((item) => {
            result.push({
              time,
              data: data ? [item] : [],
            });
          });*/

        if (data) {
          data.forEach((item) => {
            result.push({
              time,
              data: [item],
            });
          });
        } else {
          result.push({
            time,
            data: [],
          });
        }
      });

      return result;
    },
    []
  );

  const schedules = React.useCallback(
    async (values: ISchedule) => {
      setLoadingSchedule(true);

      try {
        const { status, data } = await getSchedule(values);

        if ([200, 201].includes(status)) {
          const transformedData = transformScheduleData(data.data);

          // Extrai os IDs dos novos dados
          const ids: number[] = [];

          transformedData.forEach((appointment) =>
            appointment.data.forEach((item) => {
              if (item.st_schedule === "C") {
                ids.push(item.id);
              }
            })
          );

          const lastAttendanceId = Number(
            localStorage.getItem("lastAttendanceId") || 0
          );

          const lastAttendanceRequestId = ids[ids.length - 1];

          if (lastAttendanceRequestId !== lastAttendanceId) {
            localStorage.setItem("lastAttendanceId", `${ids[ids.length - 1]}`);

            //@ts-ignore
            if (window && window.ReactNativeWebView) {
              // Chama a função nativa para tocar o áudio
              //@ts-ignore
              window.ReactNativeWebView.postMessage(
                JSON.stringify({
                  action: "playNotificationSound",
                  value: "",
                })
              );
            }
          }

          // Atualiza os dados no estado e no localStorage
          setScheduleData(transformedData);
        } else {
          toast.error(data.message);
          throw new Error(data.message);
        }
      } catch (e: any) {
        console.error(e);
      } finally {
        setLoadingSchedule(false);
      }
    },
    [transformScheduleData]
  );

  const materials = React.useCallback(
    async (values: { userId: string; idPac: number }) => {
      setLoading(true);

      try {
        setLoading(false);
        const { data: dataMaterial, status: statusMaterial } =
          await getMaterials(values.userId, values.idPac);

        if ([200, 201].includes(statusMaterial)) {
          const valuesComplementary: ILabelValue[] = [];

          dataMaterial.data.complementary.forEach((item) => {
            valuesComplementary.push({
              label: item.name,
              value: item.id,
            });
          });

          setComplementary(valuesComplementary);
          setType(dataMaterial.data.type);
          setWire(dataMaterial.data.wire);
        }
      } catch (e: any) {
        setLoading(false);
      }
      setLoading(false);
    },
    []
  );

  const procedures = React.useCallback(
    async (values: { userId: string; type: number; idPac: number }) => {
      setLoading(true);

      try {
        setLoading(false);
        const { data: dataProcedures, status: statusProcedures } =
          await getProcedures(values.userId, values.type, values.idPac);

        if ([200, 201].includes(statusProcedures)) {
          const valuesComplementary: ILabelValueProcedure[] = [];

          dataProcedures.data.forEach((item) => {
            if (
              item.id !== 2 &&
              item.id !== 30 &&
              item.id !== 31 &&
              item.id !== 492
            ) {
              valuesComplementary.push({
                label: item.description,
                value: item.id,
                flow: item.flow,
              });
            }
          });

          setProceduresData(valuesComplementary);

          const valuesComplementaryWithFlow: ILabelValueProcedure[] = [];

          dataProcedures.data.forEach((item) => {
            if (item.flow !== 0) {
              valuesComplementaryWithFlow.push({
                label: item.description,
                value: item.id,
                flow: item.flow,
              });
            }
          });

          setProceduresDataWithFlow(valuesComplementaryWithFlow);
        }
      } catch (e: any) {
        setLoading(false);
      }
      setLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const referrals = React.useCallback(
    async (values: { id: string; idSchedule: number; idPac: number }) => {
      setLoading(true);

      try {
        setLoading(false);
        const { data: dataProcedures, status: statusProcedures } =
          await getReferrals(values.id, values.idSchedule, values.idPac);

        if ([200, 201].includes(statusProcedures)) {
          const valuesComplementary: ILabelValue[] = [];

          dataProcedures.data.forEach((item) => {
            /*if (
              item.id !== 2 &&
              item.id !== 30 &&
              item.id !== 31 &&
              item.id !== 285 &&
              item.id !== 492
            ) {
              valuesComplementary.push({
                label: item.NAME,
                value: item.ID,
              });
            }*/
            valuesComplementary.push({
              label: item.name,
              value: item.id,
            });
          });

          setReferralsData(valuesComplementary);
        }
      } catch (e: any) {
        setLoading(false);
      }
      setLoading(false);
    },
    []
  );

  const signIn = React.useCallback(
    async (values: IAuthLogin) => {
      setLoading(true);

      try {
        const { status, data: dataLogin } = await authLogin(values);

        if ([200, 201].includes(status)) {
          const authenticated: IAuthenticated = {
            auth: {
              message: dataLogin.message,
              success: dataLogin.success,
              data: {
                token: dataLogin.data.token,
                id: dataLogin.data.id,
                dentist: {
                  id: dataLogin.data.dentist.id,
                  name: dataLogin.data.dentist.name,
                  idUnit: dataLogin.data.dentist.idUnit,
                  appraiser: dataLogin.data.dentist.appraiser,
                  /*hour: {
                    startTimeMorning:
                      dataLogin.data.dentist.hour.startTimeMorning,
                    endTimeMorning: dataLogin.data.dentist.hour.endTimeMorning,
                    startTimeAfternoon:
                      dataLogin.data.dentist.hour.startTimeAfternoon,
                    endTimeAfternoon:
                      dataLogin.data.dentist.hour.endTimeAfternoon,
                  },*/
                },
              },
            },
          };

          localStorage.setItem(
            "userActual",
            JSON.stringify(authenticated.auth)
          );
          //setUser(authenticated.auth);

          setAuthenticatedUser(authenticated);
          setLoading(false);
          navigate("/home");
          toast.success(dataLogin.message);
        } else {
          toast.error(dataLogin.message);
          throw new Error(dataLogin.message);
        }
      } catch (e: any) {
        setLoading(false);
      }
      setLoading(false);
    },
    [navigate]
  );

  const timeReset = React.useCallback(async () => {
    try {
      const { status, data } = await getTimeReset();

      if ([200, 201, 204].includes(status)) {
        const timeMiliseconds = data.data.times.refresh * 1000;

        setTimeRefres(timeMiliseconds);
      } else {
        toast.error(data.message);
      }
    } catch (e) {
      console.error(e);
    } finally {
    }
  }, []);

  function toastGlobal() {
    toast.success("Evolução carregada com sucesso!");
  }

  (window as any).toastGlobal = toastGlobal;

  React.useEffect(() => {
    timeReset();
  }, [timeReset]);

  const userActual = localStorage.getItem("userActual");

  const user = JSON.parse(userActual as string);

  const value = React.useMemo(
    () => ({
      signIn,
      loading,
      freeTime,
      setFreeTime,
      deslogged,
      searchPatients,
      setSearchPatients,
      schedules,
      user,
      scheduleData,
      complementary,
      type,
      wire,
      proceduresData,
      loadingSchedule,
      procedures,
      materials,
      referralsData,
      referrals,
      alerts,
      setAlerts,
      modalOpen,
      setModalOpen,
      responses,
      setResponses,
      timeChoosen,
      setTimeChoosen,
      timeSelect,
      setTimeSelect,
      showServed,
      setShowServed,
      controlArrived,
      setControlArrived,
      timeRefresh,
      proceduresDataWithFlow,
    }),
    [
      signIn,
      loading,
      freeTime,
      setFreeTime,
      deslogged,
      searchPatients,
      setSearchPatients,
      schedules,
      user,
      scheduleData,
      complementary,
      type,
      wire,
      proceduresData,
      loadingSchedule,
      procedures,
      materials,
      referralsData,
      referrals,
      alerts,
      setAlerts,
      modalOpen,
      setModalOpen,
      responses,
      setResponses,
      timeChoosen,
      setTimeChoosen,
      timeSelect,
      setTimeSelect,
      showServed,
      setShowServed,
      controlArrived,
      setControlArrived,
      timeRefresh,
      proceduresDataWithFlow,
    ]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = (): IAuthContext => React.useContext(AuthContext);

export { AuthContext, AuthProvider, useAuth };
