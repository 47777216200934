import API from "shared/services/Api";
import { IReferralsResponse } from "shared/types/Referrals";

export const getReferrals = async (
  userId: string,
  isSchedule: number,
  patientId: number
): Promise<IReferralsResponse> =>
  API.get(
    `/oc/referrals?id=${userId}&idSchedule=${isSchedule}&idPac=${patientId}`
  );
