import React from "react";

import * as Styled from "./styles";

import { ReactComponent as MenuIcon } from "shared/assets/svg/menu.svg";
import { Avatar, Menu, MenuItem, Typography, Box, Modal } from "@mui/material";

/*import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";*/
import { menuPatientAvaliation } from "shared/mock";
import { DEFAULT_AVATAR } from "shared/config/properties";
import { CloseConsult } from "components/CloseConsult";
import { Documentation } from "components/Documentation";
import { ClinicalRecordUser } from "components/ClinicalRecord";
import { useAvaliation } from "shared/providers/Avaliation";

interface IProps {
  title: any;
  children?: any;
}

const HeadConsult: React.FC<IProps> = ({ title, children }) => {
  const { patientObj /*step*/ } = useAvaliation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openClose, setOpenClose] = React.useState<boolean>(false);

  const [openCloseDocumentation, setOpenCloseDocumentation] =
    React.useState<boolean>(false);

  const [openCloseClinicalRecord, setOpenCloseClinicalRecord] =
    React.useState<boolean>(false);

  /* function LinearProgressWithLabel(
    props: LinearProgressProps & { value: number }
  ) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column-reverse",
          gap: "10px",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box>
          <Typography
            variant="body2"
            color="#007DED"
            sx={{ fontWeight: "500", fontSize: "18px" }}
          >
            {title}
          </Typography>
        </Box>
      </Box>
    );
  }

  function LinearWithValueLabel() {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgressWithLabel
          value={step === 1 ? 50 : step === 2 ? 100 : 0}
        />
      </Box>
    );
  }*/

  return (
    <>
      <Styled.Box>
        <Styled.FakeBox></Styled.FakeBox>
        <Styled.Info>
          <Avatar
            src={
              patientObj && patientObj.pac_avatar && patientObj.pac_avatar
                ? patientObj.pac_avatar
                : DEFAULT_AVATAR
            }
            sx={{ width: "55px", height: "55px" }}
            alt={patientObj.pac_name}
          />
          <Typography sx={{ color: "#919296", fontWeight: "500" }}>
            ID {patientObj && patientObj.pac_id_fant && patientObj.pac_id_fant}
          </Typography>
          <Typography
            sx={{ color: "#343536", fontWeight: "bold", fontSize: "20px" }}
          >
            {patientObj.pac_name}
          </Typography>
          {/*<LinearWithValueLabel /> */}
        </Styled.Info>
        <Styled.Menu>
          <button
            onClick={handleClick}
            style={{
              backgroundColor: "transparent",
              border: "none",
            }}
          >
            <MenuIcon />
          </button>
        </Styled.Menu>
      </Styled.Box>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {menuPatientAvaliation.map((item, index) => (
          <MenuItem key={index}>
            <Styled.NoLink onClick={() => setOpenClose(true)}>
              {<item.icon />} {item.text}
            </Styled.NoLink>
          </MenuItem>
        ))}

        <Modal open={openClose} onClose={() => setOpenClose(false)}>
          <Box sx={{ backgroundColor: "white", height: "100%", width: "100%" }}>
            <CloseConsult
              close={() => setOpenClose(false)}
              idSchedule={patientObj.id}
            />
          </Box>
        </Modal>

        <Modal
          open={openCloseDocumentation}
          onClose={() => setOpenCloseDocumentation(false)}
        >
          <Box sx={{ backgroundColor: "white", height: "100%", width: "100%" }}>
            <Documentation
              onClick={() => setOpenCloseDocumentation(false)}
              idPacientParam={patientObj.pac_id}
            />
          </Box>
        </Modal>

        <Modal
          open={openCloseClinicalRecord}
          onClose={() => setOpenCloseClinicalRecord(false)}
        >
          <Box sx={{ backgroundColor: "white", height: "100%", width: "100%" }}>
            <ClinicalRecordUser
              onClick={() => setOpenCloseClinicalRecord(false)}
              idPacientParam={patientObj.pac_id}
            />
          </Box>
        </Modal>
      </Menu>

      {children}
    </>
  );
};

export { HeadConsult };
