import React from "react";
import * as Styled from "./styles";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import { materialOptions, numbers, referralOptions } from "shared/mock";

import { useAuth } from "shared/providers/auth";
import toast from "react-hot-toast";
import { Odontograma } from "components/Odontograma";
import { useMaintenance } from "shared/providers/Maintenance";

const ProcedurePerformedFixed: React.FC = () => {
  const {
    setStep,
    braceType,
    painPrescriptionNeeded,
    setPainPrescriptionNeeded,
    informedBasicCare,
    setInformedBasicCare,
    deliveredTreatmentManual,
    advisedWireAndWax,
    additionalDevice,
    setAdditionalDevice,
    hasComplement,
    setHasComplement,
    hasOtherProcedures,
    setHasOtherProcedures,
    otherProceduresValue,
    setOtherProceduresValue,
    setReferralValue,
    setShowInformedBasicCare,
    setShowDeliveredTreatmentManual,

    hasOtherReferrals,
    setHasOtherReferrals,

    basicCareObservations,
    setBasicCareObservations,
    treatmentManualObservations,

    wireAndWaxObservations,

    errorBasicCare,
    setErrorBasicCare,
    setErrorTreatmentManual,
    setErrorWireAndWax,
    odontogramaComplement,
    setOdontogramaComplement,
    questionsPlanning,
    aparateReturn,
    setAparateReturn,
    aparateBraceReturn,
    setAparateBraceReturn,
    materialReturn,
    setMaterialReturn,
    numberReturn,
    setNumberReturn,
    othersReturn,
    setOthersReturn,
    wireReturn,
    setWireReturn,
    changeWireReturn,
    setChangeWireReturn,
    materialChangeReturn,
    setMaterialChangeReturn,
    numberChangeReturn,
    setNumberChangeReturn,
    othersChangeReturn,
    setOthersChangeReturn,
    keepThreadReturn,
    setKeepThreadReturn,
    keepReturn,
    setKeepReturn,
    setAtivationReturn,
    ativationReturn,
    cimentReturn,
    setCimentReturn,
    cimentBandReturn,
    setCimentBandReturn,
    glueTubReturn,
    glueReturn,
    setGlueTubReturn,
    setGlueReturn,
    complementReturn,
    setComplementReturn,
    compReturn,
    setCompReturn,
    othersProceduresReturn,
    setOthersProceduresReturn,
    //setValueOthersReturn,
    othersReferralsReturn,
    setOthersReferralsReturn,
    setValueEncReturn,
    //valueOthersReturn,
    valueEncReturn,
    justify01,
    setJustify01,
    justify02,
    setJustify02,
    justify03,
    setJustify03,
    justify04,
    setJustify04,
    justify05,
    setJustify05,
    justify07,
    setJustify07,
    justify08,
    setJustify08,
    justify09,
    setJustify09,
    repOrRec,
    setRepOrRec,
    repOrRecBrace,
    setRepOrRecBrace,
    rep,
    setRep,
    materialProcedure,
    numberProcedure,
    realize,
    setRealize,
    setJustification,
    realizeContent,
    setRealizeContent,
    realizeAparat,
    setRealizeAparat,
    realizeEruption,
    setRealizeEruption,
    realizeIntermaxilary,
    setRealizeIntermaxilary,
    realizeCollage,
    setRealizeCollage,
    realizeRebonding,
    setRealizeRebonding,
    realizeBite,
    setRealizeBite,
    route,
    setRoute,
    setJustifyRoute,
    realizeRelease,
    setRealizeRelease,
    setJustificationContent,
    setInformateRegionContent,
    setJustificationAparat,
    setJustificationEruption,
    setInformateRegionEruption,
    setJustificationIntermaxilary,
    setJustificationCollage,
    setJustificationRebonding,
    setJustificationBite,
    setJustificationRelease,
    setInformateRegion,
    currentProcedureIndex,
    setCurrentProcedureIndex,

    odontogramaComplementReturn,
    setOdontogramaComplementReturn,
    odontogramaCollageReturn,
    setOdontogramaCollageReturn,
    odontogramaCimentReturn,
    setOdontogramaCimentReturn,

    /*proceduresLastAttendance,
    setProceduresLastAttendance,

    currentProcedureIndexReturn,
    setCurrentProcedureIndexReturn,

    showQuestionsReturn,
    setShowQuestionsReturn,*/

    odontogramaRemote,
    setOdontogramaRemote,
    odontogramaInstall,
    setOdontogramaInstall,
    odontogramaButton,
    setOdontogramaButton,
    odontogramaIntermax,
    setOdontogramaIntermax,
    odontogramaLevant,
    setOdontogramaLevant,

    /* setJustificationReturn,
    setInformateRegionReturn,
    realizeReturn,
    setRealizeReturn,
    realizeContentReturn,
    setRealizeContentReturn,
    realizeAparatReturn,
    setRealizeAparatReturn,
    realizeEruptionReturn,
    setRealizeEruptionReturn,
    realizeIntermaxilaryReturn,
    setRealizeIntermaxilaryReturn,
    realizeCollageReturn,
    setRealizeCollageReturn,
    realizeRebondingReturn,
    setRealizeRebondingReturn,
    realizeBiteReturn,
    setRealizeBiteReturn,
    routeReturn,
    setRouteReturn,
    setJustifyRouteReturn,
    realizeReleaseReturn,
    setRealizeReleaseReturn,
    setJustificationContentReturn,
    setInformateRegionContentReturn,
    setJustificationAparatReturn,
    setJustificationEruptionReturn,
    setInformateRegionEruptionReturn,
    setJustificationIntermaxilaryReturn,
    setJustificationCollageReturn,
    setJustificationRebondingReturn,
    setJustificationBiteReturn,
    setJustificationReleaseReturn,
    odontogramaRemoteReturn,
    setOdontogramaRemoteReturn,
    odontogramaInstallReturn,
    setOdontogramaInstallReturn,
    odontogramaButtonReturn,
    setOdontogramaButtonReturn,
    odontogramaIntermaxReturn,
    setOdontogramaIntermaxReturn,
    odontogramaLevantReturn,
    setOdontogramaLevantReturn,*/
    odontogramaPlanningAparatReturn,
    setOdontogramaPlanningAparatReturn,
    /*braceTypeMaintenance,
    setBraceTypeMaintenance,
    repOrRecProcedure,
    setRepOrRecProcedure,
    repOrRecBraceProcedure,
    setRepOrRecBraceProcedure,
    repProcedure,
    setRepProcedure,
    painPrescriptionNeededMaintenance,
    setPainPrescriptionNeededMaintenance,
    informedBasicCareMaintenance,
    setInformedBasicCareMaintenance,
    setMaterialProcedureMaintenance,
    setNumberProcedureMaintenance,
    setOthersProceduresFixedMaintenance,
    basicCareObservationsMaintenance,
    setBasicCareObservationsMaintenance,

    braceTypeMaintenanceReturn,
    setBraceTypeMaintenanceReturn,
    repOrRecProcedureReturn,
    setRepOrRecProcedureReturn,
    repOrRecBraceProcedureReturn,
    setRepOrRecBraceProcedureReturn,
    repProcedureReturn,
    setRepProcedureReturn,
    painPrescriptionNeededMaintenanceReturn,
    setPainPrescriptionNeededMaintenanceReturn,
    informedBasicCareMaintenanceReturn,
    setInformedBasicCareMaintenanceReturn,
    setMaterialProcedureMaintenanceReturn,
    setNumberProcedureMaintenanceReturn,
    setOthersProceduresFixedMaintenanceReturn,
    basicCareObservationsMaintenanceReturn,
    setBasicCareObservationsMaintenanceReturn,*/
    odontogramaRepOrRec,
    setOdontogramaRepOrRec,
    showOtherProcedureScreen,
    setShowOtherProcedureScreen,
    referralValue,
    wireProcedures,
    setWireProcedures,
    changeWireProcedures,
    setChangeWireProcedures,
    setMaterialChangeProcedures,
    setNumberChangeProcedures,
    setOthersChangeProcedures,
    keepThreadProcedures,
    setKeepThreadProcedures,
    keepProcedures,
    setKeepProcedures,
    setAtivationProcedures,
    materialChangeProcedures,
    numberChangeProcedures,
    ativationProcedures,
    setValueReferralsProcedures,
    valueReferralsProcedures,
    /*setOtherProceduresValueProcedures,
    otherProceduresValueProcedures,*/
    setReceiveHigh,
    receiveHigh,
    materialReturnInf,
    setMaterialReturnInf,
    setNumberReturnInf,
    numberReturnInf,
    setOthersReturnInf,
    othersReturnInf,
    setMaterialChangeReturnInf,
    materialChangeReturnInf,
    setNumberChangeReturnInf,
    numberChangeReturnInf,
    setOthersChangeReturnInf,
    othersChangeReturnInf,
    setMaterialChangeProceduresInf,
    setNumberChangeProceduresInf,
    setOthersChangeProceduresInf,
    materialChangeProceduresInf,
    numberChangeProceduresInf,
    patientObj,
    allObservationsProcedure,
    setAllObservationsProcedure,
    otherProceduresValueSecondConsult,
    setOtherProceduresValueSecondConsult,
    bracketsNumber,
    setBracketsNumber,
    keepObservation,
    setKeepObservation,
    othersChangeProceduresInf,
    othersChangeProcedures,
  } = useMaintenance();
  const {
    complementary,
    proceduresData,
    referralsData,
    proceduresDataWithFlow,
  } = useAuth();

  const [modalComplement, setModalComplement] = React.useState<boolean>(false);

  const [modalRemote, setModalRemote] = React.useState<boolean>(false);
  const [modalInstall, setModalInstall] = React.useState<boolean>(false);
  const [modalButton, setModalButton] = React.useState<boolean>(false);
  const [modalIntermax, setModalIntermax] = React.useState<boolean>(false);
  const [modalLevant, setModalLevant] = React.useState<boolean>(false);

  /* const [modalRemoteReturn, setModalRemoteReturn] =
    React.useState<boolean>(false);
  const [modalInstallReturn, setModalInstallReturn] =
    React.useState<boolean>(false);
  const [modalButtonReturn, setModalButtonReturn] =
    React.useState<boolean>(false);
  const [modalIntermaxReturn, setModalIntermaxReturn] =
    React.useState<boolean>(false);
  const [modalLevantReturn, setModalLevantReturn] =
    React.useState<boolean>(false);*/

  const [modalCiment, setModalCiment] = React.useState<boolean>(false);
  const [modalCollage, setModalCollage] = React.useState<boolean>(false);
  const [modalPlanningAparat, setModalPlanningAparat] =
    React.useState<boolean>(false);
  const [modalRecOrRep, setModalRecOrRep] = React.useState<boolean>(false);

  const [verifyChange01, setVerifyChange01] = React.useState<boolean>(false);
  const [verifyChange02, setVerifyChange02] = React.useState<boolean>(false);
  const [verifyChange03, setVerifyChange03] = React.useState<boolean>(false);
  const [verifyChange04, setVerifyChange04] = React.useState<boolean>(false);
  const [verifyChange05, setVerifyChange05] = React.useState<boolean>(false);
  const [verifyChange07, setVerifyChange07] = React.useState<boolean>(false);
  const [verifyChange08, setVerifyChange08] = React.useState<boolean>(false);
  const [verifyChange09, setVerifyChange09] = React.useState<boolean>(false);

  const descriptionsWithFlowZero = proceduresData
    .filter((procedure) => procedure.flow === 0)
    .map((procedure) => procedure.label);

  const combinedProcedures = [
    ...otherProceduresValue,
    ...otherProceduresValueSecondConsult,
  ];

  const newArrayValue = combinedProcedures
    .filter((procedure: any) => !descriptionsWithFlowZero.includes(procedure))
    .filter((procedure) => procedure !== "Manutenção");

  const handleContinue = () => {
    if (wireProcedures && changeWireProcedures.length < 1) {
      toast.error("Selecione a Troca de Fio");
      return false;
    }

    if (changeWireProcedures.length > 0) {
      if (changeWireProcedures.includes("SUP")) {
        if (!materialChangeProcedures) {
          toast.error("Selecione o material da Troca de fio Superior");
          return false;
        }

        if (!numberChangeProcedures) {
          toast.error("Selecione o número da Troca de fio Superior");
          return false;
        }
      }

      if (changeWireProcedures.includes("INF")) {
        if (!materialChangeProceduresInf) {
          toast.error("Selecione o material da Troca de fio Inferior");
          return false;
        }

        if (!numberChangeProceduresInf) {
          toast.error("Selecione o número da Troca de fio Inferior");
          return false;
        }
      }
    }

    if (keepThreadProcedures && keepProcedures.length < 1) {
      toast.error("Selecione manter fio");
      return false;
    }

    if (keepThreadProcedures && keepProcedures.length > 0) {
      if (!ativationProcedures) {
        toast.error("Selecione se tem ativação ou não.");
        return false;
      }
    }

    if (painPrescriptionNeeded === "NOT") {
      toast.error("Responda se Precisa de Receita para caso de Dor");
      return false;
    }

    if (informedBasicCare === "NOT") {
      toast.error(
        "Responda se Informou o paciente sobre os cuidados básicos com o aparelho"
      );
      return false;
    }

    /*if (deliveredTreatmentManual === "NOT" && patientObj.aname_id < 1) {
      toast.error("Responda se Entregou o manual do tratamento ortodôntico");
      return false;
    }

    if (advisedWireAndWax === "NOT") {
      toast.error(
        "Responda se Orientou o paciente a solicitar passa fio e cera na recepção"
      );
      return false;
    }*/

    if (repOrRec.length > 0 && repOrRecBrace.length < 1) {
      toast.error("Selecione se é Superior ou Inferior");
      return false;
    }

    if (repOrRec.includes("REP") && bracketsNumber.length < 1) {
      toast.error("Selecione o número de braquetes");
      return false;
    }

    if (
      repOrRec.includes("REP") &&
      repOrRecBrace.length > 0 &&
      odontogramaRepOrRec.length < 1
    ) {
      toast.error("Selecione itens do Odontograma da Reposição");
      return false;
    }

    if (braceType !== "NOT") {
      if (!materialProcedure) {
        toast.error("Selecione o material");
        return false;
      }

      if (!numberProcedure) {
        toast.error("Selecione o número");
        return false;
      }
    }

    if (
      newArrayValue.length > 0 &&
      newArrayValue[0].length > 1 &&
      patientObj.proc_name !== "Emergência"
    ) {
      if (showOtherProcedureScreen) {
        if (currentProcedureIndex < newArrayValue.length - 1) {
          if (otherProceduresValue[currentProcedureIndex] === "Mini Implante") {
            if (realize === "NOT") {
              toast.error("Responda a pergunta e tente novamente");
              return false;
            }
          }

          if (
            otherProceduresValue[currentProcedureIndex] ===
            "Controle de Contenção"
          ) {
            if (realizeContent === "NOT") {
              toast.error("Responda a pergunta e tente novamente");
              return false;
            }
          }

          if (
            otherProceduresValue[currentProcedureIndex] ===
            "Remoção de Aparelho"
          ) {
            if (realizeAparat === "NOT") {
              toast.error("Responda a pergunta e tente novamente");
              return false;
            }
          }

          if (
            otherProceduresValue[currentProcedureIndex] ===
            "Controle de Erupção"
          ) {
            if (realizeEruption === "NOT") {
              toast.error("Responda a pergunta e tente novamente");
              return false;
            }
          }

          if (
            otherProceduresValue[currentProcedureIndex] ===
            "Instalação de elásticos intermaxilares"
          ) {
            if (realizeIntermaxilary === "NOT") {
              toast.error("Responda a pergunta e tente novamente");
              return false;
            }
          }

          if (
            otherProceduresValue[currentProcedureIndex] === "Colagem de Botão"
          ) {
            if (realizeCollage === "NOT") {
              toast.error("Responda a pergunta e tente novamente");
              return false;
            }
          }

          if (
            otherProceduresValue[currentProcedureIndex] ===
            "Recolagem Contenção"
          ) {
            if (realizeRebonding === "NOT") {
              toast.error("Responda a pergunta e tente novamente");
              return false;
            }
          }

          if (
            otherProceduresValue[currentProcedureIndex] === "Levante de Mordida"
          ) {
            if (realizeBite === "NOT") {
              toast.error("Responda a pergunta e tente novamente");
              return false;
            }
          }

          if (otherProceduresValue[currentProcedureIndex] === "Alta") {
            if (realizeRelease === "NOT") {
              toast.error("Responda a pergunta e tente novamente");
              return false;
            }
          }

          if (receiveHigh) {
            setStep(4);
          }

          setCurrentProcedureIndex(currentProcedureIndex + 1);
        } else {
          if (
            patientObj.proc_name === "Emergência" ||
            patientObj.future.scheduling === true
          ) {
            setStep(4);
          } else {
            setStep(2);
          }
        }
      } else {
        setCurrentProcedureIndex(0);
        setShowOtherProcedureScreen(true);
      }
    } else {
      if (
        patientObj.proc_name === "Emergência" ||
        patientObj.future.scheduling === true
      ) {
        setStep(4);
      } else {
        setStep(2);
      }
    }
  };

  /*const continueReturn = () => {
    if (
      newArrayValue.length > 0 &&
      newArrayValue[0].length > 1 &&
      patientObj.proc_name !== "Emergência"
    ) {
      if (showQuestionsReturn) {
        if (currentProcedureIndexReturn < newArrayValue.length - 1) {
          setCurrentProcedureIndexReturn(currentProcedureIndexReturn + 1);
        } else {
          setShowQuestionsReturn(false);
        }
      } else {
        setCurrentProcedureIndexReturn(0);
        setShowQuestionsReturn(true);
      }
    } else {
      setShowQuestionsReturn(false);
    }
  };
*/
  const next = () => {
    if (verifyChange01 && justify01.length < 1) {
      toast.error("Preencha o campo justificativa");
      return false;
    }

    if (verifyChange02 && justify02.length < 1) {
      toast.error("Preencha o campo justificativa");
      return false;
    }

    if (verifyChange03 && justify03.length < 1) {
      toast.error("Preencha o campo justificativa");
      return false;
    }

    if (verifyChange04 && justify04.length < 1) {
      toast.error("Preencha o campo justificativa");
      return false;
    }

    if (verifyChange05 && justify05.length < 1) {
      toast.error("Preencha o campo justificativa");
      return false;
    }

    if (verifyChange07 && justify07.length < 1) {
      toast.error("Preencha o campo justificativa");
      return false;
    }

    if (verifyChange08 && justify08.length < 1) {
      toast.error("Preencha o campo justificativa");
      return false;
    }

    if (verifyChange09 && justify09.length < 1) {
      toast.error("Preencha o campo justificativa");
      return false;
    }

    if (
      (informedBasicCare === "NO" && basicCareObservations === "") ||
      (deliveredTreatmentManual === "NO" &&
        treatmentManualObservations === "") ||
      (advisedWireAndWax === "NO" && wireAndWaxObservations === "")
    ) {
      toast.error("Por favor, preencha todas as observações necessárias.");

      if (informedBasicCare === "NO" && basicCareObservations === "")
        setErrorBasicCare(true);
      if (
        deliveredTreatmentManual === "NO" &&
        treatmentManualObservations === ""
      )
        setErrorTreatmentManual(true);
      if (advisedWireAndWax === "NO" && wireAndWaxObservations === "")
        setErrorWireAndWax(true);
    } else {
      handleContinue();
    }
  };

  const handleReturn = () => {
    if (currentProcedureIndex > 0) {
      setCurrentProcedureIndex(currentProcedureIndex - 1);
    } else {
      setShowOtherProcedureScreen(false);
    }
  };

  /* const handleReturnAttendance = () => {
    if (currentProcedureIndex > 0) {
      setCurrentProcedureIndex(currentProcedureIndex - 1);
    } else {
      setShowQuestionsReturn(false);
    }
  };
*/
  return (
    <Styled.Box>
      {showOtherProcedureScreen && showOtherProcedureScreen ? (
        <>
          <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
            {newArrayValue[currentProcedureIndex]}
          </Typography>

          {newArrayValue[currentProcedureIndex] === "Mini Implante" && (
            <Styled.BoxDescription>
              <Typography variant="h5">
                Foi Realizado o Mini Implante ortodôntico?
              </Typography>
              <Styled.BoxButtons>
                <Button
                  variant={realize === "NO" ? "contained" : "outlined"}
                  onClick={() => setRealize("NO")}
                >
                  NÃO
                </Button>
                <Button
                  variant={realize === "YES" ? "contained" : "outlined"}
                  onClick={() => setRealize("YES")}
                >
                  SIM
                </Button>
              </Styled.BoxButtons>
              {realize === "NO" && (
                <TextField
                  label="Justificativa"
                  fullWidth
                  margin="normal"
                  onChange={(e) => setJustification(e.target.value)}
                />
              )}

              {realize === "YES" && (
                <TextField
                  label="Informe a região onde foi realizado o procedimento"
                  fullWidth
                  margin="normal"
                  onChange={(e) => setInformateRegion(e.target.value)}
                />
              )}
              {realize !== "NOT" && (
                <>
                  <Autocomplete
                    options={proceduresDataWithFlow.map(
                      (option) => option.label
                    )}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !otherProceduresValue.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setOtherProceduresValue((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Outros procedimentos" />
                    )}
                  />

                  <Autocomplete
                    options={referralsData}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !valueReferralsProcedures.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setValueReferralsProcedures((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Encaminhamentos" />
                    )}
                  />
                </>
              )}
            </Styled.BoxDescription>
          )}

          {newArrayValue[currentProcedureIndex] === "Controle de Contenção" && (
            <Styled.BoxDescription>
              <Typography variant="h5">
                Foi Realizado Controle de Contenção?
              </Typography>
              <Styled.BoxButtons>
                <Button
                  variant={realizeContent === "NO" ? "contained" : "outlined"}
                  onClick={() => setRealizeContent("NO")}
                >
                  NÃO
                </Button>
                <Button
                  variant={realizeContent === "YES" ? "contained" : "outlined"}
                  onClick={() => setRealizeContent("YES")}
                >
                  SIM
                </Button>
              </Styled.BoxButtons>
              {realizeContent === "NO" && (
                <TextField
                  label="Justificativa"
                  fullWidth
                  margin="normal"
                  onChange={(e) => setJustificationContent(e.target.value)}
                />
              )}
              {realizeContent === "YES" && (
                <TextField
                  label="Faça o registro do caso"
                  fullWidth
                  margin="normal"
                  onChange={(e) => setInformateRegionContent(e.target.value)}
                />
              )}

              {realizeContent !== "NOT" && (
                <>
                  <Autocomplete
                    options={proceduresDataWithFlow.map(
                      (option) => option.label
                    )}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !otherProceduresValue.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setOtherProceduresValue((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Outros procedimentos" />
                    )}
                  />
                  <Autocomplete
                    options={referralsData}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !valueReferralsProcedures.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setValueReferralsProcedures((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Encaminhamentos" />
                    )}
                  />
                </>
              )}
            </Styled.BoxDescription>
          )}

          {newArrayValue[currentProcedureIndex] === "Remoção de Aparelho" && (
            <Styled.BoxDescription>
              <Typography variant="h5">
                Foi Realizada a Remoção da Aparatologia Ortodôntica?
              </Typography>
              <Styled.BoxButtons>
                <Button
                  variant={realizeAparat === "NO" ? "contained" : "outlined"}
                  onClick={() => setRealizeAparat("NO")}
                >
                  NÃO
                </Button>
                <Button
                  variant={realizeAparat === "YES" ? "contained" : "outlined"}
                  onClick={() => setRealizeAparat("YES")}
                >
                  SIM
                </Button>
              </Styled.BoxButtons>
              {realizeAparat === "NO" && (
                <TextField
                  label="Justificativa"
                  fullWidth
                  margin="normal"
                  onChange={(e) => setJustificationAparat(e.target.value)}
                />
              )}

              {realizeAparat === "YES" && (
                <>
                  <Button
                    sx={{ margin: "20px 0" }}
                    onClick={() => setModalRemote(true)}
                  >
                    Abrir Odontograma
                  </Button>

                  <Modal
                    open={modalRemote}
                    onClose={() => setModalRemote(false)}
                  >
                    <Odontograma
                      onClose={() => setModalRemote(false)}
                      selected={odontogramaRemote}
                      setSelected={setOdontogramaRemote}
                      subtitle="Remoção de Aparatologia Ortodôntica"
                    />
                  </Modal>
                </>
              )}

              {realizeAparat !== "NOT" && (
                <>
                  <Autocomplete
                    options={proceduresDataWithFlow.map(
                      (option) => option.label
                    )}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !otherProceduresValue.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setOtherProceduresValue((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Outros procedimentos" />
                    )}
                  />
                  <Autocomplete
                    options={referralsData}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !valueReferralsProcedures.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setValueReferralsProcedures((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Encaminhamentos" />
                    )}
                  />
                </>
              )}
            </Styled.BoxDescription>
          )}

          {newArrayValue[currentProcedureIndex] === "Controle de Erupção" && (
            <Styled.BoxDescription>
              <Typography variant="h5">
                Foi Realizado Controle de Erupção?
              </Typography>
              <Styled.BoxButtons>
                <Button
                  variant={realizeEruption === "NO" ? "contained" : "outlined"}
                  onClick={() => setRealizeEruption("NO")}
                >
                  NÃO
                </Button>
                <Button
                  variant={realizeEruption === "YES" ? "contained" : "outlined"}
                  onClick={() => setRealizeEruption("YES")}
                >
                  SIM
                </Button>
              </Styled.BoxButtons>
              {realizeEruption === "NO" && (
                <TextField
                  label="Justificativa"
                  fullWidth
                  margin="normal"
                  onChange={(e) => setJustificationEruption(e.target.value)}
                />
              )}
              {realizeEruption === "YES" && (
                <TextField
                  label="Faça o registro do caso"
                  fullWidth
                  margin="normal"
                  onChange={(e) => setInformateRegionEruption(e.target.value)}
                />
              )}

              {realizeEruption !== "NOT" && (
                <>
                  <Autocomplete
                    options={proceduresDataWithFlow.map(
                      (option) => option.label
                    )}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !otherProceduresValue.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setOtherProceduresValue((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Outros procedimentos" />
                    )}
                  />
                  <Autocomplete
                    options={referralsData}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !valueReferralsProcedures.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setValueReferralsProcedures((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Encaminhamentos" />
                    )}
                  />
                </>
              )}
            </Styled.BoxDescription>
          )}

          {newArrayValue[currentProcedureIndex] ===
            "Instalação de elásticos intermaxilares" && (
            <Styled.BoxDescription>
              <Typography variant="h5">Foi Colocado Elástico?</Typography>
              <Styled.BoxButtons>
                <Button
                  variant={
                    realizeIntermaxilary === "NO" ? "contained" : "outlined"
                  }
                  onClick={() => setRealizeIntermaxilary("NO")}
                >
                  NÃO
                </Button>
                <Button
                  variant={
                    realizeIntermaxilary === "YES" ? "contained" : "outlined"
                  }
                  onClick={() => setRealizeIntermaxilary("YES")}
                >
                  SIM
                </Button>
              </Styled.BoxButtons>
              {realizeIntermaxilary === "NO" && (
                <TextField
                  label="Justificativa"
                  fullWidth
                  margin="normal"
                  onChange={(e) =>
                    setJustificationIntermaxilary(e.target.value)
                  }
                />
              )}

              {realizeIntermaxilary === "YES" && (
                <>
                  <Button
                    sx={{ margin: "20px 0" }}
                    onClick={() => setModalInstall(true)}
                  >
                    Abrir Odontograma
                  </Button>

                  <Modal
                    open={modalInstall}
                    onClose={() => setModalInstall(false)}
                  >
                    <Odontograma
                      onClose={() => setModalInstall(false)}
                      selected={odontogramaInstall}
                      setSelected={setOdontogramaInstall}
                      subtitle="Instalação de elásticos intermaxilares"
                    />
                  </Modal>
                </>
              )}

              {realizeIntermaxilary !== "NOT" && (
                <>
                  <Autocomplete
                    options={proceduresDataWithFlow.map(
                      (option) => option.label
                    )}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !otherProceduresValue.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setOtherProceduresValue((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Outros procedimentos" />
                    )}
                  />

                  <Autocomplete
                    options={referralsData}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !valueReferralsProcedures.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setValueReferralsProcedures((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Encaminhamentos" />
                    )}
                  />
                </>
              )}
            </Styled.BoxDescription>
          )}

          {newArrayValue[currentProcedureIndex] === "Colagem de Botão" && (
            <Styled.BoxDescription>
              <Typography variant="h5">Colagem de botão?</Typography>
              <Styled.BoxButtons>
                <Button
                  variant={realizeCollage === "NO" ? "contained" : "outlined"}
                  onClick={() => setRealizeCollage("NO")}
                >
                  NÃO
                </Button>
                <Button
                  variant={realizeCollage === "YES" ? "contained" : "outlined"}
                  onClick={() => setRealizeCollage("YES")}
                >
                  SIM
                </Button>
              </Styled.BoxButtons>
              {realizeCollage === "NO" && (
                <TextField
                  label="Justificativa"
                  fullWidth
                  margin="normal"
                  onChange={(e) => setJustificationCollage(e.target.value)}
                />
              )}

              {realizeCollage === "YES" && (
                <>
                  <Button
                    sx={{ margin: "20px 0" }}
                    onClick={() => setModalButton(true)}
                  >
                    Abrir Odontograma
                  </Button>

                  <Modal
                    open={modalButton}
                    onClose={() => setModalButton(false)}
                  >
                    <Odontograma
                      onClose={() => setModalButton(false)}
                      selected={odontogramaButton}
                      setSelected={setOdontogramaButton}
                      subtitle="Colagem de Botão"
                    />
                  </Modal>
                </>
              )}

              {realizeCollage !== "NOT" && (
                <>
                  <Autocomplete
                    options={proceduresDataWithFlow.map(
                      (option) => option.label
                    )}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !otherProceduresValue.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setOtherProceduresValue((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Outros procedimentos" />
                    )}
                  />

                  <Autocomplete
                    options={referralsData}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !valueReferralsProcedures.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setValueReferralsProcedures((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Encaminhamentos" />
                    )}
                  />
                </>
              )}
            </Styled.BoxDescription>
          )}

          {newArrayValue[currentProcedureIndex] === "Recolagem Contenção" && (
            <Styled.BoxDescription>
              <Typography variant="h5">
                Foi realizada a recolagem de contenção?
              </Typography>
              <Styled.BoxButtons>
                <Button
                  variant={realizeRebonding === "NO" ? "contained" : "outlined"}
                  onClick={() => setRealizeRebonding("NO")}
                >
                  NÃO
                </Button>
                <Button
                  variant={
                    realizeRebonding === "YES" ? "contained" : "outlined"
                  }
                  onClick={() => setRealizeRebonding("YES")}
                >
                  SIM
                </Button>
              </Styled.BoxButtons>
              {realizeRebonding === "NO" && (
                <TextField
                  label="Justificativa"
                  fullWidth
                  margin="normal"
                  onChange={(e) => setJustificationRebonding(e.target.value)}
                />
              )}

              {realizeRebonding === "YES" && (
                <>
                  <Button
                    sx={{ margin: "20px 0" }}
                    onClick={() => setModalIntermax(true)}
                  >
                    Abrir Odontograma
                  </Button>

                  <Modal
                    open={modalIntermax}
                    onClose={() => setModalIntermax(false)}
                  >
                    <Odontograma
                      onClose={() => setModalIntermax(false)}
                      selected={odontogramaIntermax}
                      setSelected={setOdontogramaIntermax}
                      subtitle="Recolagem de Contenção"
                    />
                  </Modal>
                </>
              )}

              {realizeRebonding !== "NOT" && (
                <>
                  <Autocomplete
                    options={proceduresDataWithFlow.map(
                      (option) => option.label
                    )}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !otherProceduresValue.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setOtherProceduresValue((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Outros procedimentos" />
                    )}
                  />

                  <Autocomplete
                    options={referralsData}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !valueReferralsProcedures.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setValueReferralsProcedures((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Encaminhamentos" />
                    )}
                  />
                </>
              )}
            </Styled.BoxDescription>
          )}

          {newArrayValue[currentProcedureIndex] === "Levante de Mordida" && (
            <Styled.BoxDescription>
              <Typography variant="h5">
                Foi realizado levante de mordida?
              </Typography>
              <Styled.BoxButtons>
                <Button
                  variant={realizeBite === "NO" ? "contained" : "outlined"}
                  onClick={() => setRealizeBite("NO")}
                >
                  NÃO
                </Button>
                <Button
                  variant={realizeBite === "YES" ? "contained" : "outlined"}
                  onClick={() => setRealizeBite("YES")}
                >
                  SIM
                </Button>
              </Styled.BoxButtons>
              {realizeBite === "NO" && (
                <TextField
                  label="Justificativa"
                  fullWidth
                  margin="normal"
                  onChange={(e) => setJustificationBite(e.target.value)}
                />
              )}

              {realizeBite === "YES" && (
                <>
                  <Button
                    sx={{ margin: "20px 0" }}
                    onClick={() => setModalLevant(true)}
                  >
                    Abrir Odontograma
                  </Button>

                  <Modal
                    open={modalLevant}
                    onClose={() => setModalLevant(false)}
                  >
                    <Odontograma
                      onClose={() => setModalLevant(false)}
                      selected={odontogramaLevant}
                      setSelected={setOdontogramaLevant}
                      subtitle="Levante de Mordida"
                    />
                  </Modal>
                </>
              )}

              {realizeBite !== "NOT" && (
                <>
                  <Autocomplete
                    options={proceduresDataWithFlow.map(
                      (option) => option.label
                    )}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !otherProceduresValue.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setOtherProceduresValue((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Outros procedimentos" />
                    )}
                  />

                  <Autocomplete
                    options={referralsData}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !valueReferralsProcedures.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setValueReferralsProcedures((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Encaminhamentos" />
                    )}
                  />
                </>
              )}
            </Styled.BoxDescription>
          )}

          {newArrayValue[currentProcedureIndex] === "Alta" && (
            <>
              <Styled.BoxDescription>
                <Typography variant="h5">Paciente recebeu alta?</Typography>
                <Styled.BoxButtons>
                  <Button
                    variant={realizeRelease === "NO" ? "contained" : "outlined"}
                    onClick={() => {
                      setRealizeRelease("NO");
                      setReceiveHigh(false);
                    }}
                  >
                    NÃO
                  </Button>
                  <Button
                    variant={
                      realizeRelease === "YES" ? "contained" : "outlined"
                    }
                    onClick={() => {
                      setRealizeRelease("YES");
                      setReceiveHigh(true);
                    }}
                  >
                    SIM
                  </Button>
                </Styled.BoxButtons>
                {realizeRelease === "NO" && (
                  <TextField
                    label="Justificativa"
                    fullWidth
                    margin="normal"
                    onChange={(e) => setJustificationRelease(e.target.value)}
                  />
                )}
              </Styled.BoxDescription>

              {realizeRelease === "YES" && (
                <Styled.BoxDescription>
                  <Typography variant="h5">
                    Paciente foi encaminhado para a documentação final?
                  </Typography>
                  <Styled.BoxButtons>
                    <Button
                      variant={route === "NO" ? "contained" : "outlined"}
                      onClick={() => setRoute("NO")}
                    >
                      NÃO
                    </Button>
                    <Button
                      variant={route === "YES" ? "contained" : "outlined"}
                      onClick={() => setRoute("YES")}
                    >
                      SIM
                    </Button>
                  </Styled.BoxButtons>
                  {route === "NO" && (
                    <TextField
                      label="Justificativa"
                      fullWidth
                      margin="normal"
                      onChange={(e) => setJustifyRoute(e.target.value)}
                    />
                  )}
                </Styled.BoxDescription>
              )}

              {realizeRelease !== "NOT" && (
                <>
                  <Autocomplete
                    options={proceduresDataWithFlow.map(
                      (option) => option.label
                    )}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !otherProceduresValue.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setOtherProceduresValue((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Outros procedimentos" />
                    )}
                  />

                  <Autocomplete
                    options={referralsData}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !valueReferralsProcedures.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setValueReferralsProcedures((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Encaminhamentos" />
                    )}
                  />
                </>
              )}
            </>
          )}

          <Styled.BtnFixed>
            <Button onClick={handleReturn} fullWidth>
              {currentProcedureIndex === 0 ? "Retornar" : "Anterior"}
            </Button>
            <Button onClick={handleContinue} fullWidth>
              {currentProcedureIndex < newArrayValue.length - 1
                ? "Próximo"
                : "Continuar"}
            </Button>
          </Styled.BtnFixed>
        </>
      ) : (
        <>
          <>
            {questionsPlanning && questionsPlanning.length > 0 ? (
              <>
                {patientObj.proc_name !== "Emergência" && (
                  <Styled.BoxPlanning>
                    <Typography variant="h5">
                      Planejamento da consulta anterior
                    </Typography>

                    {questionsPlanning.filter((item) => item.id === "PPQ001")
                      .length > 0 ? (
                      <Styled.BoxIndication>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={aparateReturn}
                                onClick={() => {
                                  setAparateReturn(!aparateReturn);
                                  setAparateBraceReturn([]); // Reseta o estado ao desmarcar
                                  setVerifyChange01(true);
                                }}
                              />
                            }
                            label="Colagem da Aparatologia Ortodôntica"
                          />
                        </FormGroup>

                        {aparateReturn && (
                          <>
                            <Styled.BoxButtons>
                              <Button
                                variant={
                                  aparateBraceReturn.includes("SUP")
                                    ? "contained"
                                    : "outlined"
                                }
                                onClick={() => {
                                  if (aparateBraceReturn.includes("SUP")) {
                                    setAparateBraceReturn(
                                      aparateBraceReturn.filter(
                                        (type) => type !== "SUP"
                                      )
                                    );
                                  } else {
                                    setAparateBraceReturn([
                                      ...aparateBraceReturn,
                                      "SUP",
                                    ]);
                                  }
                                  setVerifyChange01(true);
                                }}
                              >
                                Superior
                              </Button>
                              <Button
                                variant={
                                  aparateBraceReturn.includes("INF")
                                    ? "contained"
                                    : "outlined"
                                }
                                onClick={() => {
                                  if (aparateBraceReturn.includes("INF")) {
                                    setAparateBraceReturn(
                                      aparateBraceReturn.filter(
                                        (type) => type !== "INF"
                                      )
                                    );
                                  } else {
                                    setAparateBraceReturn([
                                      ...aparateBraceReturn,
                                      "INF",
                                    ]);
                                  }
                                  setVerifyChange01(true);
                                }}
                              >
                                Inferior
                              </Button>
                            </Styled.BoxButtons>

                            {aparateBraceReturn.length > 0 && (
                              <Box
                                display="flex"
                                alignItems="center"
                                flexDirection="column"
                                mt={2}
                              >
                                {aparateBraceReturn.includes("SUP") && (
                                  <>
                                    <Typography>Superior</Typography>
                                    <Box
                                      display="flex"
                                      flexDirection="row"
                                      width="100%"
                                    >
                                      <FormControl fullWidth>
                                        <InputLabel
                                          id="demo-simple-select-label"
                                          sx={{
                                            backgroundColor: "white",
                                            padding: "2px",
                                          }}
                                        >
                                          Material
                                        </InputLabel>
                                        <Select
                                          displayEmpty
                                          variant="outlined"
                                          sx={{ width: "90%" }}
                                          value={materialReturn}
                                          style={{ marginRight: 16 }}
                                          onChange={(e) => {
                                            setMaterialReturn(
                                              e.target.value as string
                                            );
                                            setVerifyChange01(
                                              e.target.value === materialReturn
                                                ? false
                                                : true
                                            );
                                          }}
                                        >
                                          {materialOptions.map((option) => (
                                            <MenuItem
                                              key={option.value}
                                              value={option.value}
                                            >
                                              {option.label}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                      <FormControl fullWidth>
                                        <InputLabel
                                          id="demo-simple-select-label"
                                          sx={{
                                            backgroundColor: "white",
                                            padding: "2px",
                                          }}
                                        >
                                          N°
                                        </InputLabel>
                                        <Select
                                          displayEmpty
                                          variant="outlined"
                                          sx={{ width: "90%" }}
                                          style={{ marginRight: 16 }}
                                          onChange={(e) => {
                                            setNumberReturn(
                                              e.target.value as string
                                            );
                                            setVerifyChange01(
                                              e.target.value === numberReturn
                                                ? false
                                                : true
                                            );
                                          }}
                                          value={numberReturn}
                                        >
                                          {numbers.map((item, index) => (
                                            <MenuItem
                                              key={index}
                                              value={item.value}
                                            >
                                              {item.label}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>

                                      <TextField
                                        label="Outros"
                                        onChange={(e) => {
                                          setOthersReturn(
                                            e.target.value as string
                                          );
                                          setVerifyChange01(true);
                                        }}
                                        value={othersReturn}
                                      />
                                    </Box>
                                  </>
                                )}
                                {aparateBraceReturn.includes("INF") && (
                                  <>
                                    <Typography>Inferior</Typography>
                                    <Box
                                      display="flex"
                                      flexDirection="row"
                                      width="100%"
                                    >
                                      <FormControl fullWidth>
                                        <InputLabel
                                          id="demo-simple-select-label"
                                          sx={{
                                            backgroundColor: "white",
                                            padding: "2px",
                                          }}
                                        >
                                          Material
                                        </InputLabel>
                                        <Select
                                          displayEmpty
                                          variant="outlined"
                                          sx={{ width: "90%" }}
                                          value={materialReturnInf}
                                          style={{ marginRight: 16 }}
                                          onChange={(e) => {
                                            setMaterialReturnInf(
                                              e.target.value as string
                                            );
                                            setVerifyChange01(
                                              e.target.value === materialReturn
                                                ? false
                                                : true
                                            );
                                          }}
                                        >
                                          {materialOptions.map((option) => (
                                            <MenuItem
                                              key={option.value}
                                              value={option.value}
                                            >
                                              {option.label}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                      <FormControl fullWidth>
                                        <InputLabel
                                          id="demo-simple-select-label"
                                          sx={{
                                            backgroundColor: "white",
                                            padding: "2px",
                                          }}
                                        >
                                          N°
                                        </InputLabel>
                                        <Select
                                          displayEmpty
                                          variant="outlined"
                                          sx={{ width: "90%" }}
                                          style={{ marginRight: 16 }}
                                          onChange={(e) => {
                                            setNumberReturnInf(
                                              e.target.value as string
                                            );
                                            setVerifyChange01(
                                              e.target.value === numberReturn
                                                ? false
                                                : true
                                            );
                                          }}
                                          value={numberReturnInf}
                                        >
                                          {numbers.map((item, index) => (
                                            <MenuItem
                                              key={index}
                                              value={item.value}
                                            >
                                              {item.label}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>

                                      <TextField
                                        label="Outros"
                                        onChange={(e) => {
                                          setOthersReturnInf(
                                            e.target.value as string
                                          );
                                          setVerifyChange01(true);
                                        }}
                                        value={othersReturnInf}
                                      />
                                    </Box>
                                  </>
                                )}

                                <Button
                                  sx={{ margin: "20px 0" }}
                                  onClick={() => {
                                    setModalPlanningAparat(true);
                                  }}
                                  fullWidth
                                >
                                  Abrir Odontograma
                                </Button>

                                <Modal
                                  open={modalPlanningAparat}
                                  onClose={() => setModalPlanningAparat(false)}
                                >
                                  <Odontograma
                                    onClose={() =>
                                      setModalPlanningAparat(false)
                                    }
                                    selected={odontogramaPlanningAparatReturn}
                                    setSelected={
                                      setOdontogramaPlanningAparatReturn
                                    }
                                    subtitle="Colagem de aparatologia ortodôntica - Planejamento"
                                  />
                                </Modal>
                              </Box>
                            )}
                          </>
                        )}

                        {verifyChange01 && (
                          <TextField
                            label="Justifique a mudança"
                            onChange={(e) => setJustify01(e.target.value)}
                            error={verifyChange01 && justify01.length < 1}
                            helperText={
                              verifyChange01 && justify01.length < 1
                                ? "Justifique sua alteração"
                                : ""
                            }
                            sx={{ marginTop: "20px" }}
                          />
                        )}
                      </Styled.BoxIndication>
                    ) : (
                      ""
                    )}

                    {questionsPlanning.filter((item) => item.id === "PPQ002")
                      .length > 0 ? (
                      <Styled.BoxIndication>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={wireReturn}
                                onClick={() => {
                                  setWireReturn(!wireReturn);
                                  setChangeWireReturn([]); // Reseta o estado ao desmarcar
                                  setVerifyChange02(true);
                                }}
                              />
                            }
                            label="Trocar Fio"
                          />
                        </FormGroup>

                        {wireReturn && (
                          <>
                            <Styled.BoxButtons>
                              <Button
                                variant={
                                  changeWireReturn.includes("SUP")
                                    ? "contained"
                                    : "outlined"
                                }
                                onClick={() => {
                                  if (changeWireReturn.includes("SUP")) {
                                    setChangeWireReturn(
                                      changeWireReturn.filter(
                                        (type) => type !== "SUP"
                                      )
                                    );
                                  } else {
                                    setChangeWireReturn([
                                      ...changeWireReturn,
                                      "SUP",
                                    ]);
                                  }
                                  setVerifyChange02(true);
                                }}
                              >
                                Superior
                              </Button>
                              <Button
                                variant={
                                  changeWireReturn.includes("INF")
                                    ? "contained"
                                    : "outlined"
                                }
                                onClick={() => {
                                  if (changeWireReturn.includes("INF")) {
                                    setChangeWireReturn(
                                      changeWireReturn.filter(
                                        (type) => type !== "INF"
                                      )
                                    );
                                  } else {
                                    setChangeWireReturn([
                                      ...changeWireReturn,
                                      "INF",
                                    ]);
                                  }
                                  setVerifyChange02(true);
                                }}
                              >
                                Inferior
                              </Button>
                            </Styled.BoxButtons>

                            {changeWireReturn.length > 0 && (
                              <>
                                {changeWireReturn.includes("SUP") && (
                                  <>
                                    <Typography>Superior</Typography>
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      mt={2}
                                    >
                                      <FormControl fullWidth>
                                        <InputLabel
                                          id="demo-simple-select-label"
                                          sx={{
                                            backgroundColor: "white",
                                            padding: "2px",
                                          }}
                                        >
                                          Material
                                        </InputLabel>
                                        <Select
                                          displayEmpty
                                          variant="outlined"
                                          sx={{ width: "90%" }}
                                          style={{ marginRight: 16 }}
                                          value={materialChangeReturn}
                                          onChange={(e) => {
                                            setMaterialChangeReturn(
                                              e.target.value as string
                                            );
                                            setVerifyChange02(
                                              e.target.value ===
                                                materialChangeReturn
                                                ? false
                                                : true
                                            );
                                          }}
                                        >
                                          {materialOptions.map((option) => (
                                            <MenuItem
                                              key={option.value}
                                              value={option.value}
                                            >
                                              {option.label}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                      <FormControl fullWidth>
                                        <InputLabel
                                          id="demo-simple-select-label"
                                          sx={{
                                            backgroundColor: "white",
                                            padding: "2px",
                                          }}
                                        >
                                          N°
                                        </InputLabel>
                                        <Select
                                          displayEmpty
                                          variant="outlined"
                                          sx={{ width: "90%" }}
                                          style={{ marginRight: 16 }}
                                          onChange={(e) => {
                                            setNumberChangeReturn(
                                              e.target.value as string
                                            );
                                            setVerifyChange02(
                                              e.target.value ===
                                                numberChangeReturn
                                                ? false
                                                : true
                                            );
                                          }}
                                          value={numberChangeReturn}
                                        >
                                          {numbers.map((item, index) => (
                                            <MenuItem
                                              key={index}
                                              value={item.value}
                                            >
                                              {item.label}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>

                                      <TextField
                                        label="Outros"
                                        onChange={(e) => {
                                          setOthersChangeReturn(
                                            e.target.value as string
                                          );
                                          setVerifyChange02(true);
                                        }}
                                        value={othersChangeReturn}
                                      />
                                    </Box>
                                  </>
                                )}

                                {changeWireReturn.includes("INF") && (
                                  <>
                                    <Typography>Inferior</Typography>
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      mt={2}
                                    >
                                      <FormControl fullWidth>
                                        <InputLabel
                                          id="demo-simple-select-label"
                                          sx={{
                                            backgroundColor: "white",
                                            padding: "2px",
                                          }}
                                        >
                                          Material
                                        </InputLabel>
                                        <Select
                                          displayEmpty
                                          variant="outlined"
                                          sx={{ width: "90%" }}
                                          style={{ marginRight: 16 }}
                                          onChange={(e) => {
                                            setMaterialChangeReturnInf(
                                              e.target.value as string
                                            );
                                            setVerifyChange02(
                                              e.target.value ===
                                                materialChangeReturn
                                                ? false
                                                : true
                                            );
                                          }}
                                          value={materialChangeReturnInf}
                                        >
                                          {materialOptions.map((option) => (
                                            <MenuItem
                                              key={option.value}
                                              value={option.value}
                                            >
                                              {option.label}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                      <FormControl fullWidth>
                                        <InputLabel
                                          id="demo-simple-select-label"
                                          sx={{
                                            backgroundColor: "white",
                                            padding: "2px",
                                          }}
                                        >
                                          N°
                                        </InputLabel>
                                        <Select
                                          displayEmpty
                                          variant="outlined"
                                          sx={{ width: "90%" }}
                                          style={{ marginRight: 16 }}
                                          onChange={(e) => {
                                            setNumberChangeReturnInf(
                                              e.target.value as string
                                            );
                                            setVerifyChange02(
                                              e.target.value ===
                                                numberChangeReturn
                                                ? false
                                                : true
                                            );
                                          }}
                                          value={numberChangeReturnInf}
                                        >
                                          {numbers.map((item, index) => (
                                            <MenuItem
                                              key={index}
                                              value={item.value}
                                            >
                                              {item.label}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>

                                      <TextField
                                        label="Outros"
                                        onChange={(e) => {
                                          setOthersChangeReturnInf(
                                            e.target.value as string
                                          );
                                          setVerifyChange02(true);
                                        }}
                                        value={othersChangeReturnInf}
                                      />
                                    </Box>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}

                        {verifyChange02 && (
                          <TextField
                            label="Justifique a mudança"
                            onChange={(e) => setJustify02(e.target.value)}
                            sx={{ marginTop: "20px" }}
                            error={verifyChange02 && justify02.length < 1}
                            helperText={
                              verifyChange02 && justify02.length < 1
                                ? "Justifique sua alteração"
                                : ""
                            }
                          />
                        )}
                      </Styled.BoxIndication>
                    ) : (
                      ""
                    )}

                    {questionsPlanning.filter((item) => item.id === "PPQ003")
                      .length > 0 ? (
                      <Styled.BoxIndication>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={keepThreadReturn}
                                onClick={() => {
                                  setKeepThreadReturn(!keepThreadReturn);
                                  setKeepReturn([]); // Reseta o estado ao desmarcar
                                  setVerifyChange03(true);
                                }}
                              />
                            }
                            label="Manter Fio"
                          />
                        </FormGroup>

                        {keepThreadReturn && (
                          <>
                            <Styled.BoxButtons>
                              <Button
                                variant={
                                  keepReturn.includes("SUP")
                                    ? "contained"
                                    : "outlined"
                                }
                                onClick={() => {
                                  if (keepReturn.includes("SUP")) {
                                    setKeepReturn(
                                      keepReturn.filter(
                                        (type) => type !== "SUP"
                                      )
                                    );
                                  } else {
                                    setKeepReturn([...keepReturn, "SUP"]);
                                  }
                                  setVerifyChange03(true);
                                }}
                              >
                                Superior
                              </Button>
                              <Button
                                variant={
                                  keepReturn.includes("INF")
                                    ? "contained"
                                    : "outlined"
                                }
                                onClick={() => {
                                  if (keepReturn.includes("INF")) {
                                    setKeepReturn(
                                      keepReturn.filter(
                                        (type) => type !== "INF"
                                      )
                                    );
                                  } else {
                                    setKeepReturn([...keepReturn, "INF"]);
                                  }
                                  setVerifyChange03(true);
                                }}
                              >
                                Inferior
                              </Button>
                            </Styled.BoxButtons>

                            {keepReturn.length > 0 && (
                              <Box display="flex" alignItems="center" mt={2}>
                                <FormControl>
                                  <RadioGroup
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                    onChange={(e) => {
                                      setAtivationReturn(
                                        e.target.value as string
                                      );
                                      setVerifyChange03(
                                        e.target.value === ativationReturn
                                          ? false
                                          : true
                                      );
                                    }}
                                    value={ativationReturn}
                                  >
                                    <FormControlLabel
                                      value="yes"
                                      control={<Radio />}
                                      label="Com ativação"
                                    />
                                    <FormControlLabel
                                      value="no"
                                      control={<Radio />}
                                      label="Sem ativação"
                                    />
                                  </RadioGroup>
                                </FormControl>

                                <TextField
                                  label="Outros"
                                  onChange={(e) => {
                                    setOthersReturn(e.target.value as string);
                                    setVerifyChange03(true);
                                  }}
                                  value={othersReturn}
                                />
                              </Box>
                            )}
                          </>
                        )}

                        {verifyChange03 && (
                          <TextField
                            label="Justifique a mudança"
                            onChange={(e) => setJustify03(e.target.value)}
                            sx={{ marginTop: "20px" }}
                            error={verifyChange03 && justify03.length < 1}
                            helperText={
                              verifyChange03 && justify03.length < 1
                                ? "Justifique sua alteração"
                                : ""
                            }
                          />
                        )}
                      </Styled.BoxIndication>
                    ) : (
                      ""
                    )}

                    {questionsPlanning.filter((item) => item.id === "PPQ004")
                      .length > 0 ? (
                      <Styled.BoxIndication>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={cimentReturn}
                                onClick={() => {
                                  setCimentReturn(!cimentReturn);
                                  setCimentBandReturn([]); // Reseta o estado ao desmarcar
                                  setVerifyChange04(true);
                                }}
                              />
                            }
                            label="Cimentação de banda"
                          />
                        </FormGroup>

                        {cimentReturn && (
                          <>
                            <Styled.BoxButtons>
                              <Button
                                variant={
                                  cimentBandReturn.includes("SUP")
                                    ? "contained"
                                    : "outlined"
                                }
                                onClick={() => {
                                  if (cimentBandReturn.includes("SUP")) {
                                    setCimentBandReturn(
                                      cimentBandReturn.filter(
                                        (type) => type !== "SUP"
                                      )
                                    );
                                  } else {
                                    setCimentBandReturn([
                                      ...cimentBandReturn,
                                      "SUP",
                                    ]);
                                  }
                                  setVerifyChange04(true);
                                }}
                              >
                                Superior
                              </Button>
                              <Button
                                variant={
                                  cimentBandReturn.includes("INF")
                                    ? "contained"
                                    : "outlined"
                                }
                                onClick={() => {
                                  if (cimentBandReturn.includes("INF")) {
                                    setCimentBandReturn(
                                      cimentBandReturn.filter(
                                        (type) => type !== "INF"
                                      )
                                    );
                                  } else {
                                    setCimentBandReturn([
                                      ...cimentBandReturn,
                                      "INF",
                                    ]);
                                  }
                                  setVerifyChange04(true);
                                }}
                              >
                                Inferior
                              </Button>
                            </Styled.BoxButtons>

                            {cimentBandReturn.length > 0 && (
                              <Button
                                onClick={() => {
                                  setModalCiment(true);
                                }}
                                sx={{ marginTop: "10px" }}
                              >
                                Abrir Odontograma
                              </Button>
                            )}

                            <Modal
                              open={modalCiment}
                              onClose={() => setModalCiment(false)}
                            >
                              <Odontograma
                                onClose={() => setModalCiment(false)}
                                selected={odontogramaCimentReturn}
                                setSelected={setOdontogramaCimentReturn}
                                subtitle="Cimentação de banda"
                              />
                            </Modal>
                          </>
                        )}

                        {verifyChange04 && (
                          <TextField
                            label="Justifique a mudança"
                            onChange={(e) => setJustify04(e.target.value)}
                            sx={{ marginTop: "20px" }}
                            error={verifyChange04 && justify04.length < 1}
                            helperText={
                              verifyChange04 && justify04.length < 1
                                ? "Justifique sua alteração"
                                : ""
                            }
                          />
                        )}
                      </Styled.BoxIndication>
                    ) : (
                      ""
                    )}

                    {questionsPlanning.filter((item) => item.id === "PPQ005")
                      .length > 0 ? (
                      <Styled.BoxIndication>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={glueReturn}
                                onClick={() => {
                                  setGlueReturn(!glueReturn);
                                  setGlueTubReturn([]); // Reseta o estado ao desmarcar
                                  setVerifyChange05(true);
                                }}
                              />
                            }
                            label="Colagem do tubo"
                          />
                        </FormGroup>

                        {glueReturn && (
                          <>
                            <Styled.BoxButtons>
                              <Button
                                variant={
                                  glueTubReturn.includes("SUP")
                                    ? "contained"
                                    : "outlined"
                                }
                                onClick={() => {
                                  if (glueTubReturn.includes("SUP")) {
                                    setGlueTubReturn(
                                      glueTubReturn.filter(
                                        (type) => type !== "SUP"
                                      )
                                    );
                                  } else {
                                    setGlueTubReturn([...glueTubReturn, "SUP"]);
                                  }
                                  setVerifyChange05(true);
                                }}
                              >
                                Superior
                              </Button>
                              <Button
                                variant={
                                  glueTubReturn.includes("INF")
                                    ? "contained"
                                    : "outlined"
                                }
                                onClick={() => {
                                  if (glueTubReturn.includes("INF")) {
                                    setGlueTubReturn(
                                      glueTubReturn.filter(
                                        (type) => type !== "INF"
                                      )
                                    );
                                  } else {
                                    setGlueTubReturn([...glueTubReturn, "INF"]);
                                  }
                                  setVerifyChange05(true);
                                }}
                              >
                                Inferior
                              </Button>
                            </Styled.BoxButtons>

                            {glueTubReturn.length > 0 && (
                              <Button
                                onClick={() => {
                                  setModalCollage(true);
                                }}
                                sx={{ marginTop: "10px" }}
                              >
                                Abrir Odontograma
                              </Button>
                            )}

                            <Modal
                              open={modalCollage}
                              onClose={() => setModalCollage(false)}
                            >
                              <Odontograma
                                onClose={() => setModalCollage(false)}
                                selected={odontogramaCollageReturn}
                                setSelected={setOdontogramaCollageReturn}
                                subtitle="Colagem do tubo"
                              />
                            </Modal>
                          </>
                        )}

                        {verifyChange05 && (
                          <TextField
                            label="Justifique a mudança"
                            onChange={(e) => setJustify05(e.target.value)}
                            sx={{ marginTop: "20px" }}
                            error={verifyChange05 && justify05.length < 1}
                            helperText={
                              verifyChange05 && justify05.length < 1
                                ? "Justifique sua alteração"
                                : ""
                            }
                          />
                        )}
                      </Styled.BoxIndication>
                    ) : (
                      ""
                    )}

                    {questionsPlanning.filter((item) => item.id === "PPQ007")
                      .length > 0 ? (
                      <Styled.BoxIndication>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={complementReturn}
                                onClick={() => {
                                  setComplementReturn(!complementReturn);
                                  setCompReturn([]);
                                  setVerifyChange07(true);
                                }}
                              />
                            }
                            label="Aparelho Complementar"
                          />
                        </FormGroup>

                        {complementReturn && (
                          <>
                            <Autocomplete
                              options={complementary}
                              multiple
                              sx={{ margin: "10px 0" }}
                              onChange={(event, newValue) => {
                                setCompReturn(newValue);
                                setVerifyChange07(true);
                              }}
                              value={compReturn}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Aparelho complementar"
                                />
                              )}
                            />

                            {compReturn.length > 0 && (
                              <Button
                                onClick={() => {
                                  setModalComplement(true);
                                  setVerifyChange07(true);
                                }}
                              >
                                Abrir Odontograma
                              </Button>
                            )}

                            <Modal
                              open={modalComplement}
                              onClose={() => setModalComplement(false)}
                            >
                              <Odontograma
                                onClose={() => setModalComplement(false)}
                                selected={odontogramaComplementReturn}
                                setSelected={setOdontogramaComplementReturn}
                                subtitle="Aparelho Complementar"
                              />
                            </Modal>
                          </>
                        )}
                        {verifyChange07 && (
                          <TextField
                            label="Justifique a mudança"
                            onChange={(e) => setJustify07(e.target.value)}
                            sx={{ marginTop: "20px" }}
                            error={verifyChange07 && justify07.length < 1}
                            helperText={
                              verifyChange07 && justify07.length < 1
                                ? "Justifique sua alteração"
                                : ""
                            }
                          />
                        )}
                      </Styled.BoxIndication>
                    ) : (
                      ""
                    )}

                    {questionsPlanning.filter((item) => item.id === "PPQ008")
                      .length > 0 ? (
                      <Styled.BoxIndication>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={othersProceduresReturn}
                                onClick={() => {
                                  setOthersProceduresReturn(
                                    !othersProceduresReturn
                                  );
                                  setOtherProceduresValue([]);
                                  setVerifyChange08(true);
                                }}
                              />
                            }
                            label="Outros procedimentos"
                          />
                        </FormGroup>

                        {othersProceduresReturn && (
                          <>
                            <Autocomplete
                              options={proceduresData.map(
                                (option) => option.label
                              )}
                              multiple
                              sx={{ margin: "10px 0" }}
                              onChange={(event, newValue) => {
                                setOtherProceduresValue(newValue);
                                setVerifyChange08(true);
                              }}
                              value={otherProceduresValue}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Outros procedimentos"
                                />
                              )}
                            />
                          </>
                        )}
                        {verifyChange08 && (
                          <TextField
                            label="Justifique a mudança"
                            onChange={(e) => setJustify08(e.target.value)}
                            sx={{ marginTop: "20px" }}
                            error={verifyChange08 && justify08.length < 1}
                            helperText={
                              verifyChange08 && justify08.length < 1
                                ? "Justifique sua alteração"
                                : ""
                            }
                          />
                        )}
                      </Styled.BoxIndication>
                    ) : (
                      ""
                    )}

                    {questionsPlanning.filter((item) => item.id === "PPQ009")
                      .length > 0 ? (
                      <Styled.BoxIndication>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={othersReferralsReturn}
                                onClick={() => {
                                  setOthersReferralsReturn(
                                    !othersReferralsReturn
                                  );
                                  setVerifyChange09(true);
                                }}
                              />
                            }
                            label="Encaminhamento"
                          />
                        </FormGroup>

                        {othersReferralsReturn && (
                          <>
                            <Autocomplete
                              options={referralOptions}
                              multiple
                              sx={{ margin: "10px 0" }}
                              onChange={(event, newValue) => {
                                setValueEncReturn(newValue);
                                setVerifyChange09(true);
                              }}
                              value={valueEncReturn}
                              renderInput={(params) => (
                                <TextField {...params} label="Encaminhamento" />
                              )}
                            />
                          </>
                        )}
                        {verifyChange09 && (
                          <TextField
                            label="Justifique a mudança"
                            onChange={(e) => setJustify09(e.target.value)}
                            sx={{ marginTop: "20px" }}
                            error={verifyChange09 && justify09.length < 1}
                            helperText={
                              verifyChange09 && justify09.length < 1
                                ? "Justifique sua alteração"
                                : ""
                            }
                          />
                        )}
                      </Styled.BoxIndication>
                    ) : (
                      ""
                    )}
                  </Styled.BoxPlanning>
                )}
              </>
            ) : (
              ""
            )}
          </>

          <Styled.BoxIndication>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={wireProcedures}
                    onClick={() => {
                      setWireProcedures(!wireProcedures);
                      setChangeWireProcedures([]); // Reseta o estado ao desmarcar
                    }}
                  />
                }
                label="Trocar Fio"
              />
            </FormGroup>

            {wireProcedures && (
              <>
                <Styled.BoxButtons>
                  <Button
                    variant={
                      changeWireProcedures.includes("SUP")
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => {
                      if (changeWireProcedures.includes("SUP")) {
                        setChangeWireProcedures(
                          changeWireProcedures.filter((type) => type !== "SUP")
                        );
                      } else {
                        setChangeWireProcedures([
                          ...changeWireProcedures,
                          "SUP",
                        ]);
                      }
                    }}
                    sx={{
                      "&:hover": {
                        backgroundColor: changeWireProcedures.includes("SUP")
                          ? "#007DED"
                          : "white",
                        color: changeWireProcedures.includes("SUP")
                          ? "white"
                          : "#007DED",
                        borderColor: changeWireProcedures.includes("SUP")
                          ? "#007DED"
                          : "#EEEFF1",
                      },
                    }}
                  >
                    Superior
                  </Button>
                  <Button
                    variant={
                      changeWireProcedures.includes("INF")
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => {
                      if (changeWireProcedures.includes("INF")) {
                        setChangeWireProcedures(
                          changeWireProcedures.filter((type) => type !== "INF")
                        );
                      } else {
                        setChangeWireProcedures([
                          ...changeWireProcedures,
                          "INF",
                        ]);
                      }
                    }}
                    sx={{
                      "&:hover": {
                        backgroundColor: changeWireProcedures.includes("INF")
                          ? "#007DED"
                          : "white",
                        color: changeWireProcedures.includes("INF")
                          ? "white"
                          : "#007DED",
                        borderColor: changeWireProcedures.includes("INF")
                          ? "#007DED"
                          : "#EEEFF1",
                      },
                    }}
                  >
                    Inferior
                  </Button>
                </Styled.BoxButtons>

                {changeWireProcedures.length > 0 && (
                  <>
                    {changeWireProcedures.includes("SUP") && (
                      <>
                        <Typography mt={2}>Superior</Typography>
                        <Box display="flex" alignItems="center">
                          <FormControl fullWidth>
                            <InputLabel
                              id="demo-simple-select-label"
                              sx={{
                                backgroundColor: "white",
                                padding: "2px",
                              }}
                            >
                              Material
                            </InputLabel>
                            <Select
                              displayEmpty
                              variant="outlined"
                              sx={{ width: "90%" }}
                              style={{ marginRight: 16 }}
                              onChange={(e) =>
                                setMaterialChangeProcedures(
                                  e.target.value as string
                                )
                              }
                              value={materialChangeProcedures}
                            >
                              {materialOptions.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl fullWidth>
                            <InputLabel
                              id="demo-simple-select-label"
                              sx={{
                                backgroundColor: "white",
                                padding: "2px",
                              }}
                            >
                              N°
                            </InputLabel>
                            <Select
                              displayEmpty
                              variant="outlined"
                              sx={{ width: "90%" }}
                              style={{ marginRight: 16 }}
                              onChange={(e) =>
                                setNumberChangeProcedures(
                                  e.target.value as string
                                )
                              }
                              value={numberChangeProcedures}
                            >
                              {numbers.map((item, index) => (
                                <MenuItem key={index} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          <TextField
                            label="Outros"
                            onChange={(e) =>
                              setOthersChangeProcedures(
                                e.target.value as string
                              )
                            }
                            value={othersChangeProcedures}
                          />
                        </Box>
                      </>
                    )}

                    {changeWireProcedures.includes("INF") && (
                      <>
                        <Typography mt={2}>Inferior</Typography>
                        <Box display="flex" alignItems="center">
                          <FormControl fullWidth>
                            <InputLabel
                              id="demo-simple-select-label"
                              sx={{
                                backgroundColor: "white",
                                padding: "2px",
                              }}
                            >
                              Material
                            </InputLabel>
                            <Select
                              displayEmpty
                              variant="outlined"
                              sx={{ width: "90%" }}
                              style={{ marginRight: 16 }}
                              onChange={(e) =>
                                setMaterialChangeProceduresInf(
                                  e.target.value as string
                                )
                              }
                              value={materialChangeProceduresInf}
                            >
                              {materialOptions.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl fullWidth>
                            <InputLabel
                              id="demo-simple-select-label"
                              sx={{
                                backgroundColor: "white",
                                padding: "2px",
                              }}
                            >
                              N°
                            </InputLabel>
                            <Select
                              displayEmpty
                              variant="outlined"
                              sx={{ width: "90%" }}
                              style={{ marginRight: 16 }}
                              onChange={(e) =>
                                setNumberChangeProceduresInf(
                                  e.target.value as string
                                )
                              }
                              value={numberChangeProceduresInf}
                            >
                              {numbers.map((item, index) => (
                                <MenuItem key={index} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          <TextField
                            label="Outros"
                            onChange={(e) =>
                              setOthersChangeProceduresInf(
                                e.target.value as string
                              )
                            }
                            value={othersChangeProceduresInf}
                          />
                        </Box>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </Styled.BoxIndication>

          <Styled.BoxIndication>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={keepThreadProcedures}
                    onClick={() => {
                      setKeepThreadProcedures(!keepThreadProcedures);
                      setKeepProcedures([]); // Reseta o estado ao desmarcar
                    }}
                  />
                }
                label="Manter Fio"
              />
            </FormGroup>

            {keepThreadProcedures && (
              <>
                <Styled.BoxButtons>
                  <Button
                    variant={
                      keepProcedures.includes("SUP") ? "contained" : "outlined"
                    }
                    onClick={() => {
                      if (keepProcedures.includes("SUP")) {
                        setKeepProcedures(
                          keepProcedures.filter((type) => type !== "SUP")
                        );
                      } else {
                        setKeepProcedures([...keepProcedures, "SUP"]);
                      }
                    }}
                    sx={{
                      "&:hover": {
                        backgroundColor: keepProcedures.includes("SUP")
                          ? "#007DED"
                          : "white",
                        color: keepProcedures.includes("SUP")
                          ? "white"
                          : "#007DED",
                        borderColor: keepProcedures.includes("SUP")
                          ? "#007DED"
                          : "#EEEFF1",
                      },
                    }}
                  >
                    Superior
                  </Button>
                  <Button
                    variant={
                      keepProcedures.includes("INF") ? "contained" : "outlined"
                    }
                    onClick={() => {
                      if (keepProcedures.includes("INF")) {
                        setKeepProcedures(
                          keepProcedures.filter((type) => type !== "INF")
                        );
                      } else {
                        setKeepProcedures([...keepProcedures, "INF"]);
                      }
                    }}
                    sx={{
                      "&:hover": {
                        backgroundColor: keepProcedures.includes("INF")
                          ? "#007DED"
                          : "white",
                        color: keepProcedures.includes("INF")
                          ? "white"
                          : "#007DED",
                        borderColor: keepProcedures.includes("INF")
                          ? "#007DED"
                          : "#EEEFF1",
                      },
                    }}
                  >
                    Inferior
                  </Button>
                </Styled.BoxButtons>

                {keepProcedures.length > 0 && (
                  <Box display="flex" alignItems="center" mt={2}>
                    <FormControl>
                      <RadioGroup
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                        onChange={(e) =>
                          setAtivationProcedures(e.target.value as string)
                        }
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio />}
                          label="Com ativação"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="Sem ativação"
                        />
                      </RadioGroup>
                    </FormControl>

                    <TextField
                      label="Outros"
                      value={keepObservation}
                      onChange={(e) => setKeepObservation(e.target.value)}
                    />
                  </Box>
                )}
              </>
            )}
          </Styled.BoxIndication>

          <Styled.BoxIndication>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rep}
                    onClick={() => {
                      setRep(!rep);
                      setRepOrRec([""]);
                      setRepOrRecBrace([""]);
                      setBracketsNumber("");
                    }}
                  />
                }
                label="Reposição ou Recolagem de Braquete"
              />
            </FormGroup>
            {rep && (
              <>
                <Styled.BoxButtons>
                  <Button
                    variant={
                      repOrRec.includes("REP") ? "contained" : "outlined"
                    }
                    onClick={() => {
                      if (repOrRec.includes("REP")) {
                        setRepOrRec(repOrRec.filter((type) => type !== "REP"));
                      } else {
                        setRepOrRec([...repOrRec, "REP"]);
                      }
                    }}
                    sx={{
                      "&:hover": {
                        backgroundColor: repOrRec.includes("REP")
                          ? "#007DED"
                          : "white",
                        color: repOrRec.includes("REP") ? "white" : "#007DED",
                        borderColor: repOrRec.includes("REP")
                          ? "#007DED"
                          : "#EEEFF1",
                      },
                    }}
                  >
                    Reposição
                  </Button>
                  <Button
                    variant={
                      repOrRec.includes("REC") ? "contained" : "outlined"
                    }
                    onClick={() => {
                      if (repOrRec.includes("REC")) {
                        setRepOrRec(repOrRec.filter((type) => type !== "REC"));
                      } else {
                        setRepOrRec([...repOrRec, "REC"]);
                      }
                    }}
                    sx={{
                      "&:hover": {
                        backgroundColor: repOrRec.includes("REC")
                          ? "#007DED"
                          : "white",
                        color: repOrRec.includes("REC") ? "white" : "#007DED",
                        borderColor: repOrRec.includes("REC")
                          ? "#007DED"
                          : "#EEEFF1",
                      },
                    }}
                  >
                    Recolagem
                  </Button>
                </Styled.BoxButtons>

                {(repOrRec.includes("REP") || repOrRec.includes("REC")) && (
                  <Styled.BoxButtons style={{ marginTop: "15px" }}>
                    <Button
                      variant={
                        repOrRecBrace.includes("SUP") ? "contained" : "outlined"
                      }
                      onClick={() => {
                        if (repOrRecBrace.includes("SUP")) {
                          setRepOrRecBrace(
                            repOrRecBrace.filter((type) => type !== "SUP")
                          );
                        } else {
                          setRepOrRecBrace([...repOrRecBrace, "SUP"]);
                        }
                      }}
                      sx={{
                        "&:hover": {
                          backgroundColor: repOrRecBrace.includes("SUP")
                            ? "#007DED"
                            : "white",
                          color: repOrRecBrace.includes("SUP")
                            ? "white"
                            : "#007DED",
                          borderColor: repOrRecBrace.includes("SUP")
                            ? "#007DED"
                            : "#EEEFF1",
                        },
                      }}
                    >
                      Superior
                    </Button>
                    <Button
                      variant={
                        repOrRecBrace.includes("INF") ? "contained" : "outlined"
                      }
                      onClick={() => {
                        if (repOrRecBrace.includes("INF")) {
                          setRepOrRecBrace(
                            repOrRecBrace.filter((type) => type !== "INF")
                          );
                        } else {
                          setRepOrRecBrace([...repOrRecBrace, "INF"]);
                        }
                      }}
                      sx={{
                        "&:hover": {
                          backgroundColor: repOrRecBrace.includes("INF")
                            ? "#007DED"
                            : "white",
                          color: repOrRecBrace.includes("INF")
                            ? "white"
                            : "#007DED",
                          borderColor: repOrRecBrace.includes("INF")
                            ? "#007DED"
                            : "#EEEFF1",
                        },
                      }}
                    >
                      Inferior
                    </Button>
                  </Styled.BoxButtons>
                )}

                {(repOrRecBrace.includes("SUP") ||
                  repOrRecBrace.includes("INF")) && (
                  <>
                    <Button
                      onClick={() => setModalRecOrRep(true)}
                      sx={{ marginTop: "10px" }}
                    >
                      Abrir Odontogama
                    </Button>
                    <Modal
                      open={modalRecOrRep}
                      onClose={() => setModalRecOrRep(false)}
                    >
                      <Odontograma
                        onClose={() => setModalRecOrRep(false)}
                        selected={odontogramaRepOrRec}
                        setSelected={setOdontogramaRepOrRec}
                        subtitle="Reposição ou Recolagem"
                      />
                    </Modal>
                  </>
                )}
              </>
            )}
          </Styled.BoxIndication>

          {repOrRec.includes("REP") && (
            <Styled.BoxIndication>
              <Typography
                variant="h5"
                sx={{ marginTop: "5px", marginBottom: "10px" }}
              >
                Quantos braquetes foram repostos?
              </Typography>
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={bracketsNumber}
                  onChange={(e) => setBracketsNumber(String(e.target.value))}
                >
                  {[...Array(15).keys()].map((value) => (
                    <MenuItem key={value + 1} value={value + 1}>
                      {value + 1}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Styled.BoxIndication>
          )}

          <Styled.BoxIndication>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={hasComplement}
                    onClick={() => {
                      setHasComplement(!hasComplement);
                      setAdditionalDevice([]);
                    }}
                  />
                }
                label="Aparelho Complementar"
              />
            </FormGroup>
            {hasComplement && (
              <>
                <Autocomplete
                  options={complementary}
                  multiple
                  sx={{ margin: "10px 0" }}
                  onChange={(event, newValue) => {
                    setAdditionalDevice(newValue);
                  }}
                  value={additionalDevice}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      value={additionalDevice}
                      label="Aparelho complementar"
                    />
                  )}
                />
                {additionalDevice.length > 0 && (
                  <>
                    <Button sx={{ margin: "20px 0" }}>Abrir Odontograma</Button>
                    <Modal
                      open={modalComplement}
                      onClose={() => setModalComplement(false)}
                    >
                      <Odontograma
                        onClose={() => setModalComplement(false)}
                        selected={odontogramaComplement}
                        setSelected={setOdontogramaComplement}
                        subtitle="Aparelho Complementar"
                      />
                    </Modal>
                  </>
                )}
              </>
            )}
          </Styled.BoxIndication>

          <Styled.BoxIndication>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={hasOtherProcedures}
                    onClick={() => setHasOtherProcedures(!hasOtherProcedures)}
                  />
                }
                label="Outros procedimentos"
              />
            </FormGroup>
            {hasOtherProcedures && (
              <>
                <Autocomplete
                  options={proceduresData.map((option) => option.label)}
                  multiple
                  sx={{ margin: "10px 0" }}
                  onChange={(event, newValue) =>
                    setOtherProceduresValueSecondConsult(newValue)
                  }
                  value={otherProceduresValueSecondConsult}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      value={otherProceduresValueSecondConsult}
                      label="Outros procedimentos"
                    />
                  )}
                />
              </>
            )}
          </Styled.BoxIndication>

          <Styled.BoxIndication>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={hasOtherReferrals}
                    onClick={() => setHasOtherReferrals(!hasOtherReferrals)}
                  />
                }
                label="Encaminhamentos"
              />
            </FormGroup>
            {hasOtherReferrals && (
              <>
                <Autocomplete
                  options={referralsData}
                  multiple
                  sx={{ margin: "10px 0" }}
                  onChange={(event, newValue) => setReferralValue(newValue)}
                  value={referralValue}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      value={referralValue}
                      label="Encaminhamentos"
                    />
                  )}
                />
              </>
            )}
          </Styled.BoxIndication>

          <Styled.BoxIndication>
            <Typography
              variant="h5"
              sx={{ marginTop: "5px", marginBottom: "10px" }}
            >
              Precisa de receita para o caso de dor?
            </Typography>
            <Styled.BoxButtons style={{ marginBottom: "10px" }}>
              <Button
                variant={
                  painPrescriptionNeeded === "YES" ? "contained" : "outlined"
                }
                onClick={() => {
                  setPainPrescriptionNeeded("YES");
                  setShowInformedBasicCare(true);
                }}
              >
                Sim
              </Button>
              <Button
                variant={
                  painPrescriptionNeeded === "NO" ? "contained" : "outlined"
                }
                onClick={() => {
                  setPainPrescriptionNeeded("NO");
                  setShowInformedBasicCare(true);
                }}
              >
                Não
              </Button>
            </Styled.BoxButtons>
          </Styled.BoxIndication>

          <Styled.BoxIndication>
            <Typography
              variant="h5"
              sx={{ marginTop: "5px", marginBottom: "10px" }}
            >
              Informou o paciente sobre os cuidados básicos com o aparelho?
            </Typography>
            <Styled.BoxButtons style={{ marginBottom: "10px" }}>
              <Button
                variant={informedBasicCare === "YES" ? "contained" : "outlined"}
                onClick={() => {
                  setInformedBasicCare("YES");
                  setShowDeliveredTreatmentManual(true);
                }}
              >
                Sim
              </Button>
              <Button
                variant={informedBasicCare === "NO" ? "contained" : "outlined"}
                onClick={() => {
                  setInformedBasicCare("NO");
                  setShowDeliveredTreatmentManual(true);
                }}
              >
                Não
              </Button>
            </Styled.BoxButtons>
            {informedBasicCare === "NO" && (
              <TextField
                fullWidth
                label="Justifique"
                variant="outlined"
                sx={{ marginTop: "10px", paddingBottom: "5px" }}
                value={basicCareObservations}
                onChange={(e) => {
                  setBasicCareObservations(e.target.value);
                  setErrorBasicCare(false);
                }}
                error={errorBasicCare}
                helperText={errorBasicCare ? "Justificação é obrigatória" : ""}
              />
            )}
          </Styled.BoxIndication>

          <TextField
            label="Observações gerais"
            value={allObservationsProcedure}
            onChange={(e) => setAllObservationsProcedure(e.target.value)}
          />

          <Styled.BtnFixedAnam>
            <Button fullWidth onClick={next}>
              Continuar
            </Button>
          </Styled.BtnFixedAnam>
        </>
      )}
    </Styled.Box>
  );
};

export { ProcedurePerformedFixed };
