import React from "react";
import * as Styled from "./styles";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Modal,
  TextField,
  Typography,
} from "@mui/material";

import { Odontograma } from "components/Odontograma";
import toast from "react-hot-toast";
import { useAuth } from "shared/providers/auth";
import { useMaintenance } from "shared/providers/Maintenance";

const ProcedurePerformedMovable: React.FC = () => {
  const {
    setStep,
    needsBand,
    setNeedsBand,
    setNeedsSeparator,
    setPerformMolding,
    setInstallSeparator,
    moldingJustification,
    setMoldingJustification,
    separatorJustification,
    setSeparatorJustification,
    //patientObj,

    currentProcedureIndex,
    setCurrentProcedureIndex,
    otherProceduresValue,
    realize,
    setRealize,
    setJustification,
    realizeContent,
    setRealizeContent,
    realizeAparat,
    setRealizeAparat,
    realizeEruption,
    setRealizeEruption,
    realizeIntermaxilary,
    setRealizeIntermaxilary,
    realizeCollage,
    setRealizeCollage,
    realizeRebonding,
    setRealizeRebonding,
    realizeBite,
    setRealizeBite,
    route,
    setRoute,
    setJustifyRoute,
    realizeRelease,
    setRealizeRelease,
    setJustificationContent,
    setInformateRegionContent,
    setJustificationAparat,
    setJustificationEruption,
    setInformateRegionEruption,
    setJustificationIntermaxilary,
    setJustificationCollage,
    setJustificationRebonding,
    setJustificationBite,
    setJustificationRelease,
    setInformateRegion,
    odontogramaRemote,
    setOdontogramaRemote,
    odontogramaInstall,
    setOdontogramaInstall,
    odontogramaButton,
    setOdontogramaButton,
    odontogramaIntermax,
    setOdontogramaIntermax,
    odontogramaLevant,
    setOdontogramaLevant,
    /*braceTypeMaintenance,
    setBraceTypeMaintenance,
    setRepOrRecProcedure,
    setRepOrRecBraceProcedure,
    repProcedure,
    setRepProcedure,
    painPrescriptionNeededMaintenance,
    setPainPrescriptionNeededMaintenance,
    informedBasicCareMaintenance,
    setInformedBasicCareMaintenance,
    setMaterialProcedureMaintenance,
    setNumberProcedureMaintenance,
    setOthersProceduresFixedMaintenance,
    basicCareObservationsMaintenance,
    setBasicCareObservationsMaintenance,*/
    hasOtherProcedures,
    setHasOtherProcedures,
    setOtherProceduresValue,
    hasOtherReferrals,
    setHasOtherReferrals,
    setReferralValue,
    referralValue,
    setValueReferralsProcedures,
    valueReferralsProcedures,
    receiveHigh,
    setReceiveHigh,
    patientObj,
    allObservationsProcedure,
    setAllObservationsProcedure,
    otherProceduresValueSecondConsult,
    setOtherProceduresValueSecondConsult,
  } = useMaintenance();

  const { proceduresData, referralsData, proceduresDataWithFlow } = useAuth();

  const handleNeedsBandClick = (value: "NO" | "YES") => {
    setNeedsBand(value);
    setNeedsSeparator("NOT");
    setPerformMolding("NOT");
    setInstallSeparator("NOT");
    setMoldingJustification("");
    setSeparatorJustification("");
  };

  const [showOtherProcedureScreen, setShowOtherProcedureScreen] =
    React.useState(false);

  const [modalRemote, setModalRemote] = React.useState<boolean>(false);
  const [modalInstall, setModalInstall] = React.useState<boolean>(false);
  const [modalButton, setModalButton] = React.useState<boolean>(false);
  const [modalIntermax, setModalIntermax] = React.useState<boolean>(false);
  const [modalLevant, setModalLevant] = React.useState<boolean>(false);

  const descriptionsWithFlowZero = proceduresData
    .filter((procedure) => procedure.flow === 0)
    .map((procedure) => procedure.label);

  const combinedProcedures = [
    ...otherProceduresValue,
    ...otherProceduresValueSecondConsult,
  ];

  const newArrayValue = combinedProcedures
    .filter((procedure: any) => !descriptionsWithFlowZero.includes(procedure))
    .filter((procedure) => procedure !== "Manutenção");

  const handleContinue = () => {
    if (needsBand === "NO") {
      if (moldingJustification.length < 1) {
        toast.error("Preencha a justificativa.");
        return false;
      }
    }

    if (
      newArrayValue.length > 0 &&
      newArrayValue[0].length > 1 &&
      patientObj.proc_name !== "Emergência"
    ) {
      if (showOtherProcedureScreen) {
        if (currentProcedureIndex < newArrayValue.length - 1) {
          if (newArrayValue[currentProcedureIndex] === "Mini Implante") {
            if (realize === "NOT") {
              toast.error("Responda a pergunta e tente novamente");
              return false;
            }
          }

          if (
            newArrayValue[currentProcedureIndex] === "Controle de Contenção"
          ) {
            if (realizeContent === "NOT") {
              toast.error("Responda a pergunta e tente novamente");
              return false;
            }
          }

          if (newArrayValue[currentProcedureIndex] === "Remoção de Aparelho") {
            if (realizeAparat === "NOT") {
              toast.error("Responda a pergunta e tente novamente");
              return false;
            }
          }

          if (newArrayValue[currentProcedureIndex] === "Controle de Erupção") {
            if (realizeEruption === "NOT") {
              toast.error("Responda a pergunta e tente novamente");
              return false;
            }
          }

          if (
            newArrayValue[currentProcedureIndex] ===
            "Instalação de elásticos intermaxilares"
          ) {
            if (realizeIntermaxilary === "NOT") {
              toast.error("Responda a pergunta e tente novamente");
              return false;
            }
          }

          if (newArrayValue[currentProcedureIndex] === "Colagem de Botão") {
            if (realizeCollage === "NOT") {
              toast.error("Responda a pergunta e tente novamente");
              return false;
            }
          }

          if (newArrayValue[currentProcedureIndex] === "Recolagem Contenção") {
            if (realizeRebonding === "NOT") {
              toast.error("Responda a pergunta e tente novamente");
              return false;
            }
          }

          if (newArrayValue[currentProcedureIndex] === "Levante de Mordida") {
            if (realizeBite === "NOT") {
              toast.error("Responda a pergunta e tente novamente");
              return false;
            }
          }

          if (newArrayValue[currentProcedureIndex] === "Alta") {
            if (realizeRelease === "NOT") {
              toast.error("Responda a pergunta e tente novamente");
              return false;
            }
          }

          if (receiveHigh) {
            setStep(4);
          }

          setCurrentProcedureIndex(currentProcedureIndex + 1);
        } else {
          if (needsBand === "NO") {
            if (moldingJustification.length < 1) {
              toast.error("Preencha a justificativa.");
              return false;
            }
          }
          if (
            patientObj.proc_name === "Emergência" ||
            patientObj.future.scheduling === true
          ) {
            setStep(4);
          } else {
            setStep(2);
          }
        }
      } else {
        setCurrentProcedureIndex(0);
        setShowOtherProcedureScreen(true);
      }
    } else {
      if (
        patientObj.proc_name === "Emergência" ||
        patientObj.future.scheduling === true
      ) {
        setStep(4);
      } else {
        setStep(2);
      }
    }
  };

  const handleReturn = () => {
    if (currentProcedureIndex > 0) {
      setCurrentProcedureIndex(currentProcedureIndex - 1);
    } else {
      setShowOtherProcedureScreen(false);
    }
  };

  return (
    <Styled.Box>
      {showOtherProcedureScreen && showOtherProcedureScreen ? (
        <>
          <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
            {newArrayValue[currentProcedureIndex]}
          </Typography>

          {newArrayValue[currentProcedureIndex] === "Mini Implante" && (
            <Styled.BoxDescription>
              <Typography variant="h5">
                Foi Realizado o Mini Implante ortodôntico?
              </Typography>
              <Styled.BoxButtons>
                <Button
                  variant={realize === "NO" ? "contained" : "outlined"}
                  onClick={() => setRealize("NO")}
                >
                  NÃO
                </Button>
                <Button
                  variant={realize === "YES" ? "contained" : "outlined"}
                  onClick={() => setRealize("YES")}
                >
                  SIM
                </Button>
              </Styled.BoxButtons>
              {realize === "NO" && (
                <TextField
                  label="Justificativa"
                  fullWidth
                  margin="normal"
                  onChange={(e) => setJustification(e.target.value)}
                />
              )}

              {realize === "YES" && (
                <TextField
                  label="Informe a região onde foi realizado o procedimento"
                  fullWidth
                  margin="normal"
                  onChange={(e) => setInformateRegion(e.target.value)}
                />
              )}
              {realize !== "NOT" && (
                <>
                  <Autocomplete
                    options={proceduresDataWithFlow.map(
                      (option) => option.label
                    )}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !otherProceduresValue.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setOtherProceduresValue((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Outros procedimentos" />
                    )}
                  />

                  <Autocomplete
                    options={referralsData}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !valueReferralsProcedures.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setValueReferralsProcedures((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Encaminhamentos" />
                    )}
                  />
                </>
              )}
            </Styled.BoxDescription>
          )}

          {newArrayValue[currentProcedureIndex] === "Controle de Contenção" && (
            <Styled.BoxDescription>
              <Typography variant="h5">
                Foi Realizado Controle de Contenção?
              </Typography>
              <Styled.BoxButtons>
                <Button
                  variant={realizeContent === "NO" ? "contained" : "outlined"}
                  onClick={() => setRealizeContent("NO")}
                >
                  NÃO
                </Button>
                <Button
                  variant={realizeContent === "YES" ? "contained" : "outlined"}
                  onClick={() => setRealizeContent("YES")}
                >
                  SIM
                </Button>
              </Styled.BoxButtons>
              {realizeContent === "NO" && (
                <TextField
                  label="Justificativa"
                  fullWidth
                  margin="normal"
                  onChange={(e) => setJustificationContent(e.target.value)}
                />
              )}
              {realizeContent === "YES" && (
                <TextField
                  label="Faça o registro do caso"
                  fullWidth
                  margin="normal"
                  onChange={(e) => setInformateRegionContent(e.target.value)}
                />
              )}

              {realizeContent !== "NOT" && (
                <>
                  <Autocomplete
                    options={proceduresDataWithFlow.map(
                      (option) => option.label
                    )}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !otherProceduresValue.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setOtherProceduresValue((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Outros procedimentos" />
                    )}
                  />
                  <Autocomplete
                    options={referralsData}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !valueReferralsProcedures.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setValueReferralsProcedures((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Encaminhamentos" />
                    )}
                  />
                </>
              )}
            </Styled.BoxDescription>
          )}

          {newArrayValue[currentProcedureIndex] === "Remoção de Aparelho" && (
            <Styled.BoxDescription>
              <Typography variant="h5">
                Foi Realizada a Remoção da Aparatologia Ortodôntica?
              </Typography>
              <Styled.BoxButtons>
                <Button
                  variant={realizeAparat === "NO" ? "contained" : "outlined"}
                  onClick={() => setRealizeAparat("NO")}
                >
                  NÃO
                </Button>
                <Button
                  variant={realizeAparat === "YES" ? "contained" : "outlined"}
                  onClick={() => setRealizeAparat("YES")}
                >
                  SIM
                </Button>
              </Styled.BoxButtons>
              {realizeAparat === "NO" && (
                <TextField
                  label="Justificativa"
                  fullWidth
                  margin="normal"
                  onChange={(e) => setJustificationAparat(e.target.value)}
                />
              )}

              {realizeAparat === "YES" && (
                <>
                  <Button
                    sx={{ margin: "20px 0" }}
                    onClick={() => setModalRemote(true)}
                  >
                    Abrir Odontograma
                  </Button>

                  <Modal
                    open={modalRemote}
                    onClose={() => setModalRemote(false)}
                  >
                    <Odontograma
                      onClose={() => setModalRemote(false)}
                      selected={odontogramaRemote}
                      setSelected={setOdontogramaRemote}
                      subtitle="Remoção de Aparatologia Ortodôntica"
                    />
                  </Modal>
                </>
              )}

              {realizeAparat !== "NOT" && (
                <>
                  <Autocomplete
                    options={proceduresDataWithFlow.map(
                      (option) => option.label
                    )}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !otherProceduresValue.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setOtherProceduresValue((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Outros procedimentos" />
                    )}
                  />
                  <Autocomplete
                    options={referralsData}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !valueReferralsProcedures.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setValueReferralsProcedures((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Encaminhamentos" />
                    )}
                  />
                </>
              )}
            </Styled.BoxDescription>
          )}

          {newArrayValue[currentProcedureIndex] === "Controle de Erupção" && (
            <Styled.BoxDescription>
              <Typography variant="h5">
                Foi Realizado Controle de Erupção?
              </Typography>
              <Styled.BoxButtons>
                <Button
                  variant={realizeEruption === "NO" ? "contained" : "outlined"}
                  onClick={() => setRealizeEruption("NO")}
                >
                  NÃO
                </Button>
                <Button
                  variant={realizeEruption === "YES" ? "contained" : "outlined"}
                  onClick={() => setRealizeEruption("YES")}
                >
                  SIM
                </Button>
              </Styled.BoxButtons>
              {realizeEruption === "NO" && (
                <TextField
                  label="Justificativa"
                  fullWidth
                  margin="normal"
                  onChange={(e) => setJustificationEruption(e.target.value)}
                />
              )}
              {realizeEruption === "YES" && (
                <TextField
                  label="Faça o registro do caso"
                  fullWidth
                  margin="normal"
                  onChange={(e) => setInformateRegionEruption(e.target.value)}
                />
              )}

              {realizeEruption !== "NOT" && (
                <>
                  <Autocomplete
                    options={proceduresDataWithFlow.map(
                      (option) => option.label
                    )}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !otherProceduresValue.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setOtherProceduresValue((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Outros procedimentos" />
                    )}
                  />
                  <Autocomplete
                    options={referralsData}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !valueReferralsProcedures.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setValueReferralsProcedures((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Encaminhamentos" />
                    )}
                  />
                </>
              )}
            </Styled.BoxDescription>
          )}

          {newArrayValue[currentProcedureIndex] ===
            "Instalação de elásticos intermaxilares" && (
            <Styled.BoxDescription>
              <Typography variant="h5">Foi Colocado Elástico?</Typography>
              <Styled.BoxButtons>
                <Button
                  variant={
                    realizeIntermaxilary === "NO" ? "contained" : "outlined"
                  }
                  onClick={() => setRealizeIntermaxilary("NO")}
                >
                  NÃO
                </Button>
                <Button
                  variant={
                    realizeIntermaxilary === "YES" ? "contained" : "outlined"
                  }
                  onClick={() => setRealizeIntermaxilary("YES")}
                >
                  SIM
                </Button>
              </Styled.BoxButtons>
              {realizeIntermaxilary === "NO" && (
                <TextField
                  label="Justificativa"
                  fullWidth
                  margin="normal"
                  onChange={(e) =>
                    setJustificationIntermaxilary(e.target.value)
                  }
                />
              )}

              {realizeIntermaxilary === "YES" && (
                <>
                  <Button
                    sx={{ margin: "20px 0" }}
                    onClick={() => setModalInstall(true)}
                  >
                    Abrir Odontograma
                  </Button>

                  <Modal
                    open={modalInstall}
                    onClose={() => setModalInstall(false)}
                  >
                    <Odontograma
                      onClose={() => setModalInstall(false)}
                      selected={odontogramaInstall}
                      setSelected={setOdontogramaInstall}
                      subtitle="Instalação de elásticos intermaxilares"
                    />
                  </Modal>
                </>
              )}

              {realizeIntermaxilary !== "NOT" && (
                <>
                  <Autocomplete
                    options={proceduresDataWithFlow.map(
                      (option) => option.label
                    )}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !otherProceduresValue.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setOtherProceduresValue((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Outros procedimentos" />
                    )}
                  />

                  <Autocomplete
                    options={referralsData}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !valueReferralsProcedures.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setValueReferralsProcedures((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Encaminhamentos" />
                    )}
                  />
                </>
              )}
            </Styled.BoxDescription>
          )}

          {newArrayValue[currentProcedureIndex] === "Colagem de Botão" && (
            <Styled.BoxDescription>
              <Typography variant="h5">Colagem de botão?</Typography>
              <Styled.BoxButtons>
                <Button
                  variant={realizeCollage === "NO" ? "contained" : "outlined"}
                  onClick={() => setRealizeCollage("NO")}
                >
                  NÃO
                </Button>
                <Button
                  variant={realizeCollage === "YES" ? "contained" : "outlined"}
                  onClick={() => setRealizeCollage("YES")}
                >
                  SIM
                </Button>
              </Styled.BoxButtons>
              {realizeCollage === "NO" && (
                <TextField
                  label="Justificativa"
                  fullWidth
                  margin="normal"
                  onChange={(e) => setJustificationCollage(e.target.value)}
                />
              )}

              {realizeCollage === "YES" && (
                <>
                  <Button
                    sx={{ margin: "20px 0" }}
                    onClick={() => setModalButton(true)}
                  >
                    Abrir Odontograma
                  </Button>

                  <Modal
                    open={modalButton}
                    onClose={() => setModalButton(false)}
                  >
                    <Odontograma
                      onClose={() => setModalButton(false)}
                      selected={odontogramaButton}
                      setSelected={setOdontogramaButton}
                      subtitle="Colagem de Botão"
                    />
                  </Modal>
                </>
              )}

              {realizeCollage !== "NOT" && (
                <>
                  <Autocomplete
                    options={proceduresDataWithFlow.map(
                      (option) => option.label
                    )}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !otherProceduresValue.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setOtherProceduresValue((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Outros procedimentos" />
                    )}
                  />

                  <Autocomplete
                    options={referralsData}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !valueReferralsProcedures.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setValueReferralsProcedures((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Encaminhamentos" />
                    )}
                  />
                </>
              )}
            </Styled.BoxDescription>
          )}

          {newArrayValue[currentProcedureIndex] === "Recolagem Contenção" && (
            <Styled.BoxDescription>
              <Typography variant="h5">
                Foi realizada a recolagem de contenção?
              </Typography>
              <Styled.BoxButtons>
                <Button
                  variant={realizeRebonding === "NO" ? "contained" : "outlined"}
                  onClick={() => setRealizeRebonding("NO")}
                >
                  NÃO
                </Button>
                <Button
                  variant={
                    realizeRebonding === "YES" ? "contained" : "outlined"
                  }
                  onClick={() => setRealizeRebonding("YES")}
                >
                  SIM
                </Button>
              </Styled.BoxButtons>
              {realizeRebonding === "NO" && (
                <TextField
                  label="Justificativa"
                  fullWidth
                  margin="normal"
                  onChange={(e) => setJustificationRebonding(e.target.value)}
                />
              )}

              {realizeRebonding === "YES" && (
                <>
                  <Button
                    sx={{ margin: "20px 0" }}
                    onClick={() => setModalIntermax(true)}
                  >
                    Abrir Odontograma
                  </Button>

                  <Modal
                    open={modalIntermax}
                    onClose={() => setModalIntermax(false)}
                  >
                    <Odontograma
                      onClose={() => setModalIntermax(false)}
                      selected={odontogramaIntermax}
                      setSelected={setOdontogramaIntermax}
                      subtitle="Recolagem de Contenção"
                    />
                  </Modal>
                </>
              )}

              {realizeRebonding !== "NOT" && (
                <>
                  <Autocomplete
                    options={proceduresDataWithFlow.map(
                      (option) => option.label
                    )}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !otherProceduresValue.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setOtherProceduresValue((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Outros procedimentos" />
                    )}
                  />

                  <Autocomplete
                    options={referralsData}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !valueReferralsProcedures.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setValueReferralsProcedures((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Encaminhamentos" />
                    )}
                  />
                </>
              )}
            </Styled.BoxDescription>
          )}

          {newArrayValue[currentProcedureIndex] === "Levante de Mordida" && (
            <Styled.BoxDescription>
              <Typography variant="h5">
                Foi realizado levante de mordida?
              </Typography>
              <Styled.BoxButtons>
                <Button
                  variant={realizeBite === "NO" ? "contained" : "outlined"}
                  onClick={() => setRealizeBite("NO")}
                >
                  NÃO
                </Button>
                <Button
                  variant={realizeBite === "YES" ? "contained" : "outlined"}
                  onClick={() => setRealizeBite("YES")}
                >
                  SIM
                </Button>
              </Styled.BoxButtons>
              {realizeBite === "NO" && (
                <TextField
                  label="Justificativa"
                  fullWidth
                  margin="normal"
                  onChange={(e) => setJustificationBite(e.target.value)}
                />
              )}

              {realizeBite === "YES" && (
                <>
                  <Button
                    sx={{ margin: "20px 0" }}
                    onClick={() => setModalLevant(true)}
                  >
                    Abrir Odontograma
                  </Button>

                  <Modal
                    open={modalLevant}
                    onClose={() => setModalLevant(false)}
                  >
                    <Odontograma
                      onClose={() => setModalLevant(false)}
                      selected={odontogramaLevant}
                      setSelected={setOdontogramaLevant}
                      subtitle="Levante de Mordida"
                    />
                  </Modal>
                </>
              )}

              {realizeBite !== "NOT" && (
                <>
                  <Autocomplete
                    options={proceduresDataWithFlow.map(
                      (option) => option.label
                    )}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !otherProceduresValue.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setOtherProceduresValue((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Outros procedimentos" />
                    )}
                  />

                  <Autocomplete
                    options={referralsData}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !valueReferralsProcedures.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setValueReferralsProcedures((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Encaminhamentos" />
                    )}
                  />
                </>
              )}
            </Styled.BoxDescription>
          )}

          {newArrayValue[currentProcedureIndex] === "Alta" && (
            <>
              <Styled.BoxDescription>
                <Typography variant="h5">Paciente recebeu alta?</Typography>
                <Styled.BoxButtons>
                  <Button
                    variant={realizeRelease === "NO" ? "contained" : "outlined"}
                    onClick={() => {
                      setRealizeRelease("NO");
                      setReceiveHigh(false);
                    }}
                  >
                    NÃO
                  </Button>
                  <Button
                    variant={
                      realizeRelease === "YES" ? "contained" : "outlined"
                    }
                    onClick={() => {
                      setRealizeRelease("YES");
                      setReceiveHigh(true);
                    }}
                  >
                    SIM
                  </Button>
                </Styled.BoxButtons>
                {realizeRelease === "NO" && (
                  <TextField
                    label="Justificativa"
                    fullWidth
                    margin="normal"
                    onChange={(e) => setJustificationRelease(e.target.value)}
                  />
                )}
              </Styled.BoxDescription>

              {realizeRelease === "YES" && (
                <Styled.BoxDescription>
                  <Typography variant="h5">
                    Paciente foi encaminhado para a documentação final?
                  </Typography>
                  <Styled.BoxButtons>
                    <Button
                      variant={route === "NO" ? "contained" : "outlined"}
                      onClick={() => setRoute("NO")}
                    >
                      NÃO
                    </Button>
                    <Button
                      variant={route === "YES" ? "contained" : "outlined"}
                      onClick={() => setRoute("YES")}
                    >
                      SIM
                    </Button>
                  </Styled.BoxButtons>
                  {route === "NO" && (
                    <TextField
                      label="Justificativa"
                      fullWidth
                      margin="normal"
                      onChange={(e) => setJustifyRoute(e.target.value)}
                    />
                  )}
                </Styled.BoxDescription>
              )}

              {realizeRelease !== "NOT" && (
                <>
                  <Autocomplete
                    options={proceduresDataWithFlow.map(
                      (option) => option.label
                    )}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !otherProceduresValue.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setOtherProceduresValue((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Outros procedimentos" />
                    )}
                  />

                  <Autocomplete
                    options={referralsData}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value) => !valueReferralsProcedures.includes(value)
                      );

                      // Atualiza o estado com os valores únicos
                      setValueReferralsProcedures((prevValue: any) => [
                        ...prevValue,
                        ...uniqueValues,
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Encaminhamentos" />
                    )}
                  />
                </>
              )}
            </>
          )}

          <Styled.BtnFixed>
            <Button onClick={handleReturn} fullWidth>
              {currentProcedureIndex === 0 ? "Retornar" : "Anterior"}
            </Button>
            <Button onClick={handleContinue} fullWidth>
              {currentProcedureIndex < newArrayValue.length - 1
                ? "Próximo"
                : "Continuar"}
            </Button>
          </Styled.BtnFixed>
        </>
      ) : (
        <>
          <Styled.BoxDescription>
            <Typography variant="h5">
              Foi realizado a manutenção móvel?
            </Typography>
            <Styled.BoxButtons>
              <Button
                variant={needsBand === "NO" ? "contained" : "outlined"}
                onClick={() => handleNeedsBandClick("NO")}
              >
                NÃO
              </Button>
              <Button
                variant={needsBand === "YES" ? "contained" : "outlined"}
                onClick={() => handleNeedsBandClick("YES")}
              >
                SIM
              </Button>
            </Styled.BoxButtons>

            {needsBand === "NO" && (
              <TextField
                label="Justificativa"
                variant="outlined"
                fullWidth
                margin="normal"
                required
                value={moldingJustification}
                onChange={(e) => setMoldingJustification(e.target.value)}
              />
            )}

            {needsBand === "YES" && (
              <TextField
                label="Descreva o procedimento realizado"
                variant="outlined"
                fullWidth
                margin="normal"
                value={separatorJustification}
                onChange={(e) => setSeparatorJustification(e.target.value)}
              />
            )}
          </Styled.BoxDescription>

          <Styled.BoxIndication>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={hasOtherProcedures}
                    onClick={() => setHasOtherProcedures(!hasOtherProcedures)}
                  />
                }
                label="Outros procedimentos"
              />
            </FormGroup>
            {hasOtherProcedures && (
              <>
                <Autocomplete
                  options={proceduresData.map((option) => option.label)}
                  multiple
                  sx={{ margin: "10px 0" }}
                  onChange={(event, newValue) =>
                    setOtherProceduresValueSecondConsult(newValue)
                  }
                  value={otherProceduresValueSecondConsult}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      value={otherProceduresValueSecondConsult}
                      label="Outros procedimentos"
                    />
                  )}
                />
              </>
            )}
          </Styled.BoxIndication>

          <Styled.BoxIndication>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={hasOtherReferrals}
                    onClick={() => setHasOtherReferrals(!hasOtherReferrals)}
                  />
                }
                label="Encaminhamentos"
              />
            </FormGroup>
            {hasOtherReferrals && (
              <>
                <Autocomplete
                  options={referralsData}
                  multiple
                  sx={{ margin: "10px 0" }}
                  onChange={(event, newValue) => setReferralValue(newValue)}
                  value={referralValue}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      value={referralValue}
                      label="Encaminhamentos"
                    />
                  )}
                />
              </>
            )}
          </Styled.BoxIndication>

          <TextField
            label="Observações Gerais"
            value={allObservationsProcedure}
            onChange={(e) => setAllObservationsProcedure(e.target.value)}
          />
        </>
      )}

      {!showOtherProcedureScreen && (
        <Styled.BtnFixedAnam>
          <Button fullWidth onClick={handleContinue}>
            Continuar
          </Button>
        </Styled.BtnFixedAnam>
      )}
    </Styled.Box>
  );
};

export { ProcedurePerformedMovable };
