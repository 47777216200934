import API from "shared/services/Api";
import { IMaterialResponse } from "shared/types/Material";

export const getMaterials = async (
  userId: string,
  idPatient: number
): Promise<IMaterialResponse> =>
  API.get(
    `/oc/material?id=${userId}&idWire=0&idType=0&idComplementary=0&idPac=${idPatient}`
  );
