import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";

import { Toaster, toast } from "react-hot-toast";

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://08e9e953fae8a727b9073b998e45d247@o4508812973834240.ingest.us.sentry.io/4508812995592192",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/app-dentista-hml\.orthodonticbrasil\/.com/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

(window as any).showToast = (message: string) => {
  toast(message);
};

root.render(
  <React.StrictMode>
    <Toaster />
    <App />
  </React.StrictMode>
);
